import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import FbaSeaView from '@/views/FbaSeaView.vue';
import FbaSkyView from '@/views/FbaSkyView.vue';
import DoorToDoor from '@/views/DoorToDoor.vue';
import SkyLine from '@/views/SkyLine.vue';
import PortSea from '@/views/PortSea.vue';
import UsaToBack from '@/views/UsaToBack.vue';
import UsaToCar from '@/views/UsaToCar.vue';
import InterToTrain from '@/views/InterToTrain.vue';
import ColdChain from '@/views/ColdChain.vue';
import SeaWarehouse from '@/views/SeaWarehouse.vue';
import About from '@/views/About.vue';
import CaseStudies from '@/views/CaseStudies.vue';
import ContactUs from '@/views/ContactUs.vue';
import InterExpress from '@/views/InterExpress.vue';
import ImportLogis from '@/views/ImportLogis.vue';
import CustDelivery from '@/views/CustDelivery.vue';
import CollectLogis from '@/views/CollectLogis.vue';

Vue.use (VueRouter);

const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call (this, location).catch (err => err);
};
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/fbasea',
    name: 'fbasea',
    component: FbaSeaView,
  },
  {
    path: '/fbasky',
    name: 'fbasky',
    component: FbaSkyView,
  },
  {
    path: '/door',
    name: 'door',
    component: DoorToDoor,
  },
  {
    path: '/importlogis',
    name: 'importlogis',
    component: ImportLogis,
  },
  {
    path: '/collectlogis',
    name: 'collectlogis',
    component: CollectLogis,
  },
  {
    path: '/portsea',
    name: 'portsea',
    component: PortSea,
  },
  {
    path: '/interexp',
    name: 'interexp',
    component: InterExpress,
  },
  {
    path: '/seawarehouse',
    name: 'seawarehouse',
    component: SeaWarehouse,
  },
  {
    path: '/delivery',
    name: 'delivery',
    component: CustDelivery,
  },
  {
    path: '/uc',
    name: 'uc',
    component: UsaToCar,
  },
  {
    path: '/intertrain',
    name: 'intertrain',
    component: InterToTrain,
  },
  {
    path: '/coldchain',
    name: 'coldchain',
    component: ColdChain,
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/case',
    name: 'case',
    component: CaseStudies,
  },
  {
    path: '/concat',
    name: 'concat',
    component: ContactUs,
  },
  {
    path: '/messageList',
    name: 'messageList',
    component: () => import ('../views/messageList.vue'),
  },
];

const router = new VueRouter ({
  routes,
});

export default router;
