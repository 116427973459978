<template>
    <div>
        <div class="banner">
            <el-carousel class="train-banner-box" height="100%" arrow="never" indicator-position="none" loop="false">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <div class="banner" :style="{ backgroundImage: `url(${item.img})` }">
                        <div class="sub-title">{{ item.subTitle }}</div>
                        <div class="title">{{ item.title }}</div>
                        <div class="text">{{ item.text }}</div>
                        <div class="link-btn"><router-link :to="{ name: 'concat' }">获取报价</router-link></div>
                    </div>
                </el-carousel-item>
                <div class="header-container">
                    <div class="header-box">
                        <div><span><img :src="logo" alt="" @click="goPage('/')" /></span>
                            <!-- <span>文舟供应链管理有限公司</span> -->
                        </div>
                        <el-menu class=" right-nav" mode="horizontal" @select="handleSelect">
                            <el-submenu index="1">
              <template slot="title">主营业务</template>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasea' }"><img
                        src="../assets/icon_menu1.png" />
                      电商及FBA海派</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasky' }"><img
                        src="../assets/icon_menu7.png" />
                      电商及FBA空派</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'interexp' }"><img
                        src="../assets/icon_menu6.png" />
                      国际快递</router-link></el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'importlogis' }"><img
                        src="../assets/icon_menu4.png" />
                      进口物流</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item style="widows: 50px;"><router-link :to="{ name: 'portsea' }">
                      <img src="../assets/icon_menu3.png" /> 港到港海运服务</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'door' }"><img
                        src="../assets/icon_menu2.png" />
                      门到门整柜运输</router-link>
                  </el-menu-item></el-col>

              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'seawarehouse' }"><img
                        src="../assets/icon_menu4.png" />
                      美国自营海外仓</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'delivery' }"><img
                        src="../assets/icon_menu8.png" />
                      美国清关+派送</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'intertrain' }"><img
                        src="../assets/icon_menu6.png" />
                      国际班列</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'collectlogis' }"><img
                        src="../assets/icon_menu8.png" />
                      集运物流</router-link></el-menu-item></el-col>
              </el-row>
            </el-submenu>
                            <el-menu-item index="2"><router-link :to="{ name: 'about' }">关于文舟</router-link></el-menu-item>
                            <el-menu-item index="3"><router-link :to="{ name: 'case' }">合作案例</router-link></el-menu-item>
                            <el-menu-item index="4"><router-link
                                    :to="{ name: 'concat' }">联系我们</router-link></el-menu-item>
                        </el-menu>

                    </div>
                </div>
            </el-carousel>
        </div>

        <div class="train-centent-box">
            <div class="centent-img-box">
                <el-row>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title">国际班列整柜</div>
                            <div class="title-desc">
                                始发站点可提供代理报关及全国上门提货服务，班列固定，全程自主操作，线路已拓展至欧洲全境、俄罗斯、外蒙古、中亚五国等，并在目的国建立专业的清关、税务团队。</div>
                            <div>
                                <p>中欧班列</p>
                                <p>专注于一带一路沿线跨国铁路运输，时效稳定、安全性高，班次密集，欧洲全境提送货，提供回程进口国内到门服务。</p>
                                <p>中俄班列</p>
                                <p>发往俄罗斯、白俄罗斯、乌克兰的快速集装箱直达班列，在后贝加尔拥有优先换装发车权，可在36h内完成换装发车</p>
                                <p>中亚班列</p>
                                <p>发往中亚五国（哈萨克斯坦、乌兹别克斯坦、塔吉克斯坦、吉尔吉斯斯坦、土库曼斯坦）班列集装箱运输，提供场站装箱、拆箱、短驳、关务资讯等服务</p>
                                <p>中老班列</p>
                                <p>渝新欧中老泰班列，双向全程铁路直达，辐射东盟市场，有效缩短东南亚国家与中国、欧洲间的货运时效，实现欧洲与东南亚各国"无缝衔接"</p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="../assets/yw-gjbl1.jpg" class="image">

                    </el-col>
                </el-row>

            </div>
            <div class="centent-img-box-v2">
                <el-row>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="../assets/yw-gjbl2.jpg" class="image">
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title">欧洲FBA拼箱</div>
                            <div class="title-desc">
                                为跨境电商、B2B出口企业提供中国-欧洲铁路拼箱（LCL）直达服务，国内各大城市均可收货，固定周发班列，舱位充足，时效稳定，海铁多式联运服务可延伸至北欧各国</div>
                            <div>
                                <p>国内始发站点多</p>
                                <p>7年欧洲专线班列运输经验，中欧铁路班次齐全（重庆、成都、义乌、西安、武汉、郑州、苏州等），全国各主要口岸均设有分公司，各大城市均可发货</p>
                                <p>目的港清关点多</p>
                                <p>多线路多目的地集货网络遍布欧洲，超5年丰富经验清关团队熟悉欧洲各地清关政策，在欧洲多个国家建立固定的清关渠道（匈牙利布达佩斯、波兰马拉、比利时列日、捷克布拉格）</p>
                                <p>可做进口VAT递延</p>
                                <p>降低进口成本，消除现金流影响，欧盟境内可在比利时、荷兰做进口VAT递延，英国采用PVA清关，无需在申报时缴纳进口VTA</p>
                                <p>尾程快递优质账号</p>
                                <p>优选各国本地化快递公司（德国UPS/DHL、波兰UPS、英国DPD），后段派送使用本地优质账号，时效更快、价格更优</p>
                            </div>
                        </div>
                    </el-col>
                </el-row>

            </div>

            <div class="centent-img-box">
                <el-row>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title">集装箱租赁</div>
                            <div class="title-desc">
                                始发站点可提供代理报关及全国上门提货服务，班列固定，全程自主操作，线路已拓展至欧洲全境、俄罗斯、外蒙古、中亚五国等，并在目的国建立专业的清关、税务团队。</div>
                            <div>
                                <p>集装箱种类</p>
                                <p>20GP（20英尺标准集装箱）/40GP（40英尺标准集装箱）/40HQ（40英尺超高集装箱）/40RH（40英尺冷藏集装箱）</p>
                                <p>租箱方式</p>
                                <p>单程短租箱、往返周期租箱、长期租箱等</p>
                                <p>国内提箱点</p>
                                <p>青岛、上海、宁波、深圳、盐田、厦门、广州、天津、西安、郑州、武汉、成都、重庆等</p>
                                <p>欧洲还箱点</p>
                                <p>汉堡、杜伊斯堡、马拉、米兰、布达佩斯等</p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="../assets/yw-gjbl3.jpg" class="image">

                    </el-col>
                </el-row>

            </div>
        </div>
        <div class="table-box">
            <div class="container sanbiaoge">
                <h1><img src="../assets/icon-hc.jpg" width="20"> 中欧班列时效</h1>
                <table width="100%" cellpadding="0" cellspacing="0">
                    <thead>
                        <tr>
                            <th scope="col" style="width: 10%;">线路</th>
                            <th scope="col" style="width: 20%;">始发站</th>
                            <th scope="col" style="width: 60%;">目的站</th>
                            <th scope="col" style="width: 10%;">时效(天)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowspan="9" scope="row">长安号</td>
                            <td rowspan="9">西安</td>
                            <td>马拉<small>（去回程双向公共班列）</small></td>
                            <td>12-15</td>
                        </tr>
                        <tr>
                            <td>莫斯科</td>
                            <td>15-18</td>
                        </tr>
                        <tr>
                            <td>汉堡/杜伊斯堡<small>（去回程双向公共班列）</small></td>
                            <td>18-20</td>
                        </tr>
                        <tr>
                            <td>科隆/慕尼黑/圣彼得堡</td>
                            <td>18-20</td>
                        </tr>
                        <tr>
                            <td>布达佩斯<small>（去回程双向公共班列）</small></td>
                            <td>20-22</td>
                        </tr>
                        <tr>
                            <td>鹿特丹/蒂尔堡/明斯克</td>
                            <td>20-22</td>
                        </tr>
                        <tr>
                            <td>维罗纳/奥斯陆/洛沃西采/赫尔辛基<small>（去回程双向公共班列）</small></td>
                            <td>22-25</td>
                        </tr>
                        <tr>
                            <td>米兰/博洛尼亚/巴塞罗那/巴塞尔</td>
                            <td>22-25</td>
                        </tr>
                        <tr>
                            <td>哈萨克斯坦/乌兹别克斯坦/吉尔吉斯斯坦/塔吉克斯坦/土库曼斯坦</td>
                            <td>8-15</td>
                        </tr>
                        <tr>
                            <td scope="row">成都线</td>
                            <td>成都</td>
                            <td>罗兹/汉堡/纽伦堡/蒂尔堡</td>
                            <td>12-18</td>
                        </tr>
                        <tr>
                            <td scope="row">武汉线</td>
                            <td>武汉</td>
                            <td>马拉舍维奇/汉堡/杜伊斯堡/里昂</td>
                            <td>12-22</td>
                        </tr>
                        <tr>
                            <td scope="row">重庆线</td>
                            <td>重庆</td>
                            <td>马拉舍维奇/杜伊斯堡</td>
                            <td>12-16</td>
                        </tr>
                        <tr>
                            <td scope="row">苏州线</td>
                            <td>苏州</td>
                            <td>华沙/汉堡/杜伊斯堡</td>
                            <td>12-16</td>
                        </tr>
                        <tr>
                            <td scope="row">郑州线</td>
                            <td>郑州</td>
                            <td>华沙/汉堡/慕尼黑</td>
                            <td>12-16</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="container sanbiaoge">
                <h1><img src="../assets/icon-hc.jpg" width="20"> 中俄班列时效</h1>
                <table width="100%" cellpadding="0" cellspacing="0">
                    <thead>
                        <tr>
                            <th scope="col" style="width: 10%;">线路</th>
                            <th scope="col" style="width: 20%;">始发站</th>
                            <th scope="col" style="width: 60%;">目的站</th>
                            <th scope="col" style="width: 10%;">时效(天)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="row">/</td>
                            <td>西安</td>
                            <td>莫斯科/明斯克/基辅</td>
                            <td>12-18</td>
                        </tr>
                        <tr>
                            <td scope="row">/</td>
                            <td>义乌/南昌/长沙<br>金华/成都/重庆<br>济南/苏州/沈阳</td>
                            <td>明斯克、沃尔西诺、别雷拉斯特、克列斯特、电煤站及俄罗斯全境</td>
                            <td>稳定</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="container sanbiaoge">
                <h1><img src="../assets/icon-hc.jpg" width="20"> 中亚班列时效</h1>
                <table width="100%" cellpadding="0" cellspacing="0">
                    <thead>
                        <tr>
                            <th scope="col" style="width: 10%;">线路</th>
                            <th scope="col" style="width: 20%;">始发站</th>
                            <th scope="col" style="width: 60%;">目的站</th>
                            <th scope="col" style="width: 10%;">时效(天)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowspan="5" scope="row">/</td>
                            <td>西安</td>
                            <td>阿拉木图</td>
                            <td>12-15</td>
                        </tr>
                        <tr>
                            <td>连云港</td>
                            <td>塔什干</td>
                            <td>15-18</td>
                        </tr>
                        <tr>
                            <td>青岛</td>
                            <td>阿拉木图-塔什干</td>
                            <td>18-20</td>
                        </tr>
                        <tr>
                            <td>合肥</td>
                            <td>阿拉木图</td>
                            <td>18-20</td>
                        </tr>
                        <tr>
                            <td>长沙</td>
                            <td>塔什干</td>
                            <td>20-22</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
       
        <div class="bottom-link">
            <el-row>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        主营业务
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'fbasea' }">电商及FBA海派</router-link></div>
                    <div class="sub-title"><router-link
                      :to="{ name: 'fbasky' }">电商及FBA空派</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'interexp' }">国际快递</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'importlogis' }">进口物流</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'portsea' }">
                      港到港海运服务</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'door' }"> 门到门整柜运输</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'seawarehouse' }"> 美国自营海外仓</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'delivery' }">美国清关+派送</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'intertrain' }"> 国际班列</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'collectlogis' }">集运物流</router-link></div>
                </el-col>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        关于文舟
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'about' }">关于文舟</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'case' }">合作案例</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'concat' }">联系我们</router-link></div>
                </el-col>
            </el-row>
        </div>
        <!-- 底部 -->
        <div class="bottom-container">
            <div class="wrapper">
                Copyright @ 2008-至今 上海文舟供应链管理有限公司 All rights reserved
                沪ICP备20000382号-3
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/assets/zyyw-banner2.jpg';
import banner1 from '@/assets/banner1.jpg';
import banner2 from '@/assets/banner2.jpg';
import banner3 from '@/assets/banner3.jpg';
export default {
    name: 'FbaSeaView',
    data() {
        return {
            bannerList: [
                {
                    img: banner,
                    subTitle: '国际班列',
                    title: '班列贸易物流解决方案',
                    text: '多线路、多口岸，进出口往返对发，全国范围上门提送货，订舱、租箱、还箱一站式服务',
                }
            ],
            fbaList: [
                {
                    img: banner1,
                    title: '专注北美 链通全球',
                    text: '为您提供专业的海运整柜/拼箱、关务、国际多式联运等门到门物流服务',
                },
                {
                    img: banner2,
                    title: '一体化 高稳定 快时效',
                    text: '国内拖车+订舱/报关+清关/海运+空运+中欧班列/自营海外仓+自营车队',
                },
                {
                    img: banner3,
                    title: '非洲整柜海运',
                    text: '丰富的航线资源，稳定的非洲内陆连接网络，资深的专家团队，确保货物按时送达',
                },
            ]
        };
    },
};
</script>
<style>
.body {
    color: #15213B;
    font-size: 14px;
    line-height: 28px;
    background-color: #eef1fa !important;
}

::v-deep .el-carousel__arrow i {
  font-size: 30px;
}
::v-deep .el-carousel__arrow {
  background-color: transparent;
}
::v-deep .el-carousel__arrow:hover {
  background-color: transparent;
}
.header-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  .el-menu .el-menu--horizontal {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: none;
  }
  .el-menu--horizontal {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: none;
  }

}

  ::v-deep .el-submenu__title:focus, ::v-deep .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  ::v-deep .el-menu-item:focus, ::v-deep .el-menu-item:hover{
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    outline: 0;
    color: #909399 !important;
}
.el-menu--horizontal>.el-submenu:hover .el-submenu__title {
    color: #909399 !important;
}
.el-menu-item:focus, .el-menu-item:hover {
    outline: 0;
    background-color: rgba(0, 0, 0, 0) !important;
}
.el-menu .el-menu--horizontal {
    border-bottom: none;
}
.el-menu-item, .el-submenu__title {
    list-style: none;
}
.el-menu-item a {
    text-decoration:none;
}


  ::v-deep .el-menu--horizontal > ::v-deep .el-submenu ::v-deep .el-submenu__title{
    color: #909399 !important;
  }
  ::v-deep .el-menu--horizontal ::v-deep .el-menu-item:not(.is-disabled):hover {
    color: #909399 !important;
  }
  ::v-deep .el-menu--horizontal > ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399;
  }
  ::v-deep .el-menu--horizontal  > ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399 !important;
  }

.header-box {
  max-width: 1300px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 70px;
  margin: 0 auto;
  font-size: 14px;
  img {
    height: 70px;
    cursor: pointer;
  }
  span{
    display: inline-block;
    height: 70px;
    line-height: 70px;
    vertical-align: middle;
  }
}
.right-nav {
  width: 50%;
  display: flex;
  border-bottom: none !important;
  .text {
    margin: 0 20px;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      color: #82feff;
    }
  }
}
.train-banner-box {
    width: 100%;
    height: 456px;
    position: relative;

    .banner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        background-position: center;
        background-size: cover;
    }

    .sub-title {
        padding-bottom: 40px;
        color: #ffffff;
        font-weight: bolder;
        letter-spacing: 2px;
        padding-left: 25%;
    }

    .title {
        padding-bottom: 40px;
        font-size: 46px;
        color: #ffffff;
        font-weight: bolder;
        letter-spacing: 2px;
        padding-left: 25%;
    }

    .text {
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 2px;
        padding-left: 25%;
    }
    .link-btn {
        padding: 10px 35px;
        transition: all 1s;
        border-radius: 35px;
        background-color: #F5C12D;
        font-size: 16px;
        width: 4%;
        min-width: 65px;
        margin-left: 25%;
        margin-top: 20px;

    }

    .link-btn:hover {
        cursor: pointer;
        background-color: #1D9F95;
        transition: all 1s;
    }

    a {
        text-decoration: none;
        font-weight: 400;
        color: #15213B;

    }
}

.fba-content {
    width: 100%;
    line-height: 200px;
    font-size: 40px;
    text-align: center;
    background-color: #eef1fa;
}

.second-box {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
    background-color: #eef1fa;

    .second-box-carousel {
        width: 70%;
        height: 334px;
        position: relative;

        /* background-color: antiquewhite; */
        .second {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            background-position: center;
            background-size: cover;
        }

        .title {
            padding-bottom: 40px;
            font-size: 46px;
            color: #ffffff;
            font-weight: bolder;
            letter-spacing: 2px;
        }

        .text {
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 2px;
        }
    }
}

.three-box {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #eef1fa;

    .el-row {
        width: 100%;
        display: flex;
        justify-content: center;

        .el-col {
            padding: 20px 16px;
            margin-bottom: -10px;

            .el-card {
                padding: 40px;
            }
        }

        .el-col-offset-2 {
            margin-left: 0%;
        }
    }

    .title {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        line-height: 40px;
        margin-top: 20px;
    }

    .title-desc {
        font-size: 16px;
        color: #999999;
        line-height: 30px;
        min-height: 90px;
    }

    .image {
        height: 40px !important;
    }

}

.three-last-box {
    padding-bottom: 20px;
}

.contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    background-color: #1d9f96;
}

.left-info {
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 50px 0;

    p {
        text-align: center;
        color: #15213B;
        line-height: 35px;
    }

    .p2 {
        font-size: 40px;
        font-weight: bold;
        margin: 25px auto;
    }

    .p3 {
        font-size: 21px;
        font-weight: bold;
        width: 60%;
        margin: 15px auto 0;
        line-height: 40px;
    }

    .concat-title {
        line-height: normal;
        padding-bottom: 16px;
        font-size: 30px;
        line-height: 65px;
        text-align: center;
        font-weight: bold;
        padding-bottom: 60px;
        color: #2a2929;
    }

    .words {
        text-align: center;
        line-height: 26px;
        padding-bottom: 30px;
    }


}

.train-centent-box {

    background-color: #eef1fa;
    width: 100%;
    /* height: 850px; */
    padding-top: 50px;


    .centent-img-box {
        display: flex;
        justify-content: center;
        width: 65%;
        /* height: 400px; */
        margin: auto;
        padding-bottom: 40px;

        .el-row {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;

            .el-col {
                margin-bottom: 30px;

                .left {
                    padding: 20px 80px;
                    text-align: left;

                    .title {
                        font-size: 23px;
                        font-weight: bolder;
                    }
                }

                img {
                    padding: 0px 10px 20px 90px;
                    width: 80%;
                    height: 360px;
                }
            }
        }



    }

    .centent-img-box-v2 {
        display: flex;
        justify-content: center;
        width: 65%;
        /* height: 400px; */
        margin: auto;

        .el-row {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;

            .el-col {
                margin-bottom: 30px;

                .left {
                    padding: 20px 80px;
                    text-align: left;

                    .title {
                        font-size: 23px;
                        font-weight: bolder;
                    }
                }

                img {
                    padding: 0px 10px 20px 30px;
                    width: 80%;
                    height: 360px;
                }
            }
        }



    }

    .title {
        padding-bottom: 10px;
        font-size: 20px;
        color: #4b4747;
        font-weight: bolder;
    }

    .title-desc {
        letter-spacing: 2px;
        line-height: 28px !important;
    }
}

.table-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;

    .sanbiaoge {
        width: 70%;
        h1 {
            margin-top: 30px;
        }

        table {
            width: 100%;
            border-radius: 10px;
            margin-top: 20px;
            border-collapse: inherit;
            overflow: hidden;
            border: 0.5px solid #EFEFEF;

            th {
                border: 0.5px solid #EFEFEF;
                padding: 5px;
                text-align: center;
                font-size: 16px;
            }

            td {
                border: 0.5px solid #EFEFEF;
                padding: 5px;
                text-align: center;
            }
        }
    }
}



.bottom-link {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 50px 0 30px;
    color: #FFF;
    background-color: #15213B;

    .el-row {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;

        .el-col {
            text-align: center;

            /* margin-right: 100px; */
            .title {
                font-size: 16px;
                font-weight: bolder;
                text-align: left;
                margin-bottom: 10px;
            }

            .sub-title {
                color: #95A5C9;
                border-bottom: 1px solid #1C2B4E;
                line-height: 35px;
                text-align: left;
                font-size: 14px;
            }
        }

    }
}


.bottom-container {
    padding: 30px 0;
    color: #ffffff;
    background-color: #15213B;
    border-top: 1px solid #41537A;

    .wrapper {
        width: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .link {
        padding-right: 20px;
        cursor: pointer;
    }
}
</style>