import service from './index.js';
export function submitMessage (data) {
  return service ({
    method: 'post',
    url: '/message/putMessage',
    data,
  });
}
export function getMessage (data) {
  return service ({
    method: 'get',
    url: `/message/page?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
  });
}
