<template>
    <div style="background-color: #eef1fa;">
        <div class="banner">
            <el-carousel class="port-banner-box" height="100%" arrow="never" indicator-position="none" loop="false">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <div class="banner" :style="{ backgroundImage: `url(${item.img})` }">
                        <div class="sub-title">{{ item.subTitle }}</div>
                        <div class="title">{{ item.title }}</div>
                        <div class="text">{{ item.text }}</div>
                        <div class="link-btn"><router-link :to="{ name: 'concat' }">获取报价</router-link></div>
                    </div>
                </el-carousel-item>
                <div class="header-container">
                    <div class="header-box">
                        <div><span><img :src="logo" alt="" @click="goPage('/')" /></span>
                            <!-- <span>文舟供应链管理有限公司</span> -->
                        </div>
                        <el-menu class=" right-nav" mode="horizontal" @select="handleSelect">
                            <el-submenu index="1">
              <template slot="title">主营业务</template>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasea' }"><img
                        src="../assets/icon_menu1.png" />
                      电商及FBA海派</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasky' }"><img
                        src="../assets/icon_menu7.png" />
                      电商及FBA空派</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'interexp' }"><img
                        src="../assets/icon_menu6.png" />
                      国际快递</router-link></el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'importlogis' }"><img
                        src="../assets/icon_menu4.png" />
                      进口物流</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item style="widows: 50px;"><router-link :to="{ name: 'portsea' }">
                      <img src="../assets/icon_menu3.png" /> 港到港海运服务</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'door' }"><img
                        src="../assets/icon_menu2.png" />
                      门到门整柜运输</router-link>
                  </el-menu-item></el-col>

              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'seawarehouse' }"><img
                        src="../assets/icon_menu4.png" />
                      美国自营海外仓</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'delivery' }"><img
                        src="../assets/icon_menu8.png" />
                      美国清关+派送</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'intertrain' }"><img
                        src="../assets/icon_menu6.png" />
                      国际班列</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'collectlogis' }"><img
                        src="../assets/icon_menu8.png" />
                      集运物流</router-link></el-menu-item></el-col>
              </el-row>
            </el-submenu>
                            <el-menu-item index="2"><router-link
                                    :to="{ name: 'about' }">关于文舟</router-link></el-menu-item>
                            <el-menu-item index="3"><router-link
                                    :to="{ name: 'case' }">合作案例</router-link></el-menu-item>
                            <el-menu-item index="4"><router-link
                                    :to="{ name: 'concat' }">联系我们</router-link></el-menu-item>
                        </el-menu>

                    </div>
                </div>
            </el-carousel>
        </div>
        <!--         
        <div class="port-centent-box">
            <div class="centent-img-box">
                <el-row>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title">全程保价</div>
                            <div class="title-desc">针对跨境物流周期长、节点多，容易产生丢包破损等问题提供全程保价服务
                                面向全品类、高货值产品，保价费率为3.5%，保价货值可达USD1500/箱
                                如在运输途中发生丢包、破损，将在7个工作日内按丢损货值完成全额赔付</div>
                            <div>
                                <p>适用产品</p>
                                <p>一票一件、一票多件、海外仓暂存转运、海外仓一件代发</p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
                            class="image">

                    </el-col>
                </el-row>

            </div>
           
        </div> -->
        <div class="port-contact-container">
            <div class="left-info">
                <div class="left-text-box">
                    <p>CIF传统拼箱/整柜</p>
                    <p class="p2">优质、高效的目的港增值服务</p>
                    <p class="p3">文舟供应链在美国当地拥有众多渠道资源，多年的经验与职工外派能更好的把控每一个物流节点，标准化作业能力降低货物在中转过程中所面临的物损、丢件风险，保障货主履约交付。</p>
                </div>
            </div>
        </div>
        <div class="bottom-link">
            <el-row>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        主营业务
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'fbasea' }">电商及FBA海派</router-link></div>
                    <div class="sub-title"><router-link
                      :to="{ name: 'fbasky' }">电商及FBA空派</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'interexp' }">国际快递</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'importlogis' }">进口物流</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'portsea' }">
                      港到港海运服务</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'door' }"> 门到门整柜运输</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'seawarehouse' }"> 美国自营海外仓</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'delivery' }">美国清关+派送</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'intertrain' }"> 国际班列</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'collectlogis' }">集运物流</router-link></div>
                </el-col>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        关于文舟
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'about' }">关于文舟</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'case' }">合作案例</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'concat' }">联系我们</router-link></div>
                </el-col>
            </el-row>
        </div>
        <!-- 底部 -->
        <div class="bottom-container">
            <div class="wrapper">
                Copyright @ 2008-至今 上海文舟供应链管理有限公司 All rights reserved
                沪ICP备20000382号-3
            </div>
        </div>
    </div>
</template>

<script>
import banner1 from '@/assets/banner1.jpg';
import banner2 from '@/assets/banner2.jpg';
import banner3 from '@/assets/banner3.jpg';
import banner from '@/assets/zyyw-banner5.jpg';
export default {
    name: 'FbaSeaView',
    data() {
        return {
            bannerList: [
                {
                    img: banner,
                    subTitle: 'CIF传统拼箱/整柜',
                    title: '港到港传统物流解决方案',
                    text: '为货代同行/直客的拼箱业务提供港到港海运服务，解决传统拼箱交货周期长、费用不透明等痛点”修改为“为同行/直客的拼箱/整柜业务提供港到港海运服务，解决传统物流交货周期长、费用不透明等痛点',
                }
            ],
            fbaList: [
                {
                    img: banner1,
                    title: '专注北美 链通全球',
                    text: '为您提供专业的海运整柜/拼箱、关务、国际多式联运等门到门物流服务',
                },
                {
                    img: banner2,
                    title: '一体化 高稳定 快时效',
                    text: '国内拖车+订舱/报关+清关/海运+空运+中欧班列/自营海外仓+自营车队',
                },
                {
                    img: banner3,
                    title: '非洲整柜海运',
                    text: '丰富的航线资源，稳定的非洲内陆连接网络，资深的专家团队，确保货物按时送达',
                },
            ]
        };
    },
};
</script>
<style>
.body {
    color: #15213B;
    font-size: 14px;
    line-height: 28px;
    background-color: #eef1fa !important;
}

::v-deep .el-carousel__arrow i {
    font-size: 30px;
}

::v-deep .el-carousel__arrow {
    background-color: transparent;
}

::v-deep .el-carousel__arrow:hover {
    background-color: transparent;
}

.header-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;

    .el-menu .el-menu--horizontal {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: none;
    }

    .el-menu--horizontal {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: none;
    }

}

::v-deep .el-submenu__title:focus,
::v-deep .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

::v-deep .el-menu-item:focus,
::v-deep .el-menu-item:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    outline: 0;
    color: #909399 !important;
}

.el-menu--horizontal>.el-submenu:hover .el-submenu__title {
    color: #909399 !important;
}

.el-menu-item:focus,
.el-menu-item:hover {
    outline: 0;
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu-item,
.el-submenu__title {
    list-style: none;
}

.el-menu-item a {
    text-decoration: none;
}


::v-deep .el-menu--horizontal> ::v-deep .el-submenu ::v-deep .el-submenu__title {
    color: #909399 !important;
}

::v-deep .el-menu--horizontal ::v-deep .el-menu-item:not(.is-disabled):hover {
    color: #909399 !important;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399 !important;
}

.header-box {
    max-width: 1300px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 70px;
    margin: 0 auto;
    font-size: 14px;

    img {
        height: 70px;
        cursor: pointer;
    }

    span {
        display: inline-block;
        height: 70px;
        line-height: 70px;
        vertical-align: middle;
    }
}

.right-nav {
    width: 50%;
    display: flex;
    border-bottom: none !important;

    .text {
        margin: 0 20px;
        color: #ffffff;
        cursor: pointer;

        &:hover {
            color: #82feff;
        }
    }
}

.port-banner-box {
    width: 100%;
    height: 456px;
    position: relative;

    .banner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        background-position: center;
        background-size: cover;
    }

    .sub-title {
        padding-bottom: 40px;
        font-size: 14px;
        color: #ffffff;
        font-weight: bolder;
        letter-spacing: 2px;
        padding-left: 25%;
    }

    .title {
        padding-bottom: 40px;
        font-size: 46px;
        color: #ffffff;
        font-weight: bolder;
        letter-spacing: 2px;
        padding-left: 25%;
    }

    .text {
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 2px;
        padding-left: 25%;
        width: 540px;
    }

    .link-btn {
        padding: 10px 35px;
        transition: all 1s;
        border-radius: 35px;
        background-color: #F5C12D;
        font-size: 16px;
        width: 4%;
        min-width: 65px;
        margin-left: 25%;
        margin-top: 20px;

    }

    .link-btn:hover {
        cursor: pointer;
        background-color: #1D9F95;
        transition: all 1s;
    }

    a {
        text-decoration: none;
        font-weight: 400;
        color: #15213B;

    }
}

.fba-content {
    width: 100%;
    line-height: 200px;
    font-size: 40px;
    text-align: center;
    background-color: #eef1fa;
}

.second-box {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
    background-color: #eef1fa;

    .second-box-carousel {
        width: 70%;
        height: 334px;
        position: relative;

        /* background-color: antiquewhite; */
        .second {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            background-position: center;
            background-size: cover;
        }

        .title {
            padding-bottom: 40px;
            font-size: 46px;
            color: #ffffff;
            font-weight: bolder;
            letter-spacing: 2px;
        }

        .text {
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 2px;
        }
    }
}

.three-box {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #eef1fa;

    .el-row {
        width: 100%;
        display: flex;
        justify-content: center;

        .el-col {
            padding: 20px 16px;
            margin-bottom: -10px;

            .el-card {
                padding: 40px;
            }
        }

        .el-col-offset-2 {
            margin-left: 0%;
        }
    }

    .title {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        line-height: 40px;
        margin-top: 20px;
    }

    .title-desc {
        font-size: 16px;
        color: #999999;
        line-height: 30px;
        min-height: 90px;
    }

    .image {
        height: 40px !important;
    }

}

.three-last-box {
    padding-bottom: 20px;
}

.port-contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0px auto;
    /* margin-top: 40px; */
    background-color: #1d9f96;
}

.left-info {
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 50px 0;

    p {
        text-align: center;
        color: #15213B;
        line-height: 35px;
    }

    .p2 {
        font-size: 40px;
        font-weight: bold;
        margin: 25px auto;
    }

    .p3 {
        font-size: 21px;
        font-weight: bold;
        width: 60%;
        margin: 15px auto 20px;
        line-height: 40px;
    }

    .concat-title {
        line-height: normal;
        padding-bottom: 16px;
        font-size: 30px;
        line-height: 65px;
        text-align: center;
        font-weight: bold;
        padding-bottom: 60px;
        color: #2a2929;
    }

    .words {
        text-align: center;
        line-height: 26px;
        padding-bottom: 30px;
    }


}

.port-centent-box {

    background-color: #eef1fa;
    width: 100%;
    height: 425px;
    padding-top: 50px;


    .centent-img-box {
        display: flex;
        justify-content: center;
        width: 65%;
        height: 400px;
        margin: auto;
        padding-bottom: 40px;

        .el-row {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;

            .el-col {
                margin-bottom: 30px;

                .left {
                    padding: 20px 80px;
                    text-align: left;

                    .title {
                        font-size: 23px;
                        font-weight: bolder;
                    }
                }

                img {
                    padding: 0px 10px 20px 90px;
                    width: 80%;
                    height: 360px;
                }
            }
        }



    }

    .centent-img-box-v2 {
        display: flex;
        justify-content: center;
        width: 65%;
        height: 400px;
        margin: auto;

        .el-row {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;

            .el-col {
                margin-bottom: 30px;

                .left {
                    padding: 20px 80px;
                    text-align: left;

                    .title {
                        font-size: 23px;
                        font-weight: bolder;
                    }
                }

                img {
                    padding: 0px 10px 20px 30px;
                    width: 80%;
                    height: 360px;
                }
            }
        }



    }

    .title {
        padding-bottom: 10px;
        font-size: 20px;
        color: #4b4747;
        font-weight: bolder;
    }

    .title-desc {
        letter-spacing: 2px;
        line-height: 28px !important;
    }
}

.bottom-link {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 50px 0 30px;
    color: #FFF;
    background-color: #15213B;

    .el-row {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;

        .el-col {
            text-align: center;

            /* margin-right: 100px; */
            .title {
                font-size: 16px;
                font-weight: bolder;
                text-align: left;
                margin-bottom: 10px;
            }

            .sub-title {
                color: #95A5C9;
                border-bottom: 1px solid #1C2B4E;
                line-height: 35px;
                text-align: left;
                font-size: 14px;
            }
        }

    }
}


.bottom-container {
    padding: 30px 0;
    color: #ffffff;
    background-color: #15213B;
    border-top: 1px solid #41537A;

    .wrapper {
        width: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .link {
        padding-right: 20px;
        cursor: pointer;
    }
}
</style>