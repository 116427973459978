<template>
    <div>
        <div class="banner">
            <el-carousel class="sea-banner-box" height="100%" arrow="never" indicator-position="none" loop="false">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <div class="banner" :style="{ backgroundImage: `url(${item.img})` }">
                        <div class="title" v-html="item.title"></div>
                        <div class="text">{{ item.text }}</div>
                        <div class="link-btn"><router-link :to="{ name: 'concat' }">获取报价</router-link></div>
                    </div>
                </el-carousel-item>
                <div class="header-container">
                    <div class="header-box">
                        <div><span><img :src="logo" alt="" @click="goPage('/')" /></span>
                            <!-- <span>文舟供应链管理有限公司</span> -->
                        </div>
                        <el-menu class=" right-nav" mode="horizontal" @select="handleSelect">
                            <el-submenu index="1">
                                <template slot="title">主营业务</template>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasea' }"><img
                                                    src="../assets/icon_menu1.png" />
                                                电商及FBA海派</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasky' }"><img
                                                    src="../assets/icon_menu7.png" />
                                                电商及FBA空派</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'interexp' }"><img
                                                    src="../assets/icon_menu6.png" />
                                                国际快递</router-link></el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'importlogis' }"><img
                                                    src="../assets/icon_menu4.png" />
                                                进口物流</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item style="widows: 50px;"><router-link
                                                :to="{ name: 'portsea' }">
                                                <img src="../assets/icon_menu3.png" /> 港到港海运服务</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'door' }"><img
                                                    src="../assets/icon_menu2.png" />
                                                门到门整柜运输</router-link>
                                        </el-menu-item></el-col>

                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'seawarehouse' }"><img
                                                    src="../assets/icon_menu4.png" />
                                                美国自营海外仓</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'delivery' }"><img
                                                    src="../assets/icon_menu8.png" />
                                                美国清关+派送</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'intertrain' }"><img
                                                    src="../assets/icon_menu6.png" />
                                                国际班列</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'collectlogis' }"><img
                                                    src="../assets/icon_menu8.png" />
                                                集运物流</router-link></el-menu-item></el-col>
                                </el-row>
                            </el-submenu>
                            <el-menu-item index="2"><router-link
                                    :to="{ name: 'about' }">关于文舟</router-link></el-menu-item>
                            <el-menu-item index="3"><router-link
                                    :to="{ name: 'case' }">合作案例</router-link></el-menu-item>
                            <el-menu-item index="4"><router-link
                                    :to="{ name: 'concat' }">联系我们</router-link></el-menu-item>
                        </el-menu>

                    </div>
                </div>
            </el-carousel>
        </div>
        <div class="fba-content"><img src="../assets/fba1.png" />FBA拼箱</div>
        <div class="sea-second-box">
            <el-carousel class="second-box-carousel" height="100%" arrow="always" indicator-position="none"
                autoplay="false">
                <el-carousel-item v-for="(item, index) in fbaList" :key="index">
                    <div class="second">
                        <el-row>
                            <el-col :span="12">
                                <p>{{ item.p1 }}</p>
                                <h1>{{ item.title }}</h1>
                                <div class="text">{{ item.text }}</div>
                                <div class="text2">{{ item.text2 }}</div>
                            </el-col>
                            <el-col :span="8">
                                <div class="img" :style="{ backgroundImage: `url(${item.img})` }"></div>
                            </el-col>
                        </el-row>

                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="three-box">
            <el-row :gutter="0">
                <el-col :span="8" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <el-card :body-style="{ padding: '0px' }">
                        <img src="../assets/sea_icon1.png" class="image">
                        <div>
                            <span class="title">全国揽收</span>
                            <div class="title-desc">
                                全国29省2市顺丰速运上门揽收，2-5天一站直达，账务清晰，结算便捷
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="8" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <el-card :body-style="{ padding: '0px' }">
                        <img src="../assets/sea_icon2.png" class="image">
                        <div>
                            <span class="title">三地集货仓</span>
                            <div class="title-desc">
                                在上海、深圳、义乌设自营集货仓，完成货物分拣、贴标、绑扎、集装箱装柜
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>

        </div>
        <div class="three-box">
            <el-row>
                <el-col :span="8" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <el-card :body-style="{ padding: '0px' }">
                        <img src="../assets/sea_icon3.png" class="image">
                        <div>
                            <span class="title">智能拼箱</span>
                            <div class="title-desc">
                                自主开发数字化拼箱系统U-shipment，随时查价下单，价格透明，计费精准
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="8" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <el-card :body-style="{ padding: '0px' }">
                        <img src="../assets/sea_icon4.png" class="image">
                        <div>
                            <span class="title">海运订舱</span>
                            <div class="title-desc">
                                船到达目的港后完成清关手续，文舟自营海外仓安排拖车提柜
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>

        </div>
        <div class="three-box">
            <el-row>
                <el-col :span="8" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <el-card :body-style="{ padding: '0px' }">
                        <img src="../assets/sea_icon5.png" class="image">
                        <div>
                            <span class="title">全口岸接货</span>
                            <div class="title-desc">
                                船到达目的港后完成清关手续，文舟自营海外仓安排拖车提柜
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="8" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <el-card :body-style="{ padding: '0px' }">
                        <img src="../assets/sea_icon6.png" class="image">
                        <div>
                            <span class="title">整柜直送</span>
                            <div class="title-desc">
                                船到达目的港后完成清关手续，文舟自营海外仓安排拖车营海外仓安排拖车营海外仓安排拖车营海外仓安排拖车营海外仓安排拖车提柜
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>

        </div>
        <div class="three-box three-last-box">
            <el-row>
                <el-col :span="8" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <el-card :body-style="{ padding: '0px' }">
                        <img src="../assets/sea_icon7.png" class="image">
                        <div>
                            <span class="title">目的港派送</span>
                            <div class="title-desc">
                                自有打单系统，提供优质UPS/FedEx账号，支持UPS/FedEx一件代发或卡车转运
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="8" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <el-card :body-style="{ padding: '0px' }">
                        <img src="../assets/sea_icon8.png" class="image">
                        <div>
                            <span class="title">亚马逊服务商</span>
                            <div class="title-desc">
                                亚马逊SPN服务商
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>

        </div>
        <div class="contact-container">
            <div class="left-info">
                <div class="left-text-box">
                    <p>FBA拼箱/整柜</p>
                    <p class="p2">覆盖全美亚马逊仓库/沃尔玛仓库等电商平台海外仓及私人与商业地址</p>
                    <p class="p3">以稳定的时效、对货物极高的保障、有竞争力的价格和专业、认真的态度让我们成为亚马逊官方推荐物流服务商</p>
                    <p class="p4">（Amazon Solution Provider Network，即SPN）</p>
                </div>
            </div>
        </div>
        <div class="fbasea-centent-box">
            <div class="centent-img-box">
                <el-row>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title">全程保价</div>
                            <div class="title-desc">针对跨境物流周期长、节点多，容易产生丢包破损等问题提供全程保价服务
                                面向全品类、高货值产品，保价费率为3.5%，保价货值可达USD1500/箱
                                如在运输途中发生丢包、破损，将在7个工作日内按丢损货值完成全额赔付</div>
                            <div>
                                <p>适用产品</p>
                                <p>一票一件、一票多件、海外仓暂存转运、海外仓一件代发</p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="../assets/yw-zzfw1.jpg" class="image">

                    </el-col>
                </el-row>

            </div>
            <div class="centent-img-box-v2">
                <el-row>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="../assets/yw-zzfw2.jpg" class="image">
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title">顺丰揽收</div>
                            <div class="title-desc">与顺丰速运合作，全国上门揽收，2-5天可送达上海/义乌/深圳三地集货仓全国一站直达，一票收费，中途无需中转，财务对账清晰，结算便捷
                            </div>
                            <div>
                                <p>基础保价</p>
                                <p>单票：≤40KG，保500RMB；≤100KG，保1000RMB；＞100KG，保3000RMB</p>
                                <p>计费重泡比</p>
                                <p>1:166.67（毛重、泡重取大值）</p>
                            </div>
                        </div>
                    </el-col>
                </el-row>

            </div>
        </div>
        <div class="contact-container">
            <div class="left-info">
                <div class="left-text-box">
                    <p>增值服务</p>
                    <p class="p2">优化配套服务，提升用户体验</p>
                    <p class="p3">在提供跨境物流服务时，不局限于报关、订舱等传统服务，而是能够从不同类型客户的实际需求出发，在方案优化设计、综合物流服务等方面完善服务品类，提升服务品质。</p>
                </div>
            </div>
        </div>
        <div class="bottom-link">
            <el-row>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        主营业务
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'fbasea' }">电商及FBA海派</router-link></div>
                    <div class="sub-title"><router-link
                      :to="{ name: 'fbasky' }">电商及FBA空派</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'interexp' }">国际快递</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'importlogis' }">进口物流</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'portsea' }">
                      港到港海运服务</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'door' }"> 门到门整柜运输</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'seawarehouse' }"> 美国自营海外仓</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'delivery' }">美国清关+派送</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'intertrain' }"> 国际班列</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'collectlogis' }">集运物流</router-link></div>
                </el-col>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        关于文舟
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'about' }">关于文舟</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'case' }">合作案例</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'concat' }">联系我们</router-link></div>
                </el-col>
            </el-row>
        </div>
        <!-- 底部 -->
        <div class="bottom-container">
            <div class="wrapper">
                Copyright @ 2008-至今 上海文舟供应链管理有限公司 All rights reserved
                沪ICP备20000382号-3
            </div>
        </div>
    </div>
</template>

<script>
import banner1 from '@/assets/banner1.jpg';
import banner2 from '@/assets/banner2.jpg';
import banner3 from '@/assets/banner3.jpg';
import img1 from '@/assets/img1.jpg';
import img2 from '@/assets/img2.jpg';
import img3 from '@/assets/img3.jpg';
import img4 from '@/assets/img4.jpg';
import img5 from '@/assets/img5.jpg';
import img6 from '@/assets/img6.jpg';
import img7 from '@/assets/img7.png';

import fbabanner from '@/assets/fbabanner.jpg';
export default {
    name: 'FbaSeaView',
    data() {
        return {
            bannerList: [
                {
                    img: fbabanner,
                    title: '发件美国，求<span style="font-size:60px; color: #1f9e93;">【稳】</span>，选文舟',
                    text: '我们致力于推动跨境电商品牌出海，利用先进的物流数字化系统，充分整合资源，为客户提供全方位物流及增值服务。',
                }
            ],
            fbaList: [
                {
                    img: img1,
                    p1: '老牌庄口信誉好',
                    title: '-舱位【稳】',
                    text: '美森/EXX/OA联盟老牌庄口，为长期合作伙伴提供稳定舱位，信誉可查。',
                    text2: 'MATSON /EXX/OA ALLIANCE VETERAN ZHUANGKOU, TO PROVIDE LONG-TERM PARTNERS WITH STABLE SHIPPING SPACE, REPUTATION CAN BE CHECKED.',
                },
                {
                    img: img2,
                    p1: '全程透明',
                    title: '-心里【稳】',
                    text: '报价透明无隐藏，箱号公开货物轨迹透明，操作节点实时更新透明，查验状态海关处理进度透明，预约配送状态透明。',
                    text2: 'rajectory transparent quotation transparent no hide, no public goods, node real-time update of transparent operation, check the state customs processing progress transparent, booking shipment status transparent.',
                },
                {
                    img: img3,
                    p1: '卡车自营',
                    title: '-送货【稳】',
                    text: '自营洛杉矶纽约萨瓦纳海外仓，自营车辆超过30台，支持美国政府官网查询，支持视频验证。',
                    text2: 'Self-operated Los Angeles, New York Savannah overseas warehouse, self-operated vehicles more than 30, support the US government official website query, support video verification.',
                },
                {
                    img: img4,
                    p1: '更懂合规',
                    title: '-清关【稳】',
                    text: '常年美国海关法律师顾问团队，密切关注电商清关合规政策，CTPAT认证。',
                    text2: 'Perennial US customs law counsel team, pay close attention to e-commerce customs clearance compliance policy, CTPAT certification.',
                },
                {
                    img: img5,
                    p1: '不动包装',
                    title: '-货品【稳】',
                    text: '不改客户包装，不强行压缩体积，保障货物运输安全是第一位的，支持视频抽检货物包装状态。',
                    text2: 'rajectory transparent quotation transparent no hide, no public goods, node real-time update of transparent operation, check the state customs processing progress transparent, booking shipment status transparent.',
                },
                {
                    img: img6,
                    p1: '系统自研重资产',
                    title: '-实力【稳】',
                    text: '保持30人开发团队，保持主力线路车辆全自营，保障主要港口仓库全自营。',
                    text2: 'Keep 30 people development team, keep main line vehicles all proprietary, ensure all proprietary main port warehouse.',
                },
                {
                    img: img7,
                    p1: '自有账号',
                    title: '-配送【稳】',
                    text: '美国直签FEDEXUPS合约，自养账号超过6年，旺季配送运力资源支持，绝无跑水账号混用。',
                    text2: 'Keep 30 people development team, keep main line vehicles all proprietary, ensure all proprietary main port warehouse.',
                },
            ]
        };
    },
};
</script>
<style>
.body {
    color: #15213B;
    font-size: 14px;
    line-height: 28px;
    background-color: #eef1fa !important;
}

::v-deep .el-carousel__arrow i {
    font-size: 30px;
}

::v-deep .el-carousel__arrow {
    background-color: transparent;
}

::v-deep .el-carousel__arrow:hover {
    background-color: transparent;
}

.header-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;

    .el-menu .el-menu--horizontal {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: none;
    }

    .el-menu--horizontal {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: none;
    }

}

::v-deep .el-submenu__title:focus,
::v-deep .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

::v-deep .el-menu-item:focus,
::v-deep .el-menu-item:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    outline: 0;
    color: #909399 !important;
}

.el-menu--horizontal>.el-submenu:hover .el-submenu__title {
    color: #909399 !important;
}

.el-menu-item:focus,
.el-menu-item:hover {
    outline: 0;
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu-item,
.el-submenu__title {
    list-style: none;
}

.el-menu-item a {
    text-decoration: none;
}


::v-deep .el-menu--horizontal> ::v-deep .el-submenu ::v-deep .el-submenu__title {
    color: #909399 !important;
}

::v-deep .el-menu--horizontal ::v-deep .el-menu-item:not(.is-disabled):hover {
    color: #909399 !important;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399 !important;
}

.header-box {
    max-width: 1300px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 70px;
    margin: 0 auto;
    font-size: 14px;

    img {
        height: 70px;
        cursor: pointer;
    }

    span {
        display: inline-block;
        height: 70px;
        line-height: 70px;
        vertical-align: middle;
    }
}


a {
  text-decoration: none;
  font-weight: 400;
  color: #15213B;

}



.el-menu--horizontal {
  border-right: none;
  width: 30% !important;

  .el-row {
    margin: 0 !important;

    .el-col {
      width: 50%;
      padding: 10px;
      padding-right: 92px !important;

    }

    .el-col:hover {
      color: #000;
      background-color: #EEEEEE;
      border-radius: 5px;

      .el-menu-item {
        color: #000;
        background-color: #EEEEEE;
        border-radius: 5px;

      }
    }
  }
}

.right-nav {
    width: 50%;
    display: flex;
    border-bottom: none !important;

    .text {
        margin: 0 20px;
        color: #ffffff;
        cursor: pointer;

        &:hover {
            color: #82feff;
        }
    }
}

.sea-banner-box {
    width: 100%;
    height: 456px;
    position: relative;

    .banner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        background-position: center;
        background-size: cover;
    }

    .title {
        padding-bottom: 40px;
        font-size: 46px;
        color: #ffffff;
        font-weight: bolder;
        letter-spacing: 2px;
        padding-left: 25%;
    }

    .text {
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 2px;
        padding-left: 25%;
    }

    .link-btn {
        padding: 10px 35px;
        transition: all 1s;
        border-radius: 35px;
        background-color: #F5C12D;
        font-size: 16px;
        width: 4%;
        min-width: 65px;
        margin-left: 25%;
        margin-top: 20px;

    }

    .link-btn:hover {
        cursor: pointer;
        background-color: #1D9F95;
        transition: all 1s;
    }

    a {
        text-decoration: none;
        font-weight: 400;
        color: #15213B;

    }
}

.fba-content {
    width: 100%;
    line-height: 200px;
    font-size: 40px;
    text-align: center;
    background-color: #eef1fa;
}

.sea-second-box {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
    background-color: #eef1fa;

    .second-box-carousel {
        width: 80%;
        height: 334px;
        position: relative;

        /* background-color: antiquewhite; */
        .second {
            width: 100%;
            /* height: 100%; */
            display: flex;
            justify-content: center;
            flex-direction: column;
            /* text-align: center; */
            background-position: center;
            background-size: cover;

            .el-row {
                padding-left: 7%;

                .text {
                    font-size: 22px;
                    line-height: 38px;
                    min-height: 76px;
                }
            }

            .img {
                width: 589px;
                height: 457px;
            }
        }

        .title {
            padding-bottom: 40px;
            font-size: 46px;
            color: #ffffff;
            font-weight: bolder;
            letter-spacing: 2px;
        }

        .text {
            font-size: 14px;
            color: #15213B;
            letter-spacing: 2px;
        }

        .text2 {
            color: #747474;
            line-height: 24px;
            text-transform: uppercase;
        }
    }
}

.three-box {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #eef1fa;

    .el-row {
        width: 100%;
        display: flex;
        justify-content: center;

        .el-col {
            padding: 20px 16px;
            margin-bottom: -10px;

            .el-card {
                padding: 40px;
            }
        }

        .el-col-offset-2 {
            margin-left: 0%;
        }
    }

    .title {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        line-height: 40px;
        margin-top: 20px;
    }

    .title-desc {
        font-size: 16px;
        color: #999999;
        line-height: 30px;
        min-height: 90px;
    }

    .image {
        height: 40px !important;
    }

}

.three-last-box {
    padding-bottom: 20px;
}

.contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    background-color: #1d9f96;

    p {
        line-height: 35px;

    }

    .p2 {
        line-height: 50px;
    }

    .p3 {
        line-height: 40px;
    }

    .p4 {
        font-size: 20px;
    }
}

.left-info {
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 50px 0;

    p {
        text-align: center;
        color: #15213B;
        line-height: 35px;
    }

    .p2 {
        font-size: 40px;
        font-weight: bold;
        margin: 25px auto;
    }

    .p3 {
        font-size: 21px;
        font-weight: bold;
        width: 60%;
        margin: 15px auto 0;
        line-height: 40px;
    }

    .concat-title {
        line-height: normal;
        padding-bottom: 16px;
        font-size: 30px;
        line-height: 65px;
        text-align: center;
        font-weight: bold;
        padding-bottom: 60px;
        color: #2a2929;
    }

    .words {
        text-align: center;
        line-height: 26px;
        padding-bottom: 30px;
    }


}

.fbasea-centent-box {

    background-color: #eef1fa;
    width: 100%;
    height: 850px;
    padding-top: 50px;


    .centent-img-box {
        display: flex;
        justify-content: center;
        width: 65%;
        height: 400px;
        margin: auto;
        padding-bottom: 40px;

        .el-row {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;

            .el-col {
                margin-bottom: 30px;

                .left {
                    padding: 20px 80px;
                    text-align: left;

                    .title {
                        font-size: 23px;
                        font-weight: bolder;
                    }
                }

                img {
                    padding: 0px 10px 20px 90px;
                    width: 92%;
                    height: 330px;
                }
            }
        }



    }

    .centent-img-box-v2 {
        display: flex;
        justify-content: center;
        width: 65%;
        height: 400px;
        margin: auto;

        .el-row {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;

            .el-col {
                margin-bottom: 30px;

                .left {
                    padding: 20px 80px;
                    text-align: left;

                    .title {
                        font-size: 23px;
                        font-weight: bolder;
                    }
                }

                img {
                    padding: 0px 10px 20px 30px;
                    width: 92%;
                    height: 330px;
                }
            }
        }



    }

    .title {
        padding-bottom: 10px;
        font-size: 20px;
        color: #4b4747;
        font-weight: bolder;
    }

    .title-desc {
        letter-spacing: 2px;
        line-height: 28px !important;
    }
}

.bottom-link {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 50px 0 30px;
    color: #FFF;
    background-color: #15213B;

    .el-row {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;

        .el-col {
            text-align: center;

            /* margin-right: 100px; */
            .title {
                font-size: 16px;
                font-weight: bolder;
                text-align: left;
                margin-bottom: 10px;
            }

            .sub-title {
                color: #95A5C9;
                border-bottom: 1px solid #1C2B4E;
                line-height: 35px;
                text-align: left;
                font-size: 14px;
            }
        }

    }
}

.bottom-container {
    padding: 30px 0;
    color: #ffffff;
    background-color: #15213B;
    border-top: 1px solid #41537A;

    .wrapper {
        width: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .link {
        padding-right: 20px;
        cursor: pointer;
    }
}
</style>