<template>
    <div>
        <div class="banner">
            <el-carousel class="case-banner-box" height="100%" arrow="never" indicator-position="none" loop="false">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <div class="banner" :style="{ backgroundImage: `url(${item.img})` }">
                        <div class="title">{{ item.title }}</div>
                    </div>
                </el-carousel-item>
                <div class="header-container">
                    <div class="header-box">
                        <div><span><img :src="logo" alt="" @click="goPage('/')" /></span>
                            <!-- <span>文舟供应链管理有限公司</span> -->
                        </div>
                        <el-menu class=" right-nav" mode="horizontal" @select="handleSelect">
                            <el-submenu index="1">
                                <template slot="title">主营业务</template>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasea' }"><img
                                                    src="../assets/icon_menu1.png" />
                                                电商及FBA海派</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasky' }"><img
                                                    src="../assets/icon_menu7.png" />
                                                电商及FBA空派</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'interexp' }"><img
                                                    src="../assets/icon_menu6.png" />
                                                国际快递</router-link></el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'importlogis' }"><img
                                                    src="../assets/icon_menu4.png" />
                                                进口物流</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item style="widows: 50px;"><router-link
                                                :to="{ name: 'portsea' }">
                                                <img src="../assets/icon_menu3.png" /> 港到港海运服务</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'door' }"><img
                                                    src="../assets/icon_menu2.png" />
                                                门到门整柜运输</router-link>
                                        </el-menu-item></el-col>

                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'seawarehouse' }"><img
                                                    src="../assets/icon_menu4.png" />
                                                美国自营海外仓</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'delivery' }"><img
                                                    src="../assets/icon_menu8.png" />
                                                美国清关+派送</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'intertrain' }"><img
                                                    src="../assets/icon_menu6.png" />
                                                国际班列</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'collectlogis' }"><img
                                                    src="../assets/icon_menu8.png" />
                                                集运物流</router-link></el-menu-item></el-col>
                                </el-row>
                            </el-submenu>
                            <el-menu-item index="2"><router-link
                                    :to="{ name: 'about' }">关于文舟</router-link></el-menu-item>
                            <el-menu-item index="3"><router-link
                                    :to="{ name: 'case' }">合作案例</router-link></el-menu-item>
                            <el-menu-item index="4"><router-link
                                    :to="{ name: 'concat' }">联系我们</router-link></el-menu-item>
                        </el-menu>

                    </div>
                </div>
            </el-carousel>
        </div>

        <div class="case-centent-box">

            <div class="centent-img-box-v2">
                <el-row>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="../assets/case7.png" class="image">
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title"><img src="../assets/case13.jpg"></div>
                            <div class="title-desc">
                                文舟团队预先规划采用框架箱运输，提前与船公司确认尺寸、各港口吊机重量限制，进行配箱测算；安排场地、设备、绑扎物料，做好集货调箱及装箱方案。</div>

                        </div>
                    </el-col>
                </el-row>

            </div>

            <div class="centent-img-box">
                <el-row>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title"><img src="../assets/case12.jpg"></div>
                            <div class="title-desc">
                                接受委托后，文舟立即与对方公司专业人员沟通，收取箱体设计图、MSDS报告、消防系统组件说明及检测报告；提前与各船公司沟通，确认船期，提醒客户贴好危险品标签，考虑可能出现的突发状况，做好充分预案。
                            </div>

                        </div>
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="../assets/case6.png" class="image">

                    </el-col>


                </el-row>

            </div>
            <div class="centent-img-box-v2">
                <el-row>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="../assets/case5.png" class="image">
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title"><img src="../assets/case14.jpg"></div>
                            <div class="title-desc">
                                基于客户拥有自营海外仓，但提供亚马逊自发货服务较多的情况，针对灯具类产品对时效性的要求，文舟为客户提供了美森、CMA EXX等快船服务，确保及时送仓。
                            </div>
                        </div>
                    </el-col>
                </el-row>

            </div>

            <div class="centent-img-box">
                <el-row>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title"><img src="../assets/case11.jpg"></div>
                            <div class="title-desc">
                                货物运输前，文舟团队做好风险预案，安排专人跟进。基于时效要求和客户预算，文舟为其匹配了特价优质舱位，确保物流时效。货物运输中，文舟客服7*24小时实时跟踪，把控每一物流节点。
                            </div>

                        </div>
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="../assets/case4.png" class="image">

                    </el-col>


                </el-row>

            </div>
            <div class="centent-img-box-v2">
                <el-row>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="../assets/case3.png" class="image">
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title"><img src="../assets/case10.jpg"></div>
                            <div class="title-desc">
                                根据入库时间，文舟团队仔细核对货物品名、数值等，在清洁单证下确保零扣货，及时上架。货物送达海外仓后，文舟发挥自营仓优势为客户提供仓储、一件代发等服务，解决电商平台销售要求的海外库存、发货时效问题。

                            </div>
                        </div>
                    </el-col>
                </el-row>

            </div>

            <div class="centent-img-box">
                <el-row>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title"><img src="../assets/case9.jpg"></div>
                            <div class="title-desc">
                                货物到港后，熟悉线路的文舟洛杉矶自营卡车车队，及时安排提柜、转运，为客户降低了运输成本。针对客户转型跨境电商模式，文舟利用自营海外仓优势，为其提供衔接多个电商平台的增值服务，解决备货、自有仓库爆仓及退件返修问题，最终提高客户满意度。
                            </div>

                        </div>
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="../assets/case2.png" class="image">

                    </el-col>


                </el-row>

            </div>

            <div class="centent-img-box-v2">
                <el-row>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="../assets/case1.png" class="image">
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title"><img src="../assets/case8.jpg"></div>
                            <div class="title-desc">
                                根据客户需求及货物特点，我们为客户定制了LDP门到门物流服务综合解决方案。基于时效性要求和预算情况，客户选择了美森海卡物流方案，文舟根据货物交期，为客户匹配了美森周三正班船CLX航线，作为美森一级代理，为客户提前订舱。
                                货物运输中，文舟客服7*24小时实时跟进，让客户及时掌握物流节点。

                            </div>

                        </div>
                    </el-col>
                </el-row>

            </div>


        </div>


        <div class="bottom-link">
            <el-row>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        主营业务
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'fbasea' }">电商及FBA海派</router-link></div>
                    <div class="sub-title"><router-link
                      :to="{ name: 'fbasky' }">电商及FBA空派</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'interexp' }">国际快递</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'importlogis' }">进口物流</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'portsea' }">
                      港到港海运服务</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'door' }"> 门到门整柜运输</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'seawarehouse' }"> 美国自营海外仓</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'delivery' }">美国清关+派送</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'intertrain' }"> 国际班列</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'collectlogis' }">集运物流</router-link></div>
                </el-col>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        关于文舟
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'about' }">关于文舟</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'case' }">合作案例</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'concat' }">联系我们</router-link></div>
                </el-col>
            </el-row>
        </div>
        <!-- 底部 -->
        <div class="bottom-container">
            <div class="wrapper">
                Copyright @ 2008-至今 上海文舟供应链管理有限公司 All rights reserved
                沪ICP备20000382号-3
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/assets/case0.jpg';
import banner1 from '@/assets/banner1.jpg';
import banner2 from '@/assets/banner2.jpg';
import banner3 from '@/assets/banner3.jpg';
export default {
    name: 'FbaSeaView',
    data() {
        return {
            bannerList: [
                {
                    img: banner,
                    title: '合作案例',
                }
            ],
            fbaList: [
                {
                    img: banner1,
                    title: '专注北美 链通全球',
                    text: '为您提供专业的海运整柜/拼箱、关务、国际多式联运等门到门物流服务',
                },
                {
                    img: banner2,
                    title: '一体化 高稳定 快时效',
                    text: '国内拖车+订舱/报关+清关/海运+空运+中欧班列/自营海外仓+自营车队',
                },
                {
                    img: banner3,
                    title: '非洲整柜海运',
                    text: '丰富的航线资源，稳定的非洲内陆连接网络，资深的专家团队，确保货物按时送达',
                },
            ]
        };
    },
};
</script>
<style>
.body {
    color: #15213B;
    font-size: 14px;
    line-height: 28px;
    background-color: #eef1fa !important;
}

::v-deep .el-carousel__arrow i {
    font-size: 30px;
}

::v-deep .el-carousel__arrow {
    background-color: transparent;
}

::v-deep .el-carousel__arrow:hover {
    background-color: transparent;
}

.header-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;

    .el-menu .el-menu--horizontal {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: none;
    }

    .el-menu--horizontal {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: none;
    }

}

::v-deep .el-submenu__title:focus,
::v-deep .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

::v-deep .el-menu-item:focus,
::v-deep .el-menu-item:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    outline: 0;
    color: #909399 !important;
}

.el-menu--horizontal>.el-submenu:hover .el-submenu__title {
    color: #909399 !important;
}

.el-menu-item:focus,
.el-menu-item:hover {
    outline: 0;
    background-color: rgba(0, 0, 0, 0) !important;
}


::v-deep .el-menu--horizontal> ::v-deep .el-submenu ::v-deep .el-submenu__title {
    color: #909399 !important;
}

::v-deep .el-menu--horizontal ::v-deep .el-menu-item:not(.is-disabled):hover {
    color: #909399 !important;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399 !important;
}

.el-menu-item,
.el-submenu__title {
    list-style: none;
}

.el-menu-item a {
    text-decoration: none;
}

.header-box {
    max-width: 1300px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 70px;
    margin: 0 auto;
    font-size: 14px;

    img {
        height: 70px;
        cursor: pointer;
    }

    span {
        display: inline-block;
        height: 70px;
        line-height: 70px;
        vertical-align: middle;
    }
}

.right-nav {
    width: 50%;
    display: flex;
    border-bottom: none !important;

    .text {
        margin: 0 20px;
        color: #ffffff;
        cursor: pointer;

        &:hover {
            color: #82feff;
        }
    }
}

.case-banner-box {
    width: 100%;
    height: 456px;
    position: relative;

    .banner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        background-position: center;
        background-size: cover;
    }

    .sub-title {
        padding-bottom: 40px;
        color: #ffffff;
        font-weight: bolder;
        letter-spacing: 2px;
    }

    .title {
        padding-bottom: 40px;
        font-size: 46px;
        color: #ffffff;
        font-weight: bolder;
        letter-spacing: 2px;
    }

    .text {
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 2px;
    }
}

.fba-content {
    width: 100%;
    line-height: 200px;
    font-size: 40px;
    text-align: center;
    background-color: #eef1fa;
}

.second-box {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
    background-color: #eef1fa;

    .second-box-carousel {
        width: 70%;
        height: 334px;
        position: relative;

        /* background-color: antiquewhite; */
        .second {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            background-position: center;
            background-size: cover;
        }

        .title {
            padding-bottom: 40px;
            font-size: 46px;
            color: #ffffff;
            font-weight: bolder;
            letter-spacing: 2px;
        }

        .text {
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 2px;
        }
    }
}

.three-box {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #eef1fa;

    .el-row {
        width: 100%;
        display: flex;
        justify-content: center;

        .el-col {
            padding: 20px 16px;
            margin-bottom: -10px;

            .el-card {
                padding: 40px;
            }
        }

        .el-col-offset-2 {
            margin-left: 0%;
        }
    }

    .title {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        line-height: 40px;
        margin-top: 20px;
    }

    .title-desc {
        font-size: 16px;
        color: #999999;
        line-height: 30px;
        min-height: 90px;
    }

    .image {
        height: 40px !important;
    }

}

.three-last-box {
    padding-bottom: 20px;
}

.contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    background-color: #1d9f96;
}

.left-info {
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 50px 0;

    p {
        text-align: center;
        color: #15213B;
        line-height: 35px;
    }

    .p2 {
        font-size: 40px;
        font-weight: bold;
        margin: 25px auto;
    }

    .p3 {
        font-size: 21px;
        font-weight: bold;
        width: 60%;
        margin: 15px auto 0;
        line-height: 40px;
    }

    .concat-title {
        line-height: normal;
        padding-bottom: 16px;
        font-size: 30px;
        line-height: 65px;
        text-align: center;
        font-weight: bold;
        padding-bottom: 60px;
        color: #2a2929;
    }

    .words {
        text-align: center;
        line-height: 26px;
        padding-bottom: 30px;
    }


}

.case-centent-box {

    width: 100%;
    padding-top: 50px;


    .centent-img-box {
        display: flex;
        justify-content: center;
        width: 65%;
        /* height: 400px; */
        margin: auto;
        padding-bottom: 40px;

        .el-row {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;

            .el-col {
                width: 100%;
                margin-bottom: 30px;

                .left {
                    padding: 20px 80px;
                    text-align: left;

                    .title {
                        font-size: 23px;
                        font-weight: bolder;
                    }
                }

                img {
                    padding: 0px 10px 20px 0px;
                }
            }
        }



    }

    .centent-img-box-v2 {
        display: flex;
        justify-content: center;
        width: 65%;
        /* height: 400px; */
        margin: auto;

        .el-row {
            width: 70%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;

            .el-col {
                width: 100%;
                margin-bottom: 30px;

                .left {
                    padding: 20px 80px;
                    text-align: left;

                    .title {
                        font-size: 23px;
                        font-weight: bolder;
                    }
                }

                img {
                    padding: 0px 10px 20px 0px;
                }
            }
        }



    }

    .title {
        padding-bottom: 10px;
        font-size: 20px;
        color: #4b4747;
        font-weight: bolder;
    }

    .title-desc {
        letter-spacing: 2px;
        line-height: 28px !important;
    }
}

.table-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;

    .sanbiaoge {
        width: 70%;

        h1 {
            margin-top: 30px;
        }

        table {
            width: 100%;
            border-radius: 10px;
            margin-top: 20px;
            border-collapse: inherit;
            overflow: hidden;
            border: 0.5px solid #EFEFEF;

            th {
                border: 0.5px solid #EFEFEF;
                padding: 5px;
                text-align: center;
                font-size: 16px;
            }

            td {
                border: 0.5px solid #EFEFEF;
                padding: 5px;
                text-align: center;
            }
        }
    }
}



.bottom-link {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 50px 0 30px;
    color: #FFF;
    background-color: #15213B;

    .el-row {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;

        .el-col {
            text-align: center;

            /* margin-right: 100px; */
            .title {
                font-size: 16px;
                font-weight: bolder;
                text-align: left;
                margin-bottom: 10px;
            }

            .sub-title {
                color: #95A5C9;
                border-bottom: 1px solid #1C2B4E;
                line-height: 35px;
                text-align: left;
                font-size: 14px;
            }
        }

    }
}


.bottom-container {
    padding: 30px 0;
    color: #ffffff;
    background-color: #15213B;
    border-top: 1px solid #41537A;

    .wrapper {
        width: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .link {
        padding-right: 20px;
        cursor: pointer;
    }
}
</style>