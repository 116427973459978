<template>
    <div style="background-color: #eef1fa;">
        <div class="banner">
            <el-carousel class="port-banner-box" height="100%" arrow="never" indicator-position="none" loop="false">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <div class="banner" :style="{ backgroundImage: `url(${item.img})` }">
                        <div class="sub-title">{{ item.subTitle }}</div>
                        <div class="title">{{ item.title }}</div>
                        <div class="text">{{ item.text }}</div>
                        <div class="link-btn"><router-link :to="{ name: 'concat' }">获取报价</router-link></div>
                    </div>
                </el-carousel-item>
                <div class="header-container">
                    <div class="header-box">
                        <div><span><img :src="logo" alt="" @click="goPage('/')" /></span>
                            <!-- <span>文舟供应链管理有限公司</span> -->
                        </div>
                        <el-menu class=" right-nav" mode="horizontal" @select="handleSelect">
                            <el-submenu index="1">
                                <template slot="title">主营业务</template>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasea' }"><img
                                                    src="../assets/icon_menu1.png" />
                                                电商及FBA海派</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasky' }"><img
                                                    src="../assets/icon_menu7.png" />
                                                电商及FBA空派</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'interexp' }"><img
                                                    src="../assets/icon_menu6.png" />
                                                国际快递</router-link></el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'importlogis' }"><img
                                                    src="../assets/icon_menu4.png" />
                                                进口物流</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item style="widows: 50px;"><router-link
                                                :to="{ name: 'portsea' }">
                                                <img src="../assets/icon_menu3.png" /> 港到港海运服务</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'door' }"><img
                                                    src="../assets/icon_menu2.png" />
                                                门到门整柜运输</router-link>
                                        </el-menu-item></el-col>

                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'seawarehouse' }"><img
                                                    src="../assets/icon_menu4.png" />
                                                美国自营海外仓</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'delivery' }"><img
                                                    src="../assets/icon_menu8.png" />
                                                美国清关+派送</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'intertrain' }"><img
                                                    src="../assets/icon_menu6.png" />
                                                国际班列</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'collectlogis' }"><img
                                                    src="../assets/icon_menu8.png" />
                                                集运物流</router-link></el-menu-item></el-col>
                                </el-row>
                            </el-submenu>
                            <el-menu-item index="2"><router-link
                                    :to="{ name: 'about' }">关于文舟</router-link></el-menu-item>
                            <el-menu-item index="3"><router-link
                                    :to="{ name: 'case' }">合作案例</router-link></el-menu-item>
                            <el-menu-item index="4"><router-link
                                    :to="{ name: 'concat' }">联系我们</router-link></el-menu-item>
                        </el-menu>

                    </div>
                </div>
            </el-carousel>
        </div>

        <div>
            <div class="content">
                <div class="content-up"></div>
                <div class="content-middle">
                    <div class="content-img">
                        <div class="content-title"></div>
                        <div class="content-title-text">国际快递</div>
                    </div>
                    <div class="content-text">

                        <p>
                            文舟供应链一级或二级代理各大国际快递服务，包括中国邮政EMS，香港邮政EMS(Speedpost)，DHL，UPS，Fedex等等。物品价值高、要求运输速度快的包裹可以选择走国际快递，以确保安全与时效。
                        </p>
                        <h2>EMS</h2>
                        <img src="../assets/gjkd1.jpg" width="140" height="140" title="EMS国际快递"
                            style="float:left; margin:10px 15px 10px 0">
                        <p>
                            EMS是英文“Express Mail
                            Service”的简称，中文意思是“特快专递邮件业务”，是中国邮政及香港邮政所提供的的一个服务产品，主要是采取空运方式，加快递送速度，一般来说，根据地区远近，1－4个工作日到达。全球邮政特快专递业务是各国邮政开办的一项特殊邮政服务业务，该业务在各国邮政、海关、航空等部门均享有优先处理权、它以高速度、高质量为用户传递国际、国内紧急信函、文件资料、金融票据、商品货样等各类文件资料和物品。
                            目前，我国国际特快专递业务已与世界上200多个国家和地区建立了业务关系；国内已有近2000个大、中、小城市办理EMS业务。
                        </p>
                        <br>
                        <div id="clear"></div>
                        <br>
                        <h2>DHL</h2>
                        <img src="../assets/gjkd2.jpg" width="140" height="140" title="DHL国际快递"
                            style="float:left; margin:10px 15px 10px 0">
                        <p>
                            DHL是全球快递、洲际运输和航空货运的领导者，也是全球第一的海运和合同物流提供商。DHL为客户提供从文件到供应链管理的全系列的物流解决方案。
                            DHL这个名称来自于三个公司创始人姓氏的首字母，他们是Adrian Dalsey, Larry Hillblom 和 Robert Lynn。
                            1969年，在尼尔.阿姆斯壮迈出伟大的登月第一步的几个月后，三个合伙人也在一起迈出了一小步，这一小步将对全世界的商业运作方式将产生一个深远的影响。
                        </p><br>
                        <div id="clear"></div>
                        <br>
                        <br>
                        <h2>UPS</h2>
                        <img src="../assets/gjkd3.jpg" width="140" height="140" title="UPS国际快递"
                            style="float:left; margin:10px 15px 10px 0">
                        <p>
                            UPS是“United Parcel Service”的缩略语，中文译名为“美国联合包裹”。UPS
                            起源于1907年在美国西雅图成立的一家信差公司，以传递信件以及为零售店运送包裹起家。由于以“最好的服务、最低的价格”为业务原则，逐渐在整个美国西岸打开局面。到30年代，UPS的服务已遍布所有西部大城市，并开发了第一个机械包裹分拣系统。50年代，UPS取得了“公共运输承运人”的权利，将自己的包裹递送业务从零售店扩展到普通居民，从而成为美国邮政的直接竞争对手。
                        </p>
                        <p>
                            UPS于1907年作为一家信使公司成立于美国，通过明确地致力于支持全球商业的目标，UPS如今已发展到拥有300亿美元资产的大公司。如今的UPS，是一家全球性的公司，其商标是世界上最知名、最值得景仰的商标之一。作为世界上最大的快递承运商与包裹递送公司，同时也是专业的运输、物流、资本与电子商务服务的领导性的提供者。
                        </p><br>
                        <div id="clear"></div>
                        <br>
                        <h2>TNT</h2>
                        <img src="../assets/gjkd4.jpg" width="140" height="140" title="TNT国际快递"
                            style="float:left; margin:10px 15px 10px 0">
                        <p>
                            TNT集团是全球领先的快递和邮政服务提供商，总部设在荷兰。TNT快递成立于1946年，其国际网络覆盖世界200多个国家，提供一系列独一无二的全球整合性物流解决方案。此外，TNT还为澳大利亚以及欧洲、亚洲的许多主要国家提供业界领先的全国范围快递服务。TNT拥有43架飞机、2万辆货车，全球子公司近1000家，员工超过4万人。TNT同时还拥有数量众多的技术先进的分拣中心和完善齐全的设备资源，竭诚为客户提供业界最快捷、最可信赖的门到门送递服务。早在1988年，TNT就已进入中国市场。目前，TNT为客户提供从定时的门到门快递服务和供应链管理，到直邮服务的整合业务解决方案。TNT在中国拥有25直属运营分支机构，3个全功能国际口岸和近3000名员工，服务范围覆盖中国500多个城市。
                        </p><br>
                        <div id="clear"></div>
                        <br>
                        <h2>Fedex</h2>
                        <img src="../assets/gjkd5.jpg" width="140" height="140" title="Fedex国际快递"
                            style="float:left; margin:10px 15px 10px 0">
                        <p>
                            联邦快递（FedEx Express）隶属于美国联邦快递集团（FedEx Corp.），是集团快递运输业务的中坚力量。
                            联邦快递是全球最具规模的快递运输公司，为全球超过220个国家及地区提供快捷、可靠的快递服务。联邦快递设有环球航空及陆运网络，通常只需一至两个工作日，就能迅速运送时限紧迫的货件，而且确保准时送达。
                            联邦快递集团为遍及全球的顾客和企业提供涵盖运输、电子商务和商业运作等一系列的全面服务。作为一个久负盛名的企业品牌，联邦快递集团通过相互竞争和协调管理的运营模式，提供了一套综合的商务应用解决方案，使其年收入高达320亿美元。
                            联邦快递集团激励旗下超过26万名员工和承包商高度关注安全问题，恪守品行道德和职业操守的最高标准，并最大程度满足客户和社会的需求，使其屡次被评为全球最受尊敬和最可信赖的雇主。
                        </p>
                        <div id="clear"></div>

                    </div>

                </div>
                <div class="content-down"></div>
            </div>
        </div>

        <div class="bottom-link">
            <el-row>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        主营业务
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'fbasea' }">电商及FBA海派</router-link></div>
                    <div class="sub-title"><router-link
                      :to="{ name: 'fbasky' }">电商及FBA空派</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'interexp' }">国际快递</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'importlogis' }">进口物流</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'portsea' }">
                      港到港海运服务</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'door' }"> 门到门整柜运输</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'seawarehouse' }"> 美国自营海外仓</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'delivery' }">美国清关+派送</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'intertrain' }"> 国际班列</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'collectlogis' }">集运物流</router-link></div>
                </el-col>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        关于文舟
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'about' }">关于文舟</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'case' }">合作案例</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'concat' }">联系我们</router-link></div>
                </el-col>
            </el-row>
        </div>
        <!-- 底部 -->
        <div class="bottom-container">
            <div class="wrapper">
                Copyright @ 2008-至今 上海文舟供应链管理有限公司 All rights reserved
                沪ICP备20000382号-3
            </div>
        </div>
    </div>
</template>

<script>
import banner1 from '@/assets/banner1.jpg';
import banner2 from '@/assets/banner2.jpg';
import banner3 from '@/assets/banner3.jpg';
import banner from '@/assets/zyyw-banner5.jpg';
export default {
    name: 'FbaSeaView',
    data() {
        return {
            bannerList: [
                {
                    img: banner,
                    subTitle: '',
                    title: '国际快递',
                    text: '',
                }
            ],
            fbaList: [
                {
                    img: banner1,
                    title: '专注北美 链通全球',
                    text: '为您提供专业的海运整柜/拼箱、关务、国际多式联运等门到门物流服务',
                },
                {
                    img: banner2,
                    title: '一体化 高稳定 快时效',
                    text: '国内拖车+订舱/报关+清关/海运+空运+中欧班列/自营海外仓+自营车队',
                },
                {
                    img: banner3,
                    title: '非洲整柜海运',
                    text: '丰富的航线资源，稳定的非洲内陆连接网络，资深的专家团队，确保货物按时送达',
                },
            ]
        };
    },
};
</script>
<style>
.body {
    color: #15213B;
    font-size: 14px;
    line-height: 28px;
    background-color: #eef1fa !important;
}

::v-deep .el-carousel__arrow i {
    font-size: 30px;
}

::v-deep .el-carousel__arrow {
    background-color: transparent;
}

::v-deep .el-carousel__arrow:hover {
    background-color: transparent;
}

.header-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;

    .el-menu .el-menu--horizontal {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: none;
    }

    .el-menu--horizontal {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: none;
    }

}

::v-deep .el-submenu__title:focus,
::v-deep .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

::v-deep .el-menu-item:focus,
::v-deep .el-menu-item:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    outline: 0;
    color: #909399 !important;
}

.el-menu--horizontal>.el-submenu:hover .el-submenu__title {
    color: #909399 !important;
}

.el-menu-item:focus,
.el-menu-item:hover {
    outline: 0;
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu-item,
.el-submenu__title {
    list-style: none;
}

.el-menu-item a {
    text-decoration: none;
}


::v-deep .el-menu--horizontal> ::v-deep .el-submenu ::v-deep .el-submenu__title {
    color: #909399 !important;
}

::v-deep .el-menu--horizontal ::v-deep .el-menu-item:not(.is-disabled):hover {
    color: #909399 !important;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399 !important;
}

.header-box {
    max-width: 1300px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 70px;
    margin: 0 auto;
    font-size: 14px;

    img {
        height: 70px;
        cursor: pointer;
    }

    span {
        display: inline-block;
        height: 70px;
        line-height: 70px;
        vertical-align: middle;
    }
}

a {
  text-decoration: none;
  font-weight: 400;
  color: #15213B;

}



.el-menu--horizontal {
  border-right: none;
  width: 30% !important;

  .el-row {
    margin: 0 !important;

    .el-col {
      width: 50%;
      padding: 10px;
      padding-right: 92px !important;

    }

    .el-col:hover {
      color: #000;
      background-color: #EEEEEE;
      border-radius: 5px;

      .el-menu-item {
        color: #000;
        background-color: #EEEEEE;
        border-radius: 5px;

      }
    }
  }
}
.right-nav {
    width: 50%;
    display: flex;
    border-bottom: none !important;

    .text {
        margin: 0 20px;
        color: #ffffff;
        cursor: pointer;

        &:hover {
            color: #82feff;
        }
    }
}

.content {
    /* float: right; */
    /* width: 730px; */
    min-height: 100px;
    box-shadow: 0 0 2px #ddd;
    border-radius: 5px;
    padding: 40px 100px 40px;
}

/*.navlist,.content{ box-shadow:0 0 3px #333;}*/
.navlist-up {
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 18px;
    width: 100%;
    color: #fff;
    border-radius: 5px 5px 0 0;
}

.content-up {
    position: relative;
    height: 10px;
    width: 100%;
    border-radius: 5px 5px 0 0;
}

.navlist-middle {
    position: relative;
    background-color: #fff;
    line-height: 30px;
    font-size: 13px;
    font-weight: bold;
    box-shadow: 0px -1px 0px 1px #E0E0E0 inset;
}

.content-middle {
    position: relative;
    background-color: #fcfcfc;
    line-height: 25px;
    box-shadow: 0px 1px 0px 1px #E0E0E0 inset;
}

/*内容行距*/
.content-img {
    position: relative;
    background-color: #ebebeb;
    height: 265px;
    background-image: url(../assets/gjkd6.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.content-title {
    position: absolute;
    left: 0px;
    bottom: 0px;
    filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr='#666666', endColorStr='#ffffff 40%', gradientType='1');
    /*ie*/
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 60%);
    /*Mozilla*/
    background: -webkit-gradient(linear, 0 50%, 100% 10%, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /*Old gradient for webkit*/
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 60%);
    /*new gradient for Webkit*/
    background: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 60%);
    /*Opera11*/
    /*background-color:#000000; filter:alpha(opacity=30);-moz-opacity:0.3; -khtml-opacity: 0.3; opacity: 0.3; */
    width: 730px;
    height: 30px;
}

.content-title-text {
    position: absolute;
    left: 15px;
    bottom: 2px;
    font-size: 16px;
    line-height: 28px;
    font-weight: bold;
    text-shadow: #000000 3px 3px 15px;
    color: #FFFFFF;
}

.content-text {
    padding: 15px 20px;
    color: #666;
}

.content-text h1 {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    line-height: 45px
}

.content-text h2 {
    font-size: 16px;
    color: #339;
    font-weight: bold;
    line-height: 35px
}

.content-text h3 {
    font-size: 14px;
    color: #600;
    font-weight: bold;
    line-height: 30px
}

.content-text p {
    line-height: 40px;
    padding-bottom: 10px;
}

.content-text b {
    color: #e00;
}

.content-text a {
    COLOR: #c70;
}

/*.content-text ul {padding:0 0 25px 0}
.content-text ul li{margin:2px 0 5px 20px}内页的样式*/

.content-text ol {
    padding: 0 0 25px 0
}

.content-text ol li {
    margin: 2px 0 5px 20px
}

.content-text img {
    boder: 0px;
    height: auto;
    max-width: 100%;
    border-radius: 5px;
}


.content-text .Correct,
.Wrong,
.Exp,
.Normal {
    display: block;
    height: 20px;
    width: 350px;
    line-height: 17px;
    padding: 0 5px 0 2em;
}

/* .content-text .Correct {
    background: #e6ffe6 url(../images/register.png) 5px 2px no-repeat;
    border: 1px dashed #00a8ff;
    color: #008800;
}

.content-text .Wrong {
    background: #fff2e9 url(../images/register.png) 5px -79px no-repeat;
    border: 1px dashed #ff6600;
    color: #8b2b34;
}

.content-text .Exp {
    background: #e2f5ff url(../images/register.png) 5px -134px no-repeat;
    border: 1px dashed #00a8ff;
    color: #006699;
}

.content-text .Normal {
    background: #ffffff url(../images/register.png) 5px -134px no-repeat;
    border: 1px dashed #7F9DB9;
    color: #333333;
} */


.content-text .input_register {
    font-size: 14px;
    width: 200px;
    height: 22px;
    border: 1px solid #7F9DB9;
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 2px;
    padding-bottom: 0px;
    background-color: #FFFFFF;
}

.content-text .input_reset {
    font-size: 14px;
    width: 200px;
    height: 22px;
    border: 1px solid #7F9DB9;
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 2px;
    padding-bottom: 0px;
    background-color: #FFFFFF;
}


.navlist-down,
.content-down {
    height: 6px;
    border-radius: 0 0 5px 5px;
}

.navlist-up {
    background-color: #f60;
}

.content-up,
.content-down,
.navlist-down {
    background-color: #f93;
}

.bottom-link {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 50px 0 30px;
    color: #FFF;
    background-color: #15213B;

    .el-row {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;

        .el-col {
            text-align: center;

            /* margin-right: 100px; */
            .title {
                font-size: 16px;
                font-weight: bolder;
                text-align: left;
                margin-bottom: 10px;
            }

            .sub-title {
                color: #95A5C9;
                border-bottom: 1px solid #1C2B4E;
                line-height: 35px;
                text-align: left;
                font-size: 14px;
            }
        }

    }
}


.bottom-container {
    padding: 30px 0;
    color: #ffffff;
    background-color: #15213B;
    border-top: 1px solid #41537A;

    .wrapper {
        width: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .link {
        padding-right: 20px;
        cursor: pointer;
    }
}
</style>