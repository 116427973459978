<template>
    <div style="background-color: #eef1fa;">
        <div class="banner">
            <el-carousel class="port-banner-box" height="100%" arrow="never" indicator-position="none" loop="false">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <div class="banner" :style="{ backgroundImage: `url(${item.img})` }">
                        <div class="sub-title">{{ item.subTitle }}</div>
                        <div class="title">{{ item.title }}</div>
                        <div class="text">{{ item.text }}</div>
                        <div class="link-btn"><router-link :to="{ name: 'concat' }">获取报价</router-link></div>
                    </div>
                </el-carousel-item>
                <div class="header-container">
                    <div class="header-box">
                        <div><span><img :src="logo" alt="" @click="goPage('/')" /></span>
                            <!-- <span>文舟供应链管理有限公司</span> -->
                        </div>
                        <el-menu class=" right-nav" mode="horizontal" @select="handleSelect">
                            <el-submenu index="1">
              <template slot="title">主营业务</template>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasea' }"><img
                        src="../assets/icon_menu1.png" />
                      电商及FBA海派</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasky' }"><img
                        src="../assets/icon_menu7.png" />
                      电商及FBA空派</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'interexp' }"><img
                        src="../assets/icon_menu6.png" />
                      国际快递</router-link></el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'importlogis' }"><img
                        src="../assets/icon_menu4.png" />
                      进口物流</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item style="widows: 50px;"><router-link :to="{ name: 'portsea' }">
                      <img src="../assets/icon_menu3.png" /> 港到港海运服务</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'door' }"><img
                        src="../assets/icon_menu2.png" />
                      门到门整柜运输</router-link>
                  </el-menu-item></el-col>

              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'seawarehouse' }"><img
                        src="../assets/icon_menu4.png" />
                      美国自营海外仓</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'delivery' }"><img
                        src="../assets/icon_menu8.png" />
                      美国清关+派送</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'intertrain' }"><img
                        src="../assets/icon_menu6.png" />
                      国际班列</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'collectlogis' }"><img
                        src="../assets/icon_menu8.png" />
                      集运物流</router-link></el-menu-item></el-col>
              </el-row>
            </el-submenu>
                            <el-menu-item index="2"><router-link
                                    :to="{ name: 'about' }">关于文舟</router-link></el-menu-item>
                            <el-menu-item index="3"><router-link
                                    :to="{ name: 'case' }">合作案例</router-link></el-menu-item>
                            <el-menu-item index="4"><router-link
                                    :to="{ name: 'concat' }">联系我们</router-link></el-menu-item>
                        </el-menu>

                    </div>
                </div>
            </el-carousel>
        </div>

        <div>

            <div class="items items-steps   bg-primary ">
                <div class="center2">
                    <p class="main-title animate__animated  animate__fadeInRight" ani-name="fadeInRight" ani-delay="1s"
                        style="animation-delay: 1s;">
                        集运标准流程环节 确保品质服务</p>
                    <div class="items-block">
                        <div class="transition item animate__animated animate__rollIn" ani-name="rollInLeft"
                            ani-delay=".15s" style="animation-delay: 0.15s;">
                            <div class="img-block"><img src="../assets/jywl1.png" alt="img">
                            </div>
                            <p class="item-title">商品购物</p>
                            <p class="item-p">在淘宝天猫等网站购物</p>
                        </div>
                        <div class="transition item even  animate__animated animate__rollIn" ani-name="rollInLeft"
                            ani-delay=".3s" style="animation-delay: 0.3s;">
                            <div class="img-block"><img src="../assets/jywl2.png" alt="img">
                            </div>
                            <p class="item-title">预报运单</p>
                            <p class="item-p">将宝贝寄往我们的仓库并填写预报</p>
                        </div>
                        <div class="transition item  animate__animated animate__rollIn" ani-name="rollInLeft"
                            ani-delay=".45s" style="animation-delay: 0.45s;">
                            <div class="img-block"><img src="../assets/jywl3.png" alt="img">
                            </div>
                            <p class="item-title">等待入库</p>
                            <p class="item-p">等收到您的宝贝后将统一存放仓库</p>
                        </div>
                        <div class="transition item even  animate__animated animate__rollIn" ani-name="rollInLeft"
                            ani-delay=".6s" style="animation-delay: 0.6s;">
                            <div class="img-block"><img src="../assets/jywl4.png" alt="img">
                            </div>
                            <p class="item-title">打包提交订单</p>
                            <p class="item-p">待您提交申请后我们将为您全部打包出库</p>
                        </div>
                        <div class="transition item  animate__animated animate__rollIn" ani-name="rollInLeft"
                            ani-delay=".75s" style="animation-delay: 0.75s;">
                            <div class="img-block"><img src="../assets/jywl5.png" alt="img">
                            </div>
                            <p class="item-title">等待订单反馈</p>
                            <p class="item-p">等待完成打包出库海关申报等一系列操作</p>
                        </div>
                        <div class="transition item even  animate__animated animate__rollIn" ani-name="rollInLeft"
                            ani-delay=".9s" style="animation-delay: 0.9s;">
                            <div class="img-block"><img src="../assets/jywl6.png" alt="img">
                            </div>
                            <p class="item-title">支付订单</p>
                            <p class="item-p">完成打包出库后支付您的订单</p>
                        </div>
                        <div class="transition item  animate__animated animate__rollIn" ani-name="rollInLeft"
                            ani-delay="1.05s" style="animation-delay: 1.05s;">
                            <div class="img-block"><img src="../assets/jywl7.png" alt="img">
                            </div>
                            <p class="item-title">订单发货</p>
                            <p class="item-p">您的宝贝正在八百里加急运输中</p>
                        </div>
                        <div class="transition item even  animate__animated animate__rollIn" ani-name="rollInLeft"
                            ani-delay="1.2s" style="animation-delay: 1.2s;">
                            <div class="img-block"><img src="../assets/jywl8.png" alt="img">
                            </div>
                            <p class="item-title">完成订单</p>
                            <p class="item-p">收到宝贝后完成签收</p>
                        </div>
                        <div class="steps-block">
                            <div class="step"></div>
                            <div class="step"></div>
                            <div class="step"></div>
                            <div class="step"></div>
                            <div class="step"></div>
                            <div class="step"></div>
                            <div class="step"></div>
                            <div class="step"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bottom-link">
            <el-row>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        主营业务
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'fbasea' }">电商及FBA海派</router-link></div>
                    <div class="sub-title"><router-link
                      :to="{ name: 'fbasky' }">电商及FBA空派</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'interexp' }">国际快递</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'importlogis' }">进口物流</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'portsea' }">
                      港到港海运服务</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'door' }"> 门到门整柜运输</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'seawarehouse' }"> 美国自营海外仓</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'delivery' }">美国清关+派送</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'intertrain' }"> 国际班列</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'collectlogis' }">集运物流</router-link></div>
                </el-col>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        关于文舟
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'about' }">关于文舟</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'case' }">合作案例</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'concat' }">联系我们</router-link></div>
                </el-col>
            </el-row>
        </div>
        <!-- 底部 -->
        <div class="bottom-container">
            <div class="wrapper">
                Copyright @ 2008-至今 上海文舟供应链管理有限公司 All rights reserved
                沪ICP备20000382号-3
            </div>
        </div>
    </div>
</template>

<script>
import banner1 from '@/assets/banner1.jpg';
import banner2 from '@/assets/banner2.jpg';
import banner3 from '@/assets/banner3.jpg';
import banner from '@/assets/zyyw-banner5.jpg';

export default {
    name: 'FbaSeaView',
    data() {
        return {
            bannerList: [
                {
                    img: banner,
                    subTitle: '',
                    title: '集运物流',
                    text: '',
                }
            ],
            fbaList: [
                {
                    img: banner1,
                    title: '专注北美 链通全球',
                    text: '为您提供专业的海运整柜/拼箱、关务、国际多式联运等门到门物流服务',
                },
                {
                    img: banner2,
                    title: '一体化 高稳定 快时效',
                    text: '国内拖车+订舱/报关+清关/海运+空运+中欧班列/自营海外仓+自营车队',
                },
                {
                    img: banner3,
                    title: '非洲整柜海运',
                    text: '丰富的航线资源，稳定的非洲内陆连接网络，资深的专家团队，确保货物按时送达',
                },
            ]
        };
    },
};
</script>
<style>
.body {
    color: #15213B;
    font-size: 14px;
    line-height: 28px;
    background-color: #eef1fa !important;
}

::v-deep .el-carousel__arrow i {
    font-size: 30px;
}

::v-deep .el-carousel__arrow {
    background-color: transparent;
}

::v-deep .el-carousel__arrow:hover {
    background-color: transparent;
}

.header-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;

    .el-menu .el-menu--horizontal {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: none;
    }

    .el-menu--horizontal {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: none;
    }

}

::v-deep .el-submenu__title:focus,
::v-deep .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

::v-deep .el-menu-item:focus,
::v-deep .el-menu-item:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    outline: 0;
    color: #909399 !important;
}

.el-menu--horizontal>.el-submenu:hover .el-submenu__title {
    color: #909399 !important;
}

.el-menu-item:focus,
.el-menu-item:hover {
    outline: 0;
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu-item,
.el-submenu__title {
    list-style: none;
}

.el-menu-item a {
    text-decoration: none;
}


::v-deep .el-menu--horizontal> ::v-deep .el-submenu ::v-deep .el-submenu__title {
    color: #909399 !important;
}

::v-deep .el-menu--horizontal ::v-deep .el-menu-item:not(.is-disabled):hover {
    color: #909399 !important;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399 !important;
}

.header-box {
    max-width: 1300px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 70px;
    margin: 0 auto;
    font-size: 14px;

    img {
        height: 70px;
        cursor: pointer;
    }

    span {
        display: inline-block;
        height: 70px;
        line-height: 70px;
        vertical-align: middle;
    }
}

.right-nav {
    width: 50%;
    display: flex;
    border-bottom: none !important;

    .text {
        margin: 0 20px;
        color: #ffffff;
        cursor: pointer;

        &:hover {
            color: #82feff;
        }
    }
}

.items {
    padding: 60px 0;
    overflow: hidden;
}

.center2 {
    width: 1200px;
    margin: 0 auto;
    position: relative;

    *,
    ::after,
    ::before {
        box-sizing: border-box;
    }

    .main-title {
        font-size: 30px;
        text-align: center;
        margin-bottom: 50px;
    }

    .items-block {
        width: 1200px;
        height: 355px;
        position: relative;
        margin: 90px 0 30px;
    }
}

.items-steps .item.even {
    top: auto;
    bottom: 0;
}

.items-steps .item.even:after {
    top: -7px;
    bottom: auto;
}

.items-steps .item.even .img-block {
    margin: 0 0 0 -35px;
    position: absolute;
    left: 50%;
    bottom: -35px;
}

.items-steps .item.cur {
    margin-top: -10px;
}

.items-steps .item {
    position: absolute;
    top: 0;
    width: 156px;
    height: 150px;
    background-color: #fff;
    border-radius: 5px;
    /* transform: translateX(-156px); */
    margin-left: -78px;
    padding: 10px;
    z-index: 1;
    box-shadow: 0 0 5px 2px rgba(135, 135, 135, 0.15);
    backface-visibility: hidden;
}

.items-steps .item:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    z-index: -1;
}

.items-steps .img-block {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #fff;
    margin-top: -35px;
    position: relative;
    transition-duration: 1s;
}

.items-steps .img-block:before {
    content: "";
    width: 100%;
    height: 100%;
    box-shadow: 0 0 5px 2px rgba(135, 135, 135, 0.15);
    position: absolute;
    z-index: -2;
    border-radius: 50%;
    left: 0;
    top: 0;
}

.img-block {
    margin: 0 auto;
    display: block;
}

.items-steps .img-block img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.img-block img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
}

img {
    border: none;
}

/* img {
    max-width: 100%;
    height: auto;
} */

.items-steps .item-title {
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
}

p {
    color: #1b1919;
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: 400;
}

.items-steps .item-p {
    color: #999;
    text-align: center;
    margin-bottom: 5px;
}

.items-steps .item:after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    left: 50%;
    bottom: -7px;
    box-shadow: 0 0 5px 2px rgba(135, 135, 135, 0.15);
    background-color: #fff;
    z-index: -2;
    transform: translateX(-50%) rotateZ(135deg);
}

.items-steps .steps-block {
    position: absolute;
    height: 24px;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.items-steps .steps-block:before {
    content: "";
    position: absolute;
    width: 99.4%;
    height: 8px;
    background-color: #E9E9E9;
    border-radius: 2px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.items-steps .step {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    transform: translateX(-12px);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .2);
}


.items-steps .step:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #509cff;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .3s ease;
}

.items-steps .step:before {
    background-color: #da251c;
}

.items-steps .steps-block:after {
    content: "";
    position: absolute;
    width: 26px;
    height: 38px;
    background: url(../assets/rightjt.png) no-repeat center;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}


.items-steps .item:nth-child(1),
.items-steps .step:nth-child(1) {
    left: 75px;
}

.items-steps .item:nth-child(2),
.items-steps .step:nth-child(2) {
    left: 225px;
}

.items-steps .item:nth-child(3),
.items-steps .step:nth-child(3) {
    left: 375px;
}

.items-steps .item:nth-child(4),
.items-steps .step:nth-child(4) {
    left: 525px;
}

.items-steps .item:nth-child(5),
.items-steps .step:nth-child(5) {
    left: 675px;
}

.items-steps .item:nth-child(6),
.items-steps .step:nth-child(6) {
    left: 825px;
}

.items-steps .item:nth-child(7),
.items-steps .step:nth-child(7) {
    left: 975px;
}

.items-steps .item:nth-child(8),
.items-steps .step:nth-child(8) {
    left: 1125px;
}

.bg-primary {
    background-color: #f9f9f9 !important;




}



.bottom-link {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 50px 0 30px;
    color: #FFF;
    background-color: #15213B;

    .el-row {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;

        .el-col {
            text-align: center;

            /* margin-right: 100px; */
            .title {
                font-size: 16px;
                font-weight: bolder;
                text-align: left;
                margin-bottom: 10px;
            }

            .sub-title {
                color: #95A5C9;
                border-bottom: 1px solid #1C2B4E;
                line-height: 35px;
                text-align: left;
                font-size: 14px;
            }
        }

    }
}


.bottom-container {
    padding: 30px 0;
    color: #ffffff;
    background-color: #15213B;
    border-top: 1px solid #41537A;

    .wrapper {
        width: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .link {
        padding-right: 20px;
        cursor: pointer;
    }
}
</style>