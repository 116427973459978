<template>
    <div style="background-color: #fff;">
        <div class="banner">
            <el-carousel class="about-banner-box" height="100%" arrow="never" indicator-position="none" loop="false">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <div class="banner" :style="{ backgroundImage: `url(${item.img})` }">
                        <div class="title">{{ item.title }}</div>
                        <div class="text">{{ item.text }}</div>
                    </div>
                </el-carousel-item>
                <div class="header-container">
                    <div class="header-box">
                        <div><span><img :src="logo" alt="" @click="goPage('/')" /></span>
                            <!-- <span>文舟供应链管理有限公司</span> -->
                        </div>
                        <el-menu class=" right-nav" mode="horizontal" @select="handleSelect">
                            <el-submenu index="1">
                                <template slot="title">主营业务</template>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasea' }"><img
                                                    src="../assets/icon_menu1.png" />
                                                电商及FBA海派</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasky' }"><img
                                                    src="../assets/icon_menu7.png" />
                                                电商及FBA空派</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'interexp' }"><img
                                                    src="../assets/icon_menu6.png" />
                                                国际快递</router-link></el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'importlogis' }"><img
                                                    src="../assets/icon_menu4.png" />
                                                进口物流</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item style="widows: 50px;"><router-link
                                                :to="{ name: 'portsea' }">
                                                <img src="../assets/icon_menu3.png" /> 港到港海运服务</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'door' }"><img
                                                    src="../assets/icon_menu2.png" />
                                                门到门整柜运输</router-link>
                                        </el-menu-item></el-col>

                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'seawarehouse' }"><img
                                                    src="../assets/icon_menu4.png" />
                                                美国自营海外仓</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'delivery' }"><img
                                                    src="../assets/icon_menu8.png" />
                                                美国清关+派送</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'intertrain' }"><img
                                                    src="../assets/icon_menu6.png" />
                                                国际班列</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'collectlogis' }"><img
                                                    src="../assets/icon_menu8.png" />
                                                集运物流</router-link></el-menu-item></el-col>
                                </el-row>
                            </el-submenu>
                            <el-menu-item index="2"><router-link
                                    :to="{ name: 'about' }">关于文舟</router-link></el-menu-item>
                            <el-menu-item index="3"><router-link
                                    :to="{ name: 'case' }">合作案例</router-link></el-menu-item>
                            <el-menu-item index="4"><router-link
                                    :to="{ name: 'concat' }">联系我们</router-link></el-menu-item>
                        </el-menu>

                    </div>
                </div>
            </el-carousel>
        </div>
        <div class="about-fba-content">公司简介</div>
        <div class="about-centent-box">
            <div class="centent-img-box">
                <el-row>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title-desc">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;文舟供应链是注册于<span>中国上海的一家国际物流综合服务商</span>，致力于跨境电子商务中美专线物流解决方案的定制与实施，多年来文舟供应链充分整合航运、港口、铁路、仓储、卡车等物流资源，推动跨境物流服务的快速全面发展，为跨境电商提供一站式门到门物流解决方案。
                            </div>
                            <br />
                            <br />
                            <div class="title-desc">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;文舟供应链具备<span>美国联邦海事委员会（FMC）及中国交通部备案的无船承运人（NVOCC）资质</span>，自2020年成立以来，文舟供应链已在中国多座城市设立自营分公司，构建高度协同的物流网络；24小时客服服务，实现问题随提随答，解决跨境电商卖家对于物流运输的疑问，我们始终助力跨境电商“品牌出海”。
                            </div>
                            <br />
                            <br />
                            <div class="title-desc">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的服务包括海运拼箱、海运整柜、FBA头程、海外仓、独立站物流，在较长的跨境物流周期内，每一个物流节点，我们都将第一时间提醒，实时掌握您的物流情况，直到您的货物安全送达。同时为为跨境卖家提供从集货，海运拼柜/空运打板，整柜海运/空运，清关，目的港拖车，海外仓拆柜/暂存/一件代发，到卡车配送的一站式，全覆盖国际物流服务。团队脱胎于传统国际货运代理，团队核心成员超10年行业经验与行业资源。公司站点遍布中国华东，华北，华南“一切为了履约”。我们将持续加大跨境物流基础设施投入，加速全球网络布局，无论您身处世界各地，我们都将为您履约交付。保障您的供应链国际物流部分稳定，高效，可视！
                            </div>

                        </div>
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="../assets/about-banner1.jpg" class="image">

                    </el-col>
                </el-row>

            </div>

        </div>


        <div class="culture-content">企业文化</div>
        <div class="culture-box">
            <el-row>
                <el-col :span="6" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        使命
                    </div>
                    <div class="sub-title">以标准化产品、强履约服务保障跨境物流稳定、高效</div>

                </el-col>
                <el-col :span="6" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        愿景
                    </div>
                    <div class="sub-title">跨境物流产品标准化先行者与履约服务品质领跑者</div>

                </el-col>
                <el-col :span="6" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        企业精神
                    </div>
                    <div class="sub-title">【诺】一诺千金 不负所托 【成】成人达己 成己为人<br />【敢】敢想敢做 勇于担当 【韧】坚忍不拔 竭尽全力</div>

                </el-col>
                <el-col :span="6" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        核心价值观
                    </div>
                    <div class="sub-title">客户第一、专业高效、诚信正直、勤奋敬业<br />教学相长、拥抱变化、成人达己、成己为人</div>

                </el-col>
            </el-row>
        </div>

        <div class="lanbg d-none d-sm-block">
            <div class="ls-row bg-common">
                <div class="ls-row-box">
                    <div class="title">全球网络布局</div>
                    <div class="text">
                        立足于中美跨境电商发展，在国内多座城市设自营公司及物流基础设施，<br>着眼于跨境电商全球化进程，搭建高效、协同的全球物流网络，连接优质跨境电商卖家，赋能更多中国品牌出海。
                    </div>
                    <div class="about-map">

                        <div class="network-item network-china-sh" id="networkchina"><img class="network-map-icon"
                                src="../assets/network-bg-zb.png">
                            <div class="network-citys" id="chinacity"><span class="country-title">中国</span> <span
                                    class="citys">上海</span></div>
                        </div>

                        <div class="network-item network-china-yw"><img class="network-map-icon"
                                src="../assets/network-bg.png">
                            <div class="network-citys" id="chinacity" style="display: none;"><span
                                    class="country-title">中国</span> <span class="citys">义乌</span></div>
                        </div>
                        <div class="network-item network-china-nb"><img class="network-map-icon"
                                src="../assets/network-bg.png">
                            <div class="network-citys" id="chinacity" style="display: none;"><span
                                    class="country-title">中国</span> <span class="citys">宁波</span></div>
                        </div>
                        <div class="network-item network-china-nt"><img class="network-map-icon"
                                src="../assets/network-bg.png">
                            <div class="network-citys" id="chinacity" style="display: none;"><span
                                    class="country-title">中国</span> <span class="citys">南通</span></div>
                        </div>
                        <div class="network-item network-china-hf"><img class="network-map-icon"
                                src="../assets/network-bg.png">
                            <div class="network-citys" id="chinacity" style="display: none;"><span
                                    class="country-title">中国</span> <span class="citys">杭州</span></div>
                        </div>


                        <img src="../assets/cnmap2.png">
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="kuang2">
                <h1 class="lanse">荣誉资质</h1>
                <ul class="row">
                    <li class="col-12 col-sm-4"><img src="../assets/honor1.png" class="img-fluid">亚马逊SPN物流服务提供商</li>
                    <li class="col-12 col-sm-4"><img src="../assets/honor2.png" class="img-fluid">美国国会特别认可证书</li>
                    <li class="col-12 col-sm-4"><img src="../assets/honor3.png" class="img-fluid">美森一级代理</li>
                    <li class="col-12 col-sm-4"><img src="../assets/honor4.png" class="img-fluid">中美无船承运人资质</li>
                    <li class="col-12 col-sm-4"><img src="../assets/honor5.png" class="img-fluid">C-TPAT美国反恐认证</li>
                    <li class="col-12 col-sm-4"><img src="../assets/honor6.png" class="img-fluid">SGS认证供应商</li>
                    <li class="col-12 col-sm-4"><img src="../assets/honor7.png" class="img-fluid">FMC美国联邦海事委员会认证</li>
                    <li class="col-12 col-sm-4"><img src="../assets/honor8.png" class="img-fluid">WCA世界货物运输联盟会员</li>
                    <li class="col-12 col-sm-4"><img src="../assets/honor9.png" class="img-fluid">GLA全球物流联盟会员</li>
                    <li class="col-12 col-sm-4"><img src="../assets/honor10.png" class="img-fluid">LCD跨境电商合作与发展组织会员</li>
                    <li class="col-12 col-sm-4"><img src="../assets/honor11.png" class="img-fluid">2018-2012GCP会员</li>
                    <li class="col-12 col-sm-4"><img src="../assets/honor12.png" class="img-fluid">2021、2022十大人气海外仓</li>
                    <li class="col-12 col-sm-4"><img src="../assets/honor13.png" class="img-fluid">2019-2020诚信通会员</li>
                </ul>
            </div>
        </div>

        <div class="bottom-link">
            <el-row>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        主营业务
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'fbasea' }">电商及FBA海派</router-link></div>
                    <div class="sub-title"><router-link
                      :to="{ name: 'fbasky' }">电商及FBA空派</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'interexp' }">国际快递</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'importlogis' }">进口物流</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'portsea' }">
                      港到港海运服务</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'door' }"> 门到门整柜运输</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'seawarehouse' }"> 美国自营海外仓</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'delivery' }">美国清关+派送</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'intertrain' }"> 国际班列</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'collectlogis' }">集运物流</router-link></div>
                </el-col>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        关于文舟
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'about' }">关于文舟</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'case' }">合作案例</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'concat' }">联系我们</router-link></div>
                </el-col>
            </el-row>
        </div>
        <!-- 底部 -->
        <div class="bottom-container">
            <div class="wrapper">
                Copyright @ 2008-至今 上海文舟供应链管理有限公司 All rights reserved
                沪ICP备20000382号-3
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/assets/about-banner1.jpg';
import banner1 from '@/assets/banner1.jpg';
import banner2 from '@/assets/banner2.jpg';
import banner3 from '@/assets/banner3.jpg';

import Swiper from 'swiper';

import 'swiper/css/swiper.min.css'

// Import Swiper styles
// import 'swiper/css';
export default {
    mounted() {
        var swiperInstance = new Swiper('.swiper-container', {
            slidesPerView: 3,
            spaceBetween: 30,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        });
    },
    methods: {
        onSwiper() {

        },
        onSlideChange() {

        }
    },


    name: 'FbaSeaView',
    data() {

        return {
            swiperInstance: null,
            bannerList: [
                {
                    img: banner,
                    title: '关于文舟',
                    text: 'ABOUT US',
                }
            ],
            fbaList: [
                {
                    img: banner1,
                    title: '发件美国，求【稳】，选文舟',
                    text: '我们致力于推动跨境电商品牌出海，利用先进的物流数字化系统，充分整合资源，为客户提供全方位物流及增值服务。',
                },
                {
                    img: banner2,
                    title: '一体化 高稳定 快时效',
                    text: '国内拖车+订舱/报关+清关/海运+空运+中欧班列/自营海外仓+自营车队',
                },
                {
                    img: banner3,
                    title: '非洲整柜海运',
                    text: '丰富的航线资源，稳定的非洲内陆连接网络，资深的专家团队，确保货物按时送达',
                },
            ]
        };
    },

};
</script>
<style>
.body {
    color: #15213B;
    font-size: 14px;
    line-height: 28px;
    background-color: #eef1fa !important;
}


::v-deep .el-carousel__arrow i {
    font-size: 30px;
}

::v-deep .el-carousel__arrow {
    background-color: transparent;
}

::v-deep .el-carousel__arrow:hover {
    background-color: transparent;
}

.header-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;

    .el-menu .el-menu--horizontal {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: none;
    }

    .el-menu--horizontal {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: none;
    }

}

::v-deep .el-submenu__title:focus,
::v-deep .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

::v-deep .el-menu-item:focus,
::v-deep .el-menu-item:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    outline: 0;
    color: #909399 !important;
}

.el-menu--horizontal>.el-submenu:hover .el-submenu__title {
    color: #909399 !important;
}

.el-menu-item:focus,
.el-menu-item:hover {
    outline: 0;
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu-item,
.el-submenu__title {
    list-style: none;
}

.el-menu-item a {
    text-decoration: none;
}


::v-deep .el-menu--horizontal> ::v-deep .el-submenu ::v-deep .el-submenu__title {
    color: #909399 !important;
}

::v-deep .el-menu--horizontal ::v-deep .el-menu-item:not(.is-disabled):hover {
    color: #909399 !important;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399 !important;
}

.header-box {
    max-width: 1300px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 70px;
    margin: 0 auto;
    font-size: 14px;

    img {
        height: 70px;
        cursor: pointer;
    }

    span {
        display: inline-block;
        height: 70px;
        line-height: 70px;
        vertical-align: middle;
    }
}

a {
    text-decoration: none;
    font-weight: 400;
    color: #15213B;

}



.el-menu--horizontal {
    border-right: none;
    width: 30% !important;

    .el-row {
        margin: 0 !important;

        .el-col {
            width: 50%;
            padding: 10px;
            padding-right: 92px !important;

        }

        .el-col:hover {
            color: #000;
            background-color: #EEEEEE;
            border-radius: 5px;

            .el-menu-item {
                color: #000;
                background-color: #EEEEEE;
                border-radius: 5px;

            }
        }
    }
}


.about-banner-box {
    width: 100%;
    height: 456px;
    position: relative;

    .banner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        background-position: center;
        background-size: cover;
    }

    .title {
        padding-bottom: 40px;
        font-size: 46px;
        color: #ffffff;
        font-weight: bolder;
        letter-spacing: 2px;
    }

    .text {
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 2px;
    }
}

.about-fba-content {
    width: 100%;
    line-height: 200px;
    font-size: 40px;
    text-align: center;
    background-color: #fff;
}

.going-line-title {
    width: 100%;
    line-height: 200px;
    font-size: 40px;
    text-align: center;
}

.second-box {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
    background-color: #fff;

    .second-box-carousel {
        width: 70%;
        height: 334px;
        position: relative;

        /* background-color: antiquewhite; */
        .second {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            background-position: center;
            background-size: cover;
        }

        .title {
            padding-bottom: 40px;
            font-size: 46px;
            color: #ffffff;
            font-weight: bolder;
            letter-spacing: 2px;
        }

        .text {
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 2px;
        }
    }
}

.three-box {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #eef1fa;

    .el-row {
        width: 100%;
        display: flex;
        justify-content: center;

        .el-col {
            padding: 20px 16px;
            margin-bottom: -10px;

            .el-card {
                padding: 40px;
            }
        }

        .el-col-offset-2 {
            margin-left: 0%;
        }
    }

    .title {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        line-height: 40px;
        margin-top: 20px;
    }

    .title-desc {
        font-size: 16px;
        color: #999999;
        line-height: 30px;
        min-height: 90px;
    }

    .image {
        height: 40px !important;
    }

}

.three-last-box {
    padding-bottom: 20px;
}

.contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    background-color: #1d9f96;
}

.left-info {
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 50px 0;

    p {
        text-align: center;
        color: #15213B;
        line-height: 35px;
    }

    .p2 {
        font-size: 40px;
        font-weight: bold;
        margin: 25px auto;
    }

    .p3 {
        font-size: 21px;
        font-weight: bold;
        width: 60%;
        margin: 15px auto 0;
        line-height: 40px;
    }

    .concat-title {
        line-height: normal;
        padding-bottom: 16px;
        font-size: 30px;
        line-height: 65px;
        text-align: center;
        font-weight: bold;
        padding-bottom: 60px;
        color: #2a2929;
    }

    .words {
        text-align: center;
        line-height: 26px;
        padding-bottom: 30px;
    }


}

.about-centent-box {

    background-color: #fff;
    width: 100%;
    /* height: 525px; */
    padding-top: 50px;

    .centent-img-box {
        display: flex;
        justify-content: center;
        width: 65%;
        /* height: 400px; */
        margin: auto;

        .el-row {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;

            .el-col {
                margin-bottom: 30px;

                .left {
                    padding: 0px 50px;
                    text-align: left;

                    .title {
                        font-size: 23px;
                        font-weight: bolder;
                    }

                    span {
                        text-decoration: underline;
                    }
                }

                img {
                    padding: 0px 10px 20px 30px;
                    width: 80%;
                    height: 360px;
                }
            }
        }



    }

    .title {
        padding-bottom: 10px;
        font-size: 20px;
        color: #4b4747;
        font-weight: bolder;
    }

    .title-desc {
        color: #15213B;
        letter-spacing: 2px;
        line-height: 28px !important;
    }
}

.going-box {
    max-width: 1100px;
    margin: 0 auto;
    /* background-image: url(../images/kongyun2.png); */
    background-position: center;
    background-size: cover;
    padding: 45px;

    .swiper-container {
        height: 180px;
        background-color: #edeef1;
    }

    .swiper-wrapper {

        .swiper-slide {

            .life-box {
                bor div {
                    padding: 20px 0px 30px 20px;
                }

                p {
                    padding: 0px 0px 30px 20px;
                }
            }
        }

        /* line-height: 180px; */
    }
}


.culture-content {
    width: 100%;
    line-height: 150px;
    font-size: 40px;
    text-align: center;
    background-color: #fff;
}

.culture-box {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 20px 0 30px;
    color: #FFF;
    background-color: #fff;

    .el-row {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: center; */
        width: 70%;
        text-align: center;

        .el-col {
            text-align: center;
            width: 50%;
            padding-right: 45px;

            /* margin-right: 100px; */
            .title {
                font-size: 20px;
                border-bottom: 1px solid #C5C5C7;
                padding-bottom: 5px;
                margin-bottom: 5px;
                font-weight: bolder;
                text-align: left;
                margin-bottom: 10px;
                border-bottom: 1px solid #C5C5C7;
                line-height: 28px !important;
                color: #15213B;
            }

            .sub-title {
                color: #15213B;
                text-align: left;
                font-size: 14px;
                margin: 0px 0 40px 0;
            }
        }

    }
}

.lanbg {
    background: #1D9F96;
}

.ls-row-box {
    overflow: hidden;

    img {
        width: 100%;
    }
}

.ls-row-box .title {
    text-align: center;
    font-size: 20px;
    margin-top: 50px;
    color: #15213B;
}

.ls-row-box .text {
    margin-top: 10px;
    text-align: center;
    white-space: pre-wrap;
    line-height: 20px;
}

.ls-row-box .about-map {
    margin-top: 100px;
    margin-bottom: 100px;
}

.ls-row .title {
    font-size: 34px;
    line-height: 55px;
    font-family: wcn;
    font-weight: normal
}

.layui-carousel {
    margin-bottom: 24px
}

.about-org {
    margin-top: 35px;
}

.about-map {
    width: 1200px;
    margin: 0 auto;
    position: relative;

}


.network-item:hover {

    .network-citys {
        display: block !important;
        padding: 15px 20px;
        margin-left: -10%;
        z-index: 101;
        position: absolute;
        min-width: 130px;
        background-color: #f0f0f0;
        border-radius: 12px;
        box-shadow: #ccc 0px 0px 10px;
    }
}

.network-map-icon {
    display: block;
    z-index: 99;
}

.network-item {
    width: 20px;
    height: 25px;
    overflow: initial;
}

.network-china {
    position: absolute;
    top: 287px;
    right: 275px;
}

.network-china-sc {
    position: absolute;
    top: 311px;
    right: 274px;
}

.network-china-hk {
    position: absolute;
    top: 303px;
    right: 256px;
}

.network-china-cq {
    position: absolute;
    top: 250px;
    right: 324px;
}

.network-china-tj {
    position: absolute;
    top: 170px;
    right: 200px;
}

.network-china-sh {
    position: absolute;
    top: 578px;
    right: 282px;
    z-index: 10
}

.network-china-wh {
    position: absolute;
    top: 247px;
    right: 293px;
}

.network-china-nj {
    position: absolute;
    top: 295px;
    right: 210px;
}

.network-china-yw {
    position: absolute;
    top: 633px;
    right: 321px;
}

.network-china-hf {
    position: absolute;
    top: 603px;
    right: 307px;
}

.network-china-qd {
    position: absolute;
    top: 231px;
    right: 223px;
}

.network-china-nb {
    position: absolute;
    top: 609px;
    right: 286px;
}

.network-china-nt {
    position: absolute;
    top: 579px;
    right: 288px;
}

.network-china-xm {
    position: absolute;
    top: 281px;
    right: 247px;
}

.chinacity {
    margin-left: -300% !important;
}

.network-yn {
    position: absolute;
    top: 340px;
    right: 251px;
}

.network-tg {
    position: absolute;
    top: 325px;
    right: 283px;
}

.network-usa {
    position: absolute;
    top: 262px;
    left: 171px;
}

.network-usa-xsd {
    position: absolute;
    top: 220px;
    left: 331px;
}

.network-usa-fes {
    position: absolute;
    top: 253px;
    left: 291px;
}

.network-de {
    position: absolute;
    top: 187px;
    left: 570px;
}

.network-uk {
    position: absolute;
    top: 152px;
    left: 542px;
}

.network-mx {
    position: absolute;
    top: 312px;
    left: 222px;
}

.with340 {
    width: 340px;
}

.network-citys {
    padding: 15px 20px;
    margin-left: -10%;
    z-index: 101;
    position: absolute;
    min-width: 130px;
    background-color: #f0f0f0;
    border-radius: 12px;
    box-shadow: #ccc 0px 0px 10px;
    display: none;
}

.network-citys .country-title {
    display: block;
    font-size: 16px;
    font-weight: bold;
}

.network-citys .citys {
    display: block;
    margin-top: 10px;
}

.usacity {
    min-width: 90px;
}

.company-org {
    width: 1200px;
    margin: 0 auto;
}

.company-org-box {
    display: block;
    width: 1077px;
    height: 603px;
    margin: 0 auto;
    margin-top: 100px;
}


.kuang2 {
    padding: 60px 0
}

.kuang2 h1 {
    font-size: 34px;
    text-align: center;
    line-height: 55px;
    margin-bottom: 50px;
    font-family: wcn;
    font-weight: normal
}



.kuang2 ul li {
    text-align: center;
    margin-bottom: 15px;
    line-height: 45px;
    list-style: none;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 465px;
    margin-left: 465px;
}
.col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}

.bottom-link {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 50px 0 30px;
    color: #FFF;
    background-color: #15213B;

    .el-row {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;

        .el-col {
            text-align: center;

            /* margin-right: 100px; */
            .title {
                font-size: 16px;
                font-weight: bolder;
                text-align: left;
                margin-bottom: 10px;
            }

            .sub-title {
                color: #95A5C9;
                border-bottom: 1px solid #1C2B4E;
                line-height: 35px;
                text-align: left;
                font-size: 14px;
            }
        }

    }
}

.bottom-container {
    padding: 30px 0;
    color: #ffffff;
    background-color: #15213B;
    border-top: 1px solid #41537A;

    .wrapper {
        width: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .link {
        padding-right: 20px;
        cursor: pointer;
    }
}
</style>