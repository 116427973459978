<template>
    <div style="background-color: #eef1fa;">
        <div class="banner">
            <el-carousel class="sky-banner-box" height="100%" arrow="never" indicator-position="none" loop="false">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <div class="banner" :style="{ backgroundImage: `url(${item.img})` }">
                        <div class="text">{{ item.text }}</div>
                        <div class="title">{{ item.title }}</div>
                        <div class="link-btn"><router-link :to="{ name: 'concat' }">获取报价</router-link></div>
                    </div>
                </el-carousel-item>
                <div class="header-container">
                    <div class="header-box">
                        <div><span><img :src="logo" alt="" @click="goPage('/')" /></span>
                            <!-- <span>文舟供应链管理有限公司</span> -->
                        </div>
                        <el-menu class=" right-nav" mode="horizontal" @select="handleSelect">
                            <el-submenu index="1">
                                <template slot="title">主营业务</template>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasea' }"><img
                                                    src="../assets/icon_menu1.png" />
                                                电商及FBA海派</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasky' }"><img
                                                    src="../assets/icon_menu7.png" />
                                                电商及FBA空派</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'interexp' }"><img
                                                    src="../assets/icon_menu6.png" />
                                                国际快递</router-link></el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'importlogis' }"><img
                                                    src="../assets/icon_menu4.png" />
                                                进口物流</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item style="widows: 50px;"><router-link
                                                :to="{ name: 'portsea' }">
                                                <img src="../assets/icon_menu3.png" /> 港到港海运服务</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'door' }"><img
                                                    src="../assets/icon_menu2.png" />
                                                门到门整柜运输</router-link>
                                        </el-menu-item></el-col>

                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'seawarehouse' }"><img
                                                    src="../assets/icon_menu4.png" />
                                                美国自营海外仓</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'delivery' }"><img
                                                    src="../assets/icon_menu8.png" />
                                                美国清关+派送</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'intertrain' }"><img
                                                    src="../assets/icon_menu6.png" />
                                                国际班列</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'collectlogis' }"><img
                                                    src="../assets/icon_menu8.png" />
                                                集运物流</router-link></el-menu-item></el-col>
                                </el-row>
                            </el-submenu>
                            <el-menu-item index="2"><router-link
                                    :to="{ name: 'about' }">关于文舟</router-link></el-menu-item>
                            <el-menu-item index="3"><router-link
                                    :to="{ name: 'case' }">合作案例</router-link></el-menu-item>
                            <el-menu-item index="4"><router-link
                                    :to="{ name: 'concat' }">联系我们</router-link></el-menu-item>
                        </el-menu>

                    </div>
                </div>
            </el-carousel>
        </div>
        <div class="fba-content"><img src="../assets/fba1.png" />账号稳定</div>
        <div class="sky-centent-box">
            <div class="centent-img-box">
                <el-row>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title-desc">重视商誉，从未使用跑水账号;Fedex自养快递账号6年，耗费巨资只为稳定标准件优势价格</div>
                            <div>
                                <p class="desc-en">ATTACH IMPORTANCE TO GOODWILL, NEVER USE A RUNNING ACCOUNT; FEDEX
                                    RAISED ITS OWN EXPRESS ACCOUNT FOR 6 YEARS, AND SPENT HUGE SUMS TO STABILIZE THE
                                    ADVANTAGEOUS PRICE OF STANDARD PARTS</p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="../assets/img7.png" class="image">

                    </el-col>
                </el-row>

            </div>

        </div>
        <div class="fba-content"><img src="../assets/fba1.png" />舱位稳定</div>
        <div class="flight-table">
            <table border="2" cellspacing="0">
                <thead>
                    <tr>
                        <th colspan="6">空运CONSOL目前开每周四/每周天航班，PVG-ORD/LAX航班统配，每个航班固定，2700kg/17cbm固定位置</th>
                    </tr>
                    <tr>
                        <th>时间</th>
                        <th>地点</th>
                        <th>航空公司</th>
                        <th>航班号</th>
                        <th>中国时间</th>
                        <th>美国时间</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowspan="2">周四/周天</td>
                        <td rowspan="2">上海到芝加哥ORD航班</td>
                        <td rowspan="2">南航</td>
                        <td>CZ227</td>
                        <td>09:40</td>
                        <td>11:25</td>
                    </tr>
                    <tr>
                        <td>CZ437</td>
                        <td>06:00</td>
                        <td>07:05</td>
                    </tr>
                    <tr>
                        <td rowspan="2">周四/周天</td>
                        <td rowspan="2">上海到洛杉矶LAX航班</td>
                        <td rowspan="2">东航</td>
                        <td>CK221</td>
                        <td>14:30</td>
                        <td>10:45</td>
                    </tr>
                    <tr>
                        <td>CK441</td>
                        <td>00:05</td>
                        <td>19:30</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="box-num">
            <el-row>
                <el-col :span="6" v-for="(o, index) in lineList" :key="o" :offset="index > 0 ? 2 : 0">
                    <el-card :body-style="{ padding: '0px' }">
                        <img :src="o.img" class="">
                        <div style="padding: 0px;">
                            <span>{{ o.title }}</span>
                            <!-- <div class="bottom clearfix"></div> -->
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>

        <div class="fba-content"><img src="../assets/fba1.png" />卡车自营</div>
        <div class="sky-second-box">
            <el-carousel class="second-box-carousel" height="100%" arrow="always" indicator-position="none"
                autoplay="false">
                <el-carousel-item v-for="(item, index) in carList" :key="index">
                    <!-- <div class="second" :style="{ backgroundImage: `url(${item.img})` }">
                        <div class="title">{{ item.title }}</div>
                        <div class="text">{{ item.text }}</div>
                    </div> -->
                    <div class="dispatch-img" :style="{ backgroundImage: `url(${item.img})` }"></div>
                    <div class="dispatch-text">
                        <h4>配有 Box Truck/53‘尺 Trailor 12台，保障机场到Fedex货站及门点派送服务稳定可靠</h4>
                        <p>equipped with Box Truck/53: foot Trailor 12 sets, to ensure the airport to Fedex cargo
                            station and gate delivery service stable and reliable</p>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="bottom-link">
            <el-row>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        主营业务
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'fbasea' }">电商及FBA海派</router-link></div>
                    <div class="sub-title"><router-link
                      :to="{ name: 'fbasky' }">电商及FBA空派</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'interexp' }">国际快递</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'importlogis' }">进口物流</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'portsea' }">
                      港到港海运服务</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'door' }"> 门到门整柜运输</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'seawarehouse' }"> 美国自营海外仓</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'delivery' }">美国清关+派送</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'intertrain' }"> 国际班列</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'collectlogis' }">集运物流</router-link></div>
                </el-col>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        关于文舟
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'about' }">关于文舟</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'case' }">合作案例</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'concat' }">联系我们</router-link></div>
                </el-col>
            </el-row>
        </div>
        <!-- 底部 -->
        <div class="bottom-container">
            <div class="wrapper">
                Copyright @ 2008-至今 上海文舟供应链管理有限公司 All rights reserved
                沪ICP备20000382号-3
            </div>
        </div>
    </div>
</template>

<script>
import banner1 from '@/assets/banner1.jpg';
import banner2 from '@/assets/banner2.jpg';
import banner3 from '@/assets/banner3.jpg';
import sky4 from '@/assets/sky4.jpg';
import car1 from '@/assets/sky1.png';
import car2 from '@/assets/kongpai5.png';
import line1 from '@/assets/sky3.gif';
import line2 from '@/assets/sky2.png';
import line3 from '@/assets/sky5.png';
export default {
    name: 'FbaSeaView',
    data() {
        return {
            bannerList: [
                {
                    img: sky4,
                    title: '包板直飞，全程自营，更快更稳',
                    text: '电商及FBA空派',
                }
            ],
            fbaList: [
                {
                    img: banner1,
                    title: '发件美国，求【稳】，选文舟',
                    text: '我们致力于推动跨境电商品牌出海，利用先进的物流数字化系统，充分整合资源，为客户提供全方位物流及增值服务。',
                },
                {
                    img: banner2,
                    title: '一体化 高稳定 快时效',
                    text: '国内拖车+订舱/报关+清关/海运+空运+中欧班列/自营海外仓+自营车队',
                },
                {
                    img: banner3,
                    title: '非洲整柜海运',
                    text: '丰富的航线资源，稳定的非洲内陆连接网络，资深的专家团队，确保货物按时送达',
                },
            ],
            carList: [
                {
                    img: car1
                },
                {
                    img: car2
                }
            ],
            lineList: [
                {
                    img: line1,
                    title: '南航板量'
                },
                {
                    img: line2,
                    title: '东航板量'
                },
                {
                    img: line3,
                    title: '国航板量'
                }
            ]
        };
    },
};
</script>
<style>
.body {
    color: #15213B;
    font-size: 14px;
    line-height: 28px;
    background-color: #eef1fa !important;
}

::v-deep .el-carousel__arrow i {
    font-size: 30px;
}

::v-deep .el-carousel__arrow {
    background-color: transparent;
}

::v-deep .el-carousel__arrow:hover {
    background-color: transparent;
}

.header-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;

    .el-menu .el-menu--horizontal {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: none;
    }

    .el-menu--horizontal {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: none;
    }

}

::v-deep .el-submenu__title:focus,
::v-deep .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

::v-deep .el-menu-item:focus,
::v-deep .el-menu-item:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    outline: 0;
    color: #909399 !important;
}

.el-menu--horizontal>.el-submenu:hover .el-submenu__title {
    color: #909399 !important;
}

.el-menu-item:focus,
.el-menu-item:hover {
    outline: 0;
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu-item,
.el-submenu__title {
    list-style: none;
}

.el-menu-item a {
    text-decoration: none;
}


::v-deep .el-menu--horizontal> ::v-deep .el-submenu ::v-deep .el-submenu__title {
    color: #909399 !important;
}

::v-deep .el-menu--horizontal ::v-deep .el-menu-item:not(.is-disabled):hover {
    color: #909399 !important;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399 !important;
}

.header-box {
    max-width: 1300px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 70px;
    margin: 0 auto;
    font-size: 14px;

    img {
        height: 70px;
        cursor: pointer;
    }

    span {
        display: inline-block;
        height: 70px;
        line-height: 70px;
        vertical-align: middle;
    }
}

a {
    text-decoration: none;
    font-weight: 400;
    color: #15213B;

}



.el-menu--horizontal {
    border-right: none;
    width: 30% !important;

    .el-row {
        margin: 0 !important;

        .el-col {
            width: 50%;
            padding: 10px;
            padding-right: 92px !important;

        }

        .el-col:hover {
            color: #000;
            background-color: #EEEEEE;
            border-radius: 5px;

            .el-menu-item {
                color: #000;
                background-color: #EEEEEE;
                border-radius: 5px;

            }
        }
    }
}

.right-nav {
    width: 50%;
    display: flex;
    border-bottom: none !important;

    .text {
        margin: 0 20px;
        color: #ffffff;
        cursor: pointer;

        &:hover {
            color: #82feff;
        }
    }
}

.sky-banner-box {
    width: 100%;
    height: 456px;
    position: relative;

    .banner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        background-position: center;
        background-size: cover;
    }

    .title {
        padding-bottom: 40px;
        font-size: 46px;
        color: #ffffff;
        font-weight: bolder;
        letter-spacing: 2px;
        padding-left: 25%;

    }

    .text {
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 2px;
        line-height: 80px;
        padding-left: 25%;

    }

    .link-btn {
        padding: 10px 35px;
        transition: all 1s;
        border-radius: 35px;
        background-color: #F5C12D;
        font-size: 16px;
        width: 4%;
        min-width: 65px;
        margin-left: 25%;
        margin-top: 20px;

    }

    .link-btn:hover {
        cursor: pointer;
        background-color: #1D9F95;
        transition: all 1s;
    }

    a {
        text-decoration: none;
        font-weight: 400;
        color: #15213B;

    }
}

.fba-content {
    width: 100%;
    line-height: 200px;
    font-size: 40px;
    text-align: center;
    background-color: #eef1fa;
}

.sky-second-box {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
    background-color: #eef1fa;
    background-color: #eef1fa;

    .el-carousel__item {
        width: 100%;
        display: inline-block;
        overflow: hidden;
        z-index: 0;
        display: flex;
        display: flex;
        justify-content: flex-start;
        background: #60bab4;
    }


    .second-box-carousel {
        width: 100%;
        height: 555px;
        position: relative;

        .dispatch-img {
            width: 50%;
            background-repeat: no-repeat;
            background-position: center;
        }

        .dispatch-text {
            width: 50%;
            margin: 100px 90px;
            margin-left: -30px;
            padding: 50px;
            background: #fff;

            h4 {
                line-height: 50px;
                font-size: 30px;
                color: #5f5f5f;
                letter-spacing: 5px;
                /* min-height: 150px; */
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                margin-bottom: 40px;
                text-align: justify;
            }

            p {
                font-size: 20px;
                color: #747474;
                word-break: break-all;
                text-transform: uppercase;
                line-height: 30px;
                min-height: 90px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                text-align: justify;
            }
        }

        /* background-color: antiquewhite; */
        .second {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            background-position: center;
            background-size: cover;
        }

        .title {
            padding-bottom: 40px;
            font-size: 46px;
            color: #ffffff;
            font-weight: bolder;
            letter-spacing: 2px;
        }

        .text {
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 2px;
        }
    }
}

.three-box {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #eef1fa;

    .el-row {
        width: 100%;
        display: flex;
        justify-content: center;

        .el-col {
            padding: 20px 16px;
            margin-bottom: -10px;

            .el-card {
                padding: 40px;
            }
        }

        .el-col-offset-2 {
            margin-left: 0%;
        }
    }

    .title {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        line-height: 40px;
        margin-top: 20px;
    }

    .title-desc {
        font-size: 16px;
        color: #999999;
        line-height: 30px;
        min-height: 90px;
    }

    .image {
        height: 40px !important;
    }

}

.three-last-box {
    padding-bottom: 20px;
}

.contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    background-color: #1d9f96;
}

.left-info {
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 50px 0;

    p {
        text-align: center;
        color: #15213B;
        line-height: 35px;
    }

    .p2 {
        font-size: 40px;
        font-weight: bold;
        margin: 25px auto;
    }

    .p3 {
        font-size: 21px;
        font-weight: bold;
        width: 60%;
        margin: 15px auto 0;
        line-height: 40px;
    }

    .concat-title {
        line-height: normal;
        padding-bottom: 16px;
        font-size: 30px;
        line-height: 65px;
        text-align: center;
        font-weight: bold;
        padding-bottom: 60px;
        color: #2a2929;
    }

    .words {
        text-align: center;
        line-height: 26px;
        padding-bottom: 30px;
    }


}

.sky-centent-box {

    background-color: #eef1fa;
    width: 100%;
    height: 425px;
    padding-top: 50px;

    .centent-img-box {
        display: flex;
        justify-content: center;
        width: 65%;
        height: 400px;
        margin: auto;

        .el-row {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;

            .el-col {
                margin-bottom: 30px;

                .left {
                    padding: 20px 80px;
                    text-align: left;

                    .title {
                        font-size: 23px;
                        font-weight: bolder;
                    }

                    .title-desc {
                        line-height: 50px !important;
                    }
                }

                img {
                    padding: 0px 10px 20px 30px;
                    width: 80%;
                    height: 360px;
                }
            }
        }



    }

    .title {
        padding-bottom: 10px;
        font-size: 20px;
        color: #4b4747;
        font-weight: bolder;
    }

    .title-desc {
        font-size: 1.5rem;
        letter-spacing: 2px;
        color: #373737;
        line-height: 50px !important;
        min-height: 220px;

        letter-spacing: 2px;
        text-align: justify;
    }

    .desc-en {

        color: #747474;

        font-size: 14px;
        color: #747474;
        line-height: 30px;
        word-break: break-all;
        text-transform: uppercase;
        text-align: justify;
    }

}

.flight-table {
    max-width: 1100px;
    margin: 0 auto;
    /* background-image: url(../images/kongyun2.png); */
    background-position: center;
    background-size: cover;
    padding: 45px;
}

.flight-table table {
    width: 100%;
    border: 1px solid #8dced4;
    text-align: center;
    font-size: 24px;
    color: #373737;
}

table,
td {
    border-collapse: collapse;
}

tr th {
    width: calc(100%/7);
    font-weight: 500;
    padding: 10px;
    line-height: 36px;
    word-break: break-all;
}

.flight-table table tr td,
.flight-table table tr th {
    width: calc(100%/7);
    font-weight: 500;
    padding: 10px;
    line-height: 36px;
    word-break: break-all;
}

.box-num {

    .el-row {
        display: flex;
        justify-content: center;

        .el-card:hover {
            color: #1d9f96;
            border-bottom: 3px solid #1d9f96;



        }

        img {
            width: 100%;
        }

        div {
            background: #fff;
            line-height: 60px;
            font-size: 30px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}


.bottom-link {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 50px 0 30px;
    color: #FFF;
    background-color: #15213B;

    .el-row {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;

        .el-col {
            text-align: center;

            /* margin-right: 100px; */
            .title {
                font-size: 16px;
                font-weight: bolder;
                text-align: left;
                margin-bottom: 10px;
            }

            .sub-title {
                color: #95A5C9;
                border-bottom: 1px solid #1C2B4E;
                line-height: 35px;
                text-align: left;
                font-size: 14px;
            }
        }

    }
}

.bottom-container {
    padding: 30px 0;
    color: #ffffff;
    background-color: #15213B;
    border-top: 1px solid #41537A;

    .wrapper {
        width: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .link {
        padding-right: 20px;
        cursor: pointer;
    }
}
</style>