<template>
  <div class="home">
    <!-- banner -->
    <el-carousel class="banner-box" height="100%" arrow="always" indicator-position="none">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <div class="banner" :style="{ backgroundImage: `url(${item.img})` }">
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.text }}</div>
        </div>
      </el-carousel-item>
      <div class="header-container">
        <div class="header-box">
          <div><span><img :src="logo" alt="" @click="goPage('/')" /></span>
            <!-- <span>文舟供应链管理有限公司</span> -->
          </div>
          <el-menu class=" right-nav" mode="horizontal" @select="handleSelect">
            <el-submenu index="1">
              <template slot="title">主营业务</template>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasea' }"><img
                        src="../assets/icon_menu1.png" />
                      电商及FBA海派</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasky' }"><img
                        src="../assets/icon_menu7.png" />
                      电商及FBA空派</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'interexp' }"><img
                        src="../assets/icon_menu6.png" />
                      国际快递</router-link></el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'importlogis' }"><img
                        src="../assets/icon_menu4.png" />
                      进口物流</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item style="widows: 50px;"><router-link :to="{ name: 'portsea' }">
                      <img src="../assets/icon_menu3.png" /> 港到港海运服务</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'door' }"><img
                        src="../assets/icon_menu2.png" />
                      门到门整柜运输</router-link>
                  </el-menu-item></el-col>

              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'seawarehouse' }"><img
                        src="../assets/icon_menu4.png" />
                      美国自营海外仓</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'delivery' }"><img
                        src="../assets/icon_menu8.png" />
                      美国清关+派送</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'intertrain' }"><img
                        src="../assets/icon_menu6.png" />
                      国际班列</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'collectlogis' }"><img
                        src="../assets/icon_menu8.png" />
                      集运物流</router-link></el-menu-item></el-col>
              </el-row>
            </el-submenu>
            <el-menu-item index="2"><router-link :to="{ name: 'about' }">关于文舟</router-link></el-menu-item>
            <el-menu-item index="3"><router-link :to="{ name: 'case' }">合作案例</router-link></el-menu-item>
            <el-menu-item index="4"><router-link :to="{ name: 'concat' }">联系我们</router-link></el-menu-item>
          </el-menu>

        </div>
      </div>
    </el-carousel>
    <!-- 服务优势 -->
    <div class="white-container">
      <div class="w-title">服务优势</div>
      <div class="data-module">
        <div class="data-list" v-for="(item, index) in serveList" :key="index">
          <img :src="item.img" alt="" />
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <!-- 服务项目 -->
    <div class="serve-project">
      <el-carousel class="banner-box" height="100%" arrow="always" indicator-position="none">
        <el-carousel-item v-for="(item, index) in severProjectList" :key="index">
          <div class="banner" :style="{ backgroundImage: `url(${item.img})` }">
            <div class="content-text">
              <div>服务项目</div>
              <div class="title">{{ item.title }}</div>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 数据展示 -->
    <div class="white-container">
      <div class="w-title">数据展示</div>
      <div class="data-module">
        <div class="data-show-list" v-for="(item, index) in dataShowList" :key="index">
          <img :src="item.img" alt="" />
          <div class="number">
            {{ item.num }}<span>{{ item.unit }}</span>
          </div>
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <!-- 客户评价 -->
    <div class="evaluate-container">
      <div class="w-title">跨境物流服务
        <div class="title-desc">为您提供稳定、高效的一站式物流解决方案，推动跨境电商全球化发展</div>
      </div>
      <!-- <div class="center" style="width: 70%;"> -->
      <el-row :guter="10" type="flex" justify="center">
        <el-col :span="3">
          <el-card :body-style="{ padding: '0px', }">
            <img src="../assets/icon_menu1.png" class="image">
            <div class="evaluate-card-text">
              <span>电商及FBA海派</span>

            </div>
          </el-card>
        </el-col>
        <el-col :span="3">
          <el-card :body-style="{ padding: '0px' }">
            <img src="../assets/icon_menu2.png" class="image">
            <div class="evaluate-card-text">
              <span>门到门整柜海运</span>

            </div>
          </el-card>
        </el-col>
        <el-col :span="3">
          <el-card :body-style="{ padding: '0px' }">
            <img src="../assets/icon_menu3.png" class="image">
            <div class="evaluate-card-text">
              <span>港到港海运服务</span>

            </div>
          </el-card>
        </el-col>
        <el-col :span="3">
          <el-card :body-style="{ padding: '0px' }">
            <img src="../assets/icon_menu4.png" class="image">
            <div class="evaluate-card-text">
              <span>美国自营海外仓</span>

            </div>
          </el-card>
        </el-col>
        <el-col :span="3">
          <el-card :body-style="{ padding: '0px' }">
            <img src="../assets/icon_menu5.png" class="image">
            <div class="evaluate-card-text">
              <span>美国自营车队</span>

            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row :guter="10" type="flex" justify="center">
        <el-col :span="3">
          <el-card :body-style="{ padding: '0px' }">
            <img src="../assets/icon_menu6.png" class="image">
            <div class="evaluate-card-text">
              <span>国际班列</span>

            </div>
          </el-card>
        </el-col>
        <el-col :span="3">
          <el-card :body-style="{ padding: '0px' }">
            <img src="../assets/icon_menu7.png" class="image">
            <div class="evaluate-card-text">
              <span>电商及FBA空派</span>

            </div>
          </el-card>
        </el-col>
        <el-col :span="3">
          <el-card :body-style="{ padding: '0px' }">
            <img src="../assets/icon_menu8.png" class="image">
            <div class="evaluate-card-text">
              <span>中越冷链物流</span>

            </div>
          </el-card>
        </el-col>
        <el-col :span="3">
          <el-card :body-style="{ padding: '0px' }">
            <img src="../assets/icon_menu9.png" class="image">
            <div class="evaluate-card-text">
              <span>美国退运解决方案</span>

            </div>
          </el-card>
        </el-col>
        <el-col :span="3">
          <el-card :body-style="{ padding: '0px' }">
            <img src="../assets/icon_menu10.png" class="image">
            <div class="evaluate-card-text">
              <span>空运美线运力</span>

            </div>
          </el-card>
        </el-col>
      </el-row>

      <!-- </div> -->
    </div>
    <!-- 联系我们 -->
    <div class="contact-container">
      <div class="left-info">
        <div class="left-text-box">
          <div class="concat-title">根据您的需求，我们随时提供帮助</div>
          <div class="words">
            我们将在工作日8:30-17:30的24小时内回复您或致电我们
          </div>
        </div>

      </div>
      <div class="right-img">
        <div class="right-form">
          <el-input class="i-list" v-model="messageInfo.name" placeholder="姓名"></el-input>
          <el-input class="i-list" v-model="messageInfo.phone" placeholder="手机号"></el-input>
          <el-input class="i-list" v-model="messageInfo.mail" placeholder="邮箱"></el-input>
          <el-button @click="submitInfo" class="submit-btn" :loading="submitLoading" type="warning">提交</el-button>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="white-container">
      <div class="w-title">合作伙伴</div>
      <div class="center w-center">
        <i :class="[
        isMoveLeftBtn
          ? 'iconfont icon-jiantou1 disable-btn'
          : 'iconfont icon-jiantou1',
      ]" @click="leftMove"></i>
        <div class="wrapper" id="scroll-wrapper">
          <div class="cooperation-content">
            <div class="c-list" v-for="(item, index) in cooperationList" :key="index">
              <img :src="item.img" alt="" />
            </div>
          </div>
        </div>
        <i :class="[
        isMoveRightBtn
          ? 'iconfont icon-jiantou disable-btn'
          : 'iconfont icon-jiantou',
      ]" @click="rightMove"></i>
      </div>
    </div>
    <div class="bottom-link">
      <el-row>
        <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
          <div class="title">
            主营业务
          </div>
          <div class="sub-title"><router-link :to="{ name: 'fbasea' }">电商及FBA海派</router-link></div>
          <div class="sub-title"><router-link :to="{ name: 'fbasky' }">电商及FBA空派</router-link></div>
          <div class="sub-title"><router-link :to="{ name: 'interexp' }">国际快递</router-link></div>
          <div class="sub-title"><router-link :to="{ name: 'importlogis' }">进口物流</router-link></div>
          <div class="sub-title"><router-link :to="{ name: 'portsea' }">
              港到港海运服务</router-link></div>
          <div class="sub-title"><router-link :to="{ name: 'door' }"> 门到门整柜运输</router-link></div>
          <div class="sub-title"><router-link :to="{ name: 'seawarehouse' }"> 美国自营海外仓</router-link></div>
          <div class="sub-title"><router-link :to="{ name: 'delivery' }">美国清关+派送</router-link></div>
          <div class="sub-title"><router-link :to="{ name: 'intertrain' }"> 国际班列</router-link></div>
          <div class="sub-title"><router-link :to="{ name: 'collectlogis' }">集运物流</router-link></div>
        </el-col>
        <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
          <div class="title">
            关于文舟
          </div>
          <div class="sub-title"><router-link :to="{ name: 'about' }">关于文舟</router-link></div>
          <div class="sub-title"><router-link :to="{ name: 'case' }">合作案例</router-link></div>
          <div class="sub-title"><router-link :to="{ name: 'concat' }">联系我们</router-link></div>
        </el-col>
      </el-row>
    </div>
    <!-- 底部 -->
    <div class="bottom-container">
      <div class="wrapper">
        Copyright @ 2008-至今 上海文舟供应链管理有限公司 All rights reserved
        沪ICP备20000382号-3
      </div>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/logo.png';

import icon1 from '@/assets/icon1.png';
import icon2 from '@/assets/icon2.png';
import icon3 from '@/assets/icon3.png';
import icon4 from '@/assets/icon4.png';
import shuzi1 from '@/assets/shuzi1.png';
import shuzi2 from '@/assets/shuzi2.png';
import shuzi3 from '@/assets/shuzi3.png';
import shuzi4 from '@/assets/shuzi4.png';
import shuzi5 from '@/assets/shuzi5.png';
import banner1 from '@/assets/banner1.jpg';
import banner2 from '@/assets/banner2.jpg';
import banner3 from '@/assets/banner3.jpg';
import fwxm1 from '@/assets/fwxm1.jpg';
import fwxm2 from '@/assets/fwxm2.jpg';
import fwxm3 from '@/assets/fwxm3.jpg';
import pingjia from '@/assets/pingjia.jpg';
import lxwm from '@/assets/lxwm.jpg';

import c1 from '@/assets/c1.png';
import c2 from '@/assets/c2.png';
import c3 from '@/assets/c3.png';
import c4 from '@/assets/c4.png';
import c5 from '@/assets/c5.png';
import c6 from '@/assets/c6.png';
import c7 from '@/assets/c7.png';
import c8 from '@/assets/c8.png';
import c9 from '@/assets/c9.png';
import c10 from '@/assets/c10.png';
import c11 from '@/assets/c11.png';
import c12 from '@/assets/c12.png';
import c13 from '@/assets/c13.png';
import c14 from '@/assets/c14.png';
import c15 from '@/assets/c15.png';

import { submitMessage } from '@/request/api';


export default {
  name: 'HomeView',
  data() {
    return {
      logo,
      lxwm,
      bannerList: [
        {
          img: banner1,
          title: '专注北美 链通全球',
          text: '为您提供专业的海运整柜/拼箱、关务、国际多式联运等门到门物流服务',
        },
        {
          img: banner2,
          title: '一体化 高稳定 快时效',
          text: '国内拖车+订舱/报关+清关/海运+空运+中欧班列/自营海外仓+自营车队',
        },
        {
          img: banner3,
          title: '整柜海运',
          text: '丰富的航线资源，稳定的内陆连接网络，资深的专家团队，确保货物按时送达',
        },
      ],
      severProjectList: [
        {
          img: fwxm1,
          title: '服装LDP',
          text: '2008年起文舟开始为服装外贸企业提供中美海运LDP门到门物流服务，并以此打开了传统外贸企业出口美国的国际海运物流市场，获得了业内的良好口碑，依托对于服装领域多年的专业了解与熟悉，以及美国本地所积累的丰富行业资源，可随时根据客户的出货计划提供全面、稳定、便捷的物流解决方案。',
        },
        {
          img: fwxm2,
          title: '行业物流',
          text: '文舟供应链专注于传统外贸行业，为传统外贸客户提供灵活、稳定的门到门一站式物流解决方案，服务涵盖国际海运订舱、关务、国际多式联运、空运、海外仓、中欧班列等物流服务领域，服务客户涉及服装、家具家居、汽配、户外用品、运动健身、新能源。',
        },
        {
          img: fwxm3,
          title: '新能源',
          text: '文舟供应链为中国新能源打开海外市场提供专业的物流运输服务，制定一体化物流解决方案，对物流全链路中的各个细微环节严密把控，做到流程标准化、操作规范化，在保障安全的前提下，为设备运营调试争取更多时间，确保高稳定、快时效。',
        },
      ],
      serveList: [
        {
          img: icon1,
          title: '安全可靠',
          text: '我们深知货物安全的重要性，因此我们的自营团队严格把控各个物流节点，坚持合规操作，自成立至今保持0扣货率',
        },
        {
          img: icon2,
          title: '快速响应',
          text: '规划优势航线，预先订舱，合理安排停靠港口，按需定制仓储及配送方案，实时反馈货物状态，提高运输效率',
        },
        {
          img: icon3,
          title: '机动灵活',
          text: '时刻关注供应链变化和需求，完善的基础配套功能以及专业的团队协作，在面对各种突发状况时随时调整，从容应对',
        },
        {
          img: icon4,
          title: '用心服务',
          text: '始终以客户利益最大化为服务准则，针对每一批货物的个性化需求，制定兼顾时效与价格的最佳解决方案',
        },
      ],
      evaluateList: [
        {
          img: pingjia,
          title: '户外用品',
        },
        {
          img: pingjia,
          title: '汽车配件',
        },
        {
          img: pingjia,
          title: '家居/家具',
        },
        {
          img: pingjia,
          title: '服装/纺织',
        },
      ],
      dataShowList: [
        {
          img: shuzi1,
          title: '全球直属分公司',
          num: 4,
          unit: '家',
        },
        {
          img: shuzi2,
          title: '2008年正式成立',
          num: 14,
          unit: '年',
        },
        {
          img: shuzi3,
          title: '商业共赢伙伴',
          num: 102,
          unit: '家',
        },
        {
          img: shuzi4,
          title: '累计服务客户',
          num: 1339,
          unit: '家',
        },
        {
          img: shuzi5,
          title: '平均每年海运订舱标箱',
          num: 35615,
          unit: '个',
        },
      ],
      isScrollLeftBtn: true,
      isScrollRightBtn: false,
      messageInfo: {},
      submitLoading: false,
      cooperationList: [
        { img: c1 },
        { img: c2 },
        { img: c3 },
        { img: c4 },
        { img: c5 },
        { img: c6 },
        { img: c7 },
        { img: c8 },
        { img: c9 },
        { img: c10 },
        { img: c11 },
        { img: c12 },
        { img: c13 },
        { img: c14 },
        { img: c15 },
      ],
      isMoveLeftBtn: true,
      isMoveRightBtn: false,
    };
  },
  methods: {
    // 提交留言
    submitInfo() {
      if (!this.messageInfo.name) {
        this.$message.warning('请输入姓名！');
      } else if (!this.messageInfo.phone) {
        this.$message.warning('请输入手机号！');
      } else if (!/^1[3-9]\d{9}$/.test(this.messageInfo.phone)) {
        this.$message.warning('请输入有效的手机号！');
      } else if (!this.messageInfo.mail) {
        this.$message.warning('请输入邮箱！');
      } else if (
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          this.messageInfo.mail
        )
      ) {
        this.$message.warning('请输入有效的邮箱！');
      } else {
        submitMessage(this.messageInfo).then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message.success('留言成功，客户经理会尽快与你联系！');
            this.messageInfo = {};
          }
        });
      }
    },
    // 页面跳转
    goPage(url) {
      this.$router.push({ path: url });
    },
    leftScroll() {
      let container = document.getElementById('scroll-container');
      if (!this.scrollLeft) {
        container.scrollLeft -= 420;
      }
      if (container.scrollLeft <= 420) {
        this.isScrollLeftBtn = true;
        this.isScrollRightBtn = false;
      } else {
        this.isScrollRightBtn = false;
      }
    },
    rightScroll() {
      let container = document.getElementById('scroll-container');
      let value = Math.round(container.scrollLeft / 420);
      let num = this.evaluateList.length - 4;
      if (!this.isScrollRightBtn) {
        container.scrollLeft += 420;
      }
      if (value < num) {
        this.isScrollRightBtn = false;
        this.isScrollLeftBtn = false;
      } else {
        this.isScrollRightBtn = true;
        this.isScrollLeftBtn = false;
      }
    },
    leftMove() {
      let container = document.getElementById('scroll-wrapper');
      if (!this.scrollLeft) {
        container.scrollLeft -= 254;
      }
      if (container.scrollLeft <= 254) {
        this.isMoveLeftBtn = true;
        this.isMoveRightBtn = false;
      } else {
        this.isMoveRightBtn = false;
      }
    },
    rightMove() {
      let container = document.getElementById('scroll-wrapper');
      let value = Math.round(container.scrollLeft / 254);
      let num = this.cooperationList.length - 6;
      if (!this.isMoveRightBtn) {
        container.scrollLeft += 254;
      }
      if (value < num) {
        this.isMoveRightBtn = false;
        this.isMoveLeftBtn = false;
      } else {
        this.isMoveRightBtn = true;
        this.isMoveLeftBtn = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  min-width: 1400px;
}

::v-deep .el-carousel__arrow i {
  font-size: 30px;
}

::v-deep .el-carousel__arrow {
  background-color: transparent;
}

::v-deep .el-carousel__arrow:hover {
  background-color: transparent;
}

.banner-box {
  width: 100%;
  height: 694px;
  position: relative;
  background-color: antiquewhite;

  .banner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background-position: center;
    background-size: cover;
  }

  .title {
    padding-bottom: 40px;
    font-size: 46px;
    color: #ffffff;
    font-weight: bolder;
    letter-spacing: 2px;
  }

  .text {
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 2px;
  }
}

.header-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;

  .el-menu {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: none;
  }

}

::v-deep .el-submenu__title:focus,
::v-deep .el-submenu__title:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}

::v-deep .el-menu-item:focus,
::v-deep .el-menu-item:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}

// ::v-deep .el-menu--horizontal ::v-deep .el-menu-item:not(.is-disabled):hover{
//   color: #3d6ac4 !important;
// }
::v-deep .el-menu--horizontal> ::v-deep .el-submenu ::v-deep .el-submenu__title {
  color: #909399 !important;
}

::v-deep .el-menu--horizontal ::v-deep .el-menu-item:not(.is-disabled):hover {
  color: #909399 !important;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
  color: #909399;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
  color: #909399 !important;
}

.header-box {
  max-width: 1300px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 70px;
  margin: 0 auto;
  font-size: 14px;

  img {
    // width: 150px;
    height: 70px;
    cursor: pointer;
  }

  span {
    display: inline-block;
    height: 70px;
    line-height: 70px;
    vertical-align: middle;
  }


}

a {
  text-decoration: none;
  font-weight: 400;
  color: #15213B;

}



.el-menu--horizontal {
  border-right: none;
  width: 30% !important;

  .el-row {
    margin: 0 !important;

    .el-col {
      width: 50%;
      padding: 10px;
      padding-right: 92px !important;

    }

    .el-col:hover {
      color: #000;
      background-color: #EEEEEE;
      border-radius: 5px;

      .el-menu-item {
        color: #000;
        background-color: #EEEEEE;
        border-radius: 5px;

      }
    }
  }
}




.right-nav {
  width: 50%;
  display: flex;

  .text {
    margin: 0 20px;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      color: #82feff;
    }
  }
}

.white-container {
  padding: 60px 0 90px;
  background-color: #ffffff;
}

.w-title {
  font-size: 45px;
  line-height: 75px;
  text-align: center;
  font-weight: bold;
  padding-bottom: 60px;
}

.title-desc {
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  padding-bottom: 10px;
}

.data-module {
  display: flex;
  max-width: 1300px;
  margin: 0 auto;
}

.data-list {
  padding: 0 20px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;

  img {
    width: 120px;
    height: 120px;
  }

  .title {
    font-size: 24px;
    line-height: 55px;
    color: #000000;
  }

  .text {
    color: #555555;
  }
}

.serve-project {
  width: 100%;
  height: 500px;
  background-color: #f5e2ca;

  .banner-box {
    height: 100%;
  }

  .content-text {
    width: 50%;
    margin: 0 auto;
    text-align: left;
    color: #ffffff;
  }

  .title {
    padding-bottom: 30px;
    padding-top: 10px;
    font-size: 26px;
  }

  .text {
    line-height: 40px;
  }
}

.data-show-list {
  padding: 0 20px;
  width: 20%;
  text-align: center;

  img {
    width: 80px;
    height: 80px;
    margin-bottom: 14px;
  }

  .number {
    font-size: 45px;
    color: #ffa12c;
    font-weight: bolder;
    margin-bottom: 14px;
  }

  span {
    font-size: 16px;
  }
}

.evaluate-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // height: 590px;
  padding: 60px 0 10px;

  // background-color: #2a2929;
  // background-color: #f5e2ca;
  .w-title {
    font-size: 45px;
    line-height: 75px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 60px;
    color: #2a2929;
  }

  .evaluate-card-text {
    padding: 14px;
    font-size: 17px;
    text-align: center;
  }

  .el-row {
    text-align: center;
    margin-bottom: 60px;

    .el-card.is-always-shadow,
    .el-card.is-hover-shadow:focus,
    .el-card.is-hover-shadow:hover,
    .el-cascader__dropdown,
    .el-color-picker__panel,
    .el-message-box,
    .el-notification {
      -webkit-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);
      box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);
    }

    .el-card {
      border: 0px solid #EBEEF5;
    }
  }
}

.wrapper {
  overflow-x: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
}

.evaluate-content {
  display: flex;

  .e-list {
    flex-shrink: 0;
    width: 380px;
    // height: 240px;
    padding: 10px;
    margin: 10px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 10px;
  }

  img {
    width: 100%;
    // height: 204px;
    margin-bottom: 10px;
  }
}

.center {
  display: flex;
  align-items: center;
  max-width: 1340px;
  margin: 0 auto;

  .iconfont {
    font-size: 40px;
    color: #ffffff;
    cursor: pointer;
  }

  .disable-btn {
    color: #eeeeee;
    cursor: not-allowed;
  }
}

.contact-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  background-color: #1d9f96;
}

.left-info {
  display: flex;
  justify-content: end;
  width: 50%;
  padding: 100px 0;

  .left-text-box {
    width: 50%;
  }

  .concat-title {
    line-height: normal;
    padding-bottom: 16px;
    font-size: 30px;
    line-height: 65px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 60px;
    color: #2a2929;
  }

  .words {
    text-align: center;
    line-height: 26px;
    padding-bottom: 30px;
  }


  .el-button--warning {
    background-color: #ffa12c;

    &:hover {
      background-color: #e0a800;
    }
  }
}

.right-img {
  width: 50%;
  height: auto;
  text-align: start;

  .right-form {
    width: 40%;
    text-align: left;
  }

  .el-input {
    margin-bottom: 16px;
  }

  .submit-btn {
    width: 100%;
  }
}

.cooperation-content {
  display: flex;

  .c-list {
    width: 254px;
    height: 120px;
    flex-shrink: 0;
    text-align: center;
  }

  img {
    // width: 100%;
    height: 100%;
  }

  .c-wrapper {
    overflow-x: scroll;
    scroll-behavior: smooth;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }
}

.w-center {
  .iconfont {
    color: #ffa12c;
  }

  .disable-btn {
    color: #f7dd91;
  }
}

.bottom-container {
  padding: 60px 0;
  color: #ffffff;
  background-color: #1e1e1e;

  .wrapper {
    width: 50%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .link {
    padding-right: 20px;
    cursor: pointer;
  }
}

.last {
  padding: 6px 0;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .home {
    min-width: 100vw;
  }

  .data-module {
    // display: block;
    flex-wrap: wrap;

    .data-list {
      width: 36%;
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;

      img {
        width: 80px;
        height: 80px;
      }

      .title {
        font-size: 22px;
      }

      .text {
        display: none;
      }
    }
  }

  .white-container {
    padding: 20px 0;

    .center {
      .iconfont {
        display: none;
      }
    }

    .wrapper {
      // width: 90%;
      overflow-x: scroll;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        display: block;
      }
    }
  }

  .evaluate-container {
    padding: 20px 0;

    .w-title {
      font-size: 30px;
      padding-bottom: 0;
    }

    .center {
      .iconfont {
        display: none;
      }
    }

    .wrapper {
      // width: 90%;
      overflow-x: scroll;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        display: block;
      }
    }

    .e-list {
      width: 80%;
      padding: 20px;
    }
  }

  .w-title {
    font-size: 30px;
    padding-bottom: 0;
  }

  .banner-box {
    width: 100%;
    height: 300px;

    .title {
      font-size: 30px;
    }

    .text {
      padding: 0 30px;
    }
  }

  .data-show-list {
    width: 50%;
    padding: 0 0 20px;

    img {
      width: 50px;
      height: 50px;
    }

    .number {
      font-size: 30px;

      span {
        font-size: 14px;
      }
    }
  }

  .contact-container {
    display: block;
    width: 80%;
  }

  .left-info {
    width: 100%;
    margin: 0 auto;
  }

  .right-img {
    width: 100%;
  }


  .bottom-link {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 50px 0 30px;
    color: #FFF;
    background-color: #15213B;

    .el-row {
      display: flex;
      justify-content: center;
      width: 100%;
      text-align: center;

      .el-col {
        text-align: center;

        /* margin-right: 100px; */
        .title {
          font-size: 16px;
          font-weight: bolder;
          text-align: left;
          margin-bottom: 10px;
        }

        .sub-title {
          color: #95A5C9;
          border-bottom: 1px solid #1C2B4E;
          line-height: 35px;
          text-align: left;
          font-size: 14px;
        }
      }

    }
  }

 

  .serve-project {
    height: 300px;

    .content-text {
      width: 70%;
    }

    .title {
      padding: 0 0 20px;
    }

    .text {
      // font-size: 12px;
      padding: 0;
      line-height: 20px;
    }
  }
}
.bottom-container {
    padding: 30px 0;
    color: #ffffff;
    background-color: #15213B !important;
    border-top: 1px solid #41537A;

    .wrapper {
      width: 50%;
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
    .link {
        padding-right: 20px;
        cursor: pointer;
    }
  }
</style>
