<template>
    <div  style="background-color: #eef1fa;">
        <div class="banner">
            <el-carousel class="door-banner-box" height="100%" arrow="never" indicator-position="none" loop="false">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <div class="banner" :style="{ backgroundImage: `url(${item.img})` }">
                        <div class="title">{{ item.title }}</div>
                        <div class="text">{{ item.text }}</div>
                        <div class="link-btn"><router-link :to="{ name: 'concat' }">获取报价</router-link></div>
                    </div>
                </el-carousel-item>
                <div class="header-container">
                    <div class="header-box">
                        <div><span><img :src="logo" alt="" @click="goPage('/')" /></span>
                            <!-- <span>文舟供应链管理有限公司</span> -->
                        </div>
                        <el-menu class=" right-nav" mode="horizontal" @select="handleSelect">
                            <el-submenu index="1">
              <template slot="title">主营业务</template>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasea' }"><img
                        src="../assets/icon_menu1.png" />
                      电商及FBA海派</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasky' }"><img
                        src="../assets/icon_menu7.png" />
                      电商及FBA空派</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'interexp' }"><img
                        src="../assets/icon_menu6.png" />
                      国际快递</router-link></el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'importlogis' }"><img
                        src="../assets/icon_menu4.png" />
                      进口物流</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item style="widows: 50px;"><router-link :to="{ name: 'portsea' }">
                      <img src="../assets/icon_menu3.png" /> 港到港海运服务</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'door' }"><img
                        src="../assets/icon_menu2.png" />
                      门到门整柜运输</router-link>
                  </el-menu-item></el-col>

              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'seawarehouse' }"><img
                        src="../assets/icon_menu4.png" />
                      美国自营海外仓</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'delivery' }"><img
                        src="../assets/icon_menu8.png" />
                      美国清关+派送</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'intertrain' }"><img
                        src="../assets/icon_menu6.png" />
                      国际班列</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'collectlogis' }"><img
                        src="../assets/icon_menu8.png" />
                      集运物流</router-link></el-menu-item></el-col>
              </el-row>
            </el-submenu>
                            <el-menu-item index="2"><router-link :to="{ name: 'about' }">关于文舟</router-link></el-menu-item>
                            <el-menu-item index="3"><router-link :to="{ name: 'case' }">合作案例</router-link></el-menu-item>
                            <el-menu-item index="4"><router-link
                                    :to="{ name: 'concat' }">联系我们</router-link></el-menu-item>
                        </el-menu>

                    </div>
                </div>
            </el-carousel>
        </div>
        <div class="door-three-box">
            <el-row :gutter="0">
                <el-col :span="6" v-for="(o, index) in doorPain1" :key="o" :offset="index > 0 ? 2 : 0">
                    <el-card :body-style="{ padding: '0px' }">
                        <img :src="o.img" class="">
                        <div class="text-box">
                            <h4 class="title">{{ o.title }}</h4>
                            <div class="title-desc">
                                {{ o.desc }}
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>

        </div>
        <div class="door-three-box dtb2">
            <el-row>
                <el-col :span="6" v-for="(o, index) in doorPain2" :key="o" :offset="index > 0 ? 2 : 0">
                    <el-card :body-style="{ padding: '0px' }">
                        <img :src="o.img" class="">
                        <div class="text-box">
                            <h4 class="title">{{ o.title }}</h4>
                            <div class="title-desc">
                                {{ o.desc }}
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>

        </div>

        <div class="door-contact-container">
            <div class="left-info">
                <div class="left-text-box">
                    <p>门到门海运整柜</p>
                    <p class="p2">辐射北美各大内陆IPI点</p>
                    <p class="p3">加西、美西、美东直达航线，通过铁路或拖车集装箱运输辐射加拿大及美国各大内陆IPI点熟悉多式联运各个环节可能产生的异常费用，合理安排时间节点及运输路径。</p>
                </div>
            </div>
        </div>

        <div class="logisticsImg">
            <div class="logisticsImg-list">
                <ul>
                    <li>
                        <div class="logisticsImg-item"><img src="../assets/zz1.png">
                            <p>中美无船承运人资质</p>
                        </div>
                    </li>
                    <li>
                        <div class="logisticsImg-item"><img src="../assets/zz2.png">
                            <p>中美无船承运人资质</p>
                        </div>
                    </li>
                    <li>
                        <div class="logisticsImg-item"><img src="../assets/zz3.png">
                            <p>中美无船承运人资质</p>
                        </div>
                    </li>
                    <li>
                        <div class="logisticsImg-item"><img src="../assets/zz4.png">
                            <p>中美无船承运人资质</p>
                        </div>
                    </li>
                    <li>
                        <div class="logisticsImg-item"><img src="../assets/zz5.png">
                            <p>中美无船承运人资质</p>
                        </div>
                    </li>
                    <li>
                        <div class="logisticsImg-item"><img src="../assets/zz6.png">
                            <p>中美无船承运人资质</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="bottom-link">
            <el-row>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        主营业务
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'fbasea' }">电商及FBA海派</router-link></div>
                    <div class="sub-title"><router-link
                      :to="{ name: 'fbasky' }">电商及FBA空派</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'interexp' }">国际快递</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'importlogis' }">进口物流</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'portsea' }">
                      港到港海运服务</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'door' }"> 门到门整柜运输</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'seawarehouse' }"> 美国自营海外仓</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'delivery' }">美国清关+派送</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'intertrain' }"> 国际班列</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'collectlogis' }">集运物流</router-link></div>
                </el-col>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        关于文舟
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'about' }">关于文舟</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'case' }">合作案例</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'concat' }">联系我们</router-link></div>
                </el-col>
            </el-row>
        </div>
        <!-- 底部 -->
        <div class="bottom-container">
            <div class="wrapper">
                Copyright @ 2008-至今 上海文舟供应链管理有限公司 All rights reserved
                沪ICP备20000382号-3
            </div>
        </div>
    </div>

</template>

<script>
import banner from '@/assets/zyyw-banner3.jpg';
import banner1 from '@/assets/banner1.jpg';
import banner2 from '@/assets/banner2.jpg';
import banner3 from '@/assets/banner3.jpg';
import haiyun1 from '@/assets/haiyun1.jpg';
import haiyun2 from '@/assets/haiyun2.jpg';
import haiyun3 from '@/assets/haiyun3.jpg';
import haiyun4 from '@/assets/haiyun4.jpg';
import haiyun5 from '@/assets/haiyun5.jpg';
import haiyun6 from '@/assets/haiyun6.jpg';


import c1 from '@/assets/c1.png';
import c2 from '@/assets/c2.png';
import c3 from '@/assets/c3.png';
import c4 from '@/assets/c4.png';
import c5 from '@/assets/c5.png';
import c6 from '@/assets/c6.png';
import c7 from '@/assets/c7.png';
import c8 from '@/assets/c8.png';
import c9 from '@/assets/c9.png';
import c10 from '@/assets/c10.png';
import c11 from '@/assets/c11.png';
import c12 from '@/assets/c12.png';
import c13 from '@/assets/c13.png';
import c14 from '@/assets/c14.png';
import c15 from '@/assets/c15.png';
export default {
    name: 'FbaSeaView',
    data() {
        return {
            bannerList: [
                {
                    img: banner,
                    title: '针对海运整柜时效与费用失控等痛点',
                    text: '基于“为客户有效缩减国际物流诸多不确定性”的产品设计理念创新的门到门整柜解决方案与配套能力建设，值得您的垂询！',
                }
            ],
            fbaList: [
                {
                    img: banner1,
                    title: '专注北美 链通全球',
                    text: '为您提供专业的海运整柜/拼箱、关务、国际多式联运等门到门物流服务',
                },
                {
                    img: banner2,
                    title: '一体化 高稳定 快时效',
                    text: '国内拖车+订舱/报关+清关/海运+空运+中欧班列/自营海外仓+自营车队',
                },
                {
                    img: banner3,
                    title: '非洲整柜海运',
                    text: '丰富的航线资源，稳定的非洲内陆连接网络，资深的专家团队，确保货物按时送达',
                },
            ],
            cooperationList: [
                { img: c1 },
                { img: c2 },
                { img: c3 },
                { img: c4 },
                { img: c5 },
                { img: c6 },
                { img: c7 },
                { img: c8 },
                { img: c9 },
                { img: c10 },
                { img: c11 },
                { img: c12 },
                { img: c13 },
                { img: c14 },
                { img: c15 },
            ],
            isMoveLeftBtn: true,
            isMoveRightBtn: false,
            doorPain1: [
                {
                    img: haiyun1,
                    title: '痛点一：海运时效，不稳定',
                    desc: '海运时效稳定、准时，是保障交期的前提。文舟供应链专注经营美线，对于各大船司、各航线的历年时效，保持长期关注及跟踪统计，以支持丰富经验的销售顾问给您最匹配的船期，有效保障交期。'
                },
                {
                    img: haiyun2,
                    title: '痛点二：免用箱时间，不够用',
                    desc: '美国仓储成本高昂，为最大化仓储周转效率，很多仓库会提前要求预约送仓时间，也时有发生集装箱超长时间等待卸柜的情况，尤其是汽配/家具/家居/建材等大件货物或者大型收货仓。文舟供应链超长免费用箱期合约与自备集装箱结合，支持美国多个主要港口锁定还箱成本，真正大幅度降低、甚至消除货主超期用箱费用。'
                },
                {
                    img: haiyun3,
                    title: '痛点三：舱位，时有时无',
                    desc: '基于深耕美线海运多年的优势，文舟供应链在船公司有着良好的履约记录，支持与优质客户签订长期舱位保障与固定运价合约，真正做到旺季运力舱位有保障！'
                },
            ]
            ,
            doorPain2: [
                {
                    img: haiyun4,
                    title: '痛点四：目的港码头杂费及拖车费，不透明',
                    desc: '由于分布在美国各主要港口附近都有我司自营海外仓，同时配备自营拖车团队，成本可控且相对固定。通过高效沟通管控码头杂费，结合自购车架管控拖车杂费等，能真正实现一口价、全透明的固定收费，让货主更加安心！'
                },
                {
                    img: haiyun5,
                    title: '痛点五：高关税、反倾销产品，束手无策',
                    desc: '中美贸易摩擦及相关政策的调整，时有发生，这是所有涉及美线业务的客户不可预知、也无法完全避免的。文舟供应链身处这一“旋涡”多年，深谙美国海关相关的运作机制，能对其“高关税与反倾销”等法律条文“有效”解读。常年配备清关方案与路径设计的资深清关事务服务团队，一客一案，期待与您深入的沟通，量身定制您的独家方案（仅海运整柜）。'
                },
                {
                    img: haiyun6,
                    title: '痛点六：物流动态，进程未知',
                    desc: '文舟供应链自研系统，实时锁定物流最新动态。同时国内操作、客服及美国客服团队在线跟踪货物进度，异常情况预警或及时通报，保持与国内外客户及自有清关团队、自营海外仓、自营车队等在内的高效沟通，真正意义上的一站式跟踪服务平台，大大提升客户物流交付体验及满意度。'
                },
            ]
        };
    },
    methods: {
        leftMove() {
            let container = document.getElementById('scroll-wrapper');
            if (!this.scrollLeft) {
                container.scrollLeft -= 254;
            }
            if (container.scrollLeft <= 254) {
                this.isMoveLeftBtn = true;
                this.isMoveRightBtn = false;
            } else {
                this.isMoveRightBtn = false;
            }
        },
        rightMove() {
            let container = document.getElementById('scroll-wrapper');
            let value = Math.round(container.scrollLeft / 254);
            let num = this.cooperationList.length - 6;
            if (!this.isMoveRightBtn) {
                container.scrollLeft += 254;
            }
            if (value < num) {
                this.isMoveRightBtn = false;
                this.isMoveLeftBtn = false;
            } else {
                this.isMoveRightBtn = true;
                this.isMoveLeftBtn = false;
            }
        },
    }
};
</script>
<style>
.body {
    color: #15213B;
    font-size: 14px;
    line-height: 28px;
    background-color: #eef1fa !important;
}

::v-deep .el-carousel__arrow i {
  font-size: 30px;
}
::v-deep .el-carousel__arrow {
  background-color: transparent;
}
::v-deep .el-carousel__arrow:hover {
  background-color: transparent;
}
.header-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  .el-menu .el-menu--horizontal {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: none;
  }
  .el-menu--horizontal {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: none;
  }

}

  ::v-deep .el-submenu__title:focus, ::v-deep .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  ::v-deep .el-menu-item:focus, ::v-deep .el-menu-item:hover{
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    outline: 0;
    color: #909399 !important;
}
.el-menu--horizontal>.el-submenu:hover .el-submenu__title {
    color: #909399 !important;
}
.el-menu-item:focus, .el-menu-item:hover {
    outline: 0;
    background-color: rgba(0, 0, 0, 0) !important;
}
.el-menu-item, .el-submenu__title {
    list-style: none;
}
.el-menu-item a {
    text-decoration:none;
}


  ::v-deep .el-menu--horizontal > ::v-deep .el-submenu ::v-deep .el-submenu__title{
    color: #909399 !important;
  }
  ::v-deep .el-menu--horizontal ::v-deep .el-menu-item:not(.is-disabled):hover {
    color: #909399 !important;
  }
  ::v-deep .el-menu--horizontal > ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399;
  }
  ::v-deep .el-menu--horizontal  > ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399 !important;
  }

.header-box {
  max-width: 1300px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 70px;
  margin: 0 auto;
  font-size: 14px;
  img {
    height: 70px;
    cursor: pointer;
  }
  span{
    display: inline-block;
    height: 70px;
    line-height: 70px;
    vertical-align: middle;
  }
}
.right-nav {
  width: 50%;
  display: flex;
  border-bottom: none !important;
  .text {
    margin: 0 20px;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      color: #82feff;
    }
  }
}
.door-banner-box {
    width: 100%;
    height: 456px;
    position: relative;

    .banner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        background-position: center;
        background-size: cover;
    }

    .title {
        padding-bottom: 40px;
        font-size: 46px;
        color: #ffffff;
        font-weight: bolder;
        padding-left: 25%;
        letter-spacing: 2px;
    }

    .text {
        font-size: 14px;
        color: #ffffff;
        padding-left: 25%;
        letter-spacing: 2px;
        width: 540px;
    }
    .link-btn {
        padding: 10px 35px;
        transition: all 1s;
        border-radius: 35px;
        background-color: #F5C12D;
        font-size: 16px;
        width: 4%;
        min-width: 65px;
        margin-left: 25%;
        margin-top: 20px;

    }

    .link-btn:hover {
        cursor: pointer;
        background-color: #1D9F95;
        transition: all 1s;
    }

    a {
        text-decoration: none;
        font-weight: 400;
        color: #15213B;

    }
}


.door-three-box {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #eef1fa;

    .el-row {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 50px 0;

        .el-col {
            padding: 20px 16px;
            margin-bottom: -10px;

            .el-card {


                .text-box {
                    padding: 20px;
                    height: 274px;

                    .title {
                        font-size: 24px;
                        color: #333333;
                        font-weight: bold;
                        /* line-height: 40px; */
                        margin-top: -8px;
                        margin-bottom: 10px;
                    }

                    .title-desc {
                        font-size: 16px;
                        color: #999999;
                        line-height: 30px;
                        min-height: 210px;
                    }

                    .image {
                        height: 40px !important;
                    }
                }


            }

            .el-card:hover {
                color: #1f9e93 !important;
                border-bottom: 5px solid #1f9e93;

                .title {
                    color: #1f9e93;
                }

                .title-desc {
                    color: #1f9e93;
                }
            }
        }

        .el-col-offset-2 {
            margin-left: 0%;
        }
    }



}

.dtb2 {
    margin-top: -50px;
}


.three-last-box {
    padding-bottom: 20px;
}

.door-contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    background-color: #1d9f96;

    .left-text-box {
        padding-bottom: 20px;
    }
}

.left-info {
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 50px 0;

    p {
        text-align: center;
        color: #15213B;
        line-height: 35px;
    }

    .p2 {
        font-size: 40px;
        font-weight: bold;
        margin: 25px auto;
    }

    .p3 {
        font-size: 21px;
        font-weight: bold;
        width: 60%;
        margin: 15px auto 0;
        line-height: 40px;
    }

    .concat-title {
        line-height: normal;
        padding-bottom: 16px;
        font-size: 30px;
        line-height: 65px;
        text-align: center;
        font-weight: bold;
        padding-bottom: 60px;
        color: #2a2929;
    }

    .words {
        text-align: center;
        line-height: 26px;
        padding-bottom: 30px;
    }


}

.centent-box {

    background-color: #eef1fa;
    width: 100%;
    height: 850px;
    padding-top: 50px;


    .centent-img-box {
        display: flex;
        justify-content: center;
        width: 65%;
        height: 400px;
        margin: auto;
        padding-bottom: 40px;

        .el-row {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;

            .el-col {
                margin-bottom: 30px;

                .left {
                    padding: 20px 80px;
                    text-align: left;

                    .title {
                        font-size: 23px;
                        font-weight: bolder;
                    }
                }

                img {
                    padding: 0px 10px 20px 90px;
                    width: 80%;
                    height: 360px;
                }
            }
        }



    }

    .centent-img-box-v2 {
        display: flex;
        justify-content: center;
        width: 65%;
        height: 400px;
        margin: auto;

        .el-row {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;

            .el-col {
                margin-bottom: 30px;

                .left {
                    padding: 20px 80px;
                    text-align: left;

                    .title {
                        font-size: 23px;
                        font-weight: bolder;
                    }
                }

                img {
                    padding: 0px 10px 20px 30px;
                    width: 80%;
                    height: 360px;
                }
            }
        }



    }

    .title {
        padding-bottom: 10px;
        font-size: 20px;
        color: #4b4747;
        font-weight: bolder;
    }

    .title-desc {
        letter-spacing: 2px;
        line-height: 28px !important;
    }
}

.white-container {
    padding: 60px 0 90px;
    background-color: #ffffff;
}

.w-title {
    font-size: 45px;
    line-height: 75px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 60px;
}

.center {
    display: flex;
    align-items: center;
    max-width: 1340px;
    margin: 0 auto;

    .iconfont {
        font-size: 40px;
        color: #ffffff;
        cursor: pointer;
    }

    .disable-btn {
        color: #eeeeee;
        cursor: not-allowed;
    }
}

.wrapper {
    overflow-x: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }
}

.cooperation-content {
    display: flex;

    .c-list {
        width: 254px;
        height: 120px;
        flex-shrink: 0;
        text-align: center;
    }

    img {
        /* // width: 100%; */
        height: 100%;
    }

    .c-wrapper {
        overflow-x: scroll;
        scroll-behavior: smooth;


    }
}

.w-center {
    .iconfont {
        color: #ffa12c;
    }

    .disable-btn {
        color: #f7dd91;
    }
}

.logisticsImg{
    background-color: #eef1fa;
  }
  .logisticsImg-box{
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    padding-top: 60px;
    padding-bottom: 30px;
  }
  .logisticsImg-list ul{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 30px;
}
.logisticsImg-list ul li{
  width: calc(100%/6);
  text-align: center;
  padding-bottom: 20px;
}
.logisticsImg-list ul li img{
 width: auto;
 height: 41px;
}
.logisticsImg-list ul li p{
  font-size: 14px;
    color: #747474;
    line-height: 30px;
    margin-top: 10px;
}


.bottom-link {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 50px 0 30px;
    color: #FFF;
    background-color: #15213B;

    .el-row {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;

        .el-col {
            text-align: center;

            /* margin-right: 100px; */
            .title {
                font-size: 16px;
                font-weight: bolder;
                text-align: left;
                margin-bottom: 10px;
            }

            .sub-title {
                color: #95A5C9;
                border-bottom: 1px solid #1C2B4E;
                line-height: 35px;
                text-align: left;
                font-size: 14px;
            }
        }

    }
}

.bottom-container {
    padding: 30px 0;
    color: #ffffff;
    background-color: #15213B;
    border-top: 1px solid #41537A;

    .wrapper {
        width: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .link {
        padding-right: 20px;
        cursor: pointer;
    }
}
</style>