<template>
    <div>
        <div class="banner">
            <el-carousel class="banner-box" height="100%" arrow="never" indicator-position="none" loop="false">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <div class="banner" :style="{ backgroundImage: `url(${item.img})` }">
                        <div class="title">{{ item.title }}</div>
                        <div class="text">{{ item.text }}</div>
                    </div>
                </el-carousel-item>
                <div class="header-container">
                    <div class="header-box">
                        <div><span><img :src="logo" alt="" @click="goPage('/')" /></span>
                            <!-- <span>文舟供应链管理有限公司</span> -->
                        </div>
                        <el-menu class=" right-nav" mode="horizontal" @select="handleSelect">
                            <el-submenu index="1">
                                <template slot="title">主营业务</template>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasea' }">
                                                电商及FBA海派</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link
                                                :to="{ name: 'fbasky' }">电商及FBA空派</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'door' }">
                                                门到门整柜海运</router-link> </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link
                                                :to="{ name: 'skyline' }">空运美线运力</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item style="widows: 50px;"><router-link
                                                :to="{ name: 'portsea' }"> 港到港海运拼箱</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link
                                                :to="{ name: 'usaback' }">美国退运解决方案</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'seawarehouse' }">
                                                美国自营海外仓</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link
                                                :to="{ name: 'usacar' }">美国自营车队</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'intertrain' }">
                                                国际班列</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link
                                                :to="{ name: 'coldchain' }">中越冷链物流</router-link></el-menu-item></el-col>
                                </el-row>
                            </el-submenu>
                            <el-menu-item index="2"><router-link :to="{ name: 'about' }">关于文舟</router-link></el-menu-item>
                            <el-menu-item index="3"><router-link :to="{ name: 'case' }">合作案例</router-link></el-menu-item>
                            <el-menu-item index="4"><router-link
                                    :to="{ name: 'concat' }">联系我们</router-link></el-menu-item>
                        </el-menu>

                    </div>
                </div>
            </el-carousel>
        </div>

        <div class="cold-centent-box">
            <div class="centent-img-box">
                <el-row>
                    <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="title-box">
                            <div class="title-num">01</div>
                            <div class="title-num-text">定制高标</div>
                        </div>
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title">全程保价</div>
                            <div class="title-desc">针对跨境物流周期长、节点多，容易产生丢包破损等问题提供全程保价服务
                                面向全品类、高货值产品，保价费率为3.5%，保价货值可达USD1500/箱
                                如在运输途中发生丢包、破损，将在7个工作日内按丢损货值完成全额赔付</div>
                            <div>
                                <p>适用产品</p>
                                <p>一票一件、一票多件、海外仓暂存转运、海外仓一件代发</p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
                            class="image">

                    </el-col>
                </el-row>

            </div>
            <div class="centent-img-box-v2">
                <el-row>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
                            class="image">
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title">全程保价</div>
                            <div class="title-desc">针对跨境物流周期长、节点多，容易产生丢包破损等问题提供全程保价服务
                                面向全品类、高货值产品，保价费率为3.5%，保价货值可达USD1500/箱
                                如在运输途中发生丢包、破损，将在7个工作日内按丢损货值完成全额赔付</div>
                            <div>
                                <p>适用产品</p>
                                <p>一票一件、一票多件、海外仓暂存转运、海外仓一件代发</p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="title-box">
                            <div class="title-num">02</div>
                            <div class="title-num-text">定制高标</div>
                        </div>
                    </el-col>
                </el-row>

            </div>
            <div class="centent-img-box">
                <el-row>
                    <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="title-box">
                            <div class="title-num">03</div>
                            <div class="title-num-text">定制高标</div>
                        </div>
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title">全程保价</div>
                            <div class="title-desc">针对跨境物流周期长、节点多，容易产生丢包破损等问题提供全程保价服务
                                面向全品类、高货值产品，保价费率为3.5%，保价货值可达USD1500/箱
                                如在运输途中发生丢包、破损，将在7个工作日内按丢损货值完成全额赔付</div>
                            <div>
                                <p>适用产品</p>
                                <p>一票一件、一票多件、海外仓暂存转运、海外仓一件代发</p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
                            class="image">

                    </el-col>
                </el-row>

            </div>
            <div class="centent-img-box-v2">
                <el-row>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
                            class="image">
                    </el-col>
                    <el-col :span="12" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="left">
                            <div class="title">全程保价</div>
                            <div class="title-desc">针对跨境物流周期长、节点多，容易产生丢包破损等问题提供全程保价服务
                                面向全品类、高货值产品，保价费率为3.5%，保价货值可达USD1500/箱
                                如在运输途中发生丢包、破损，将在7个工作日内按丢损货值完成全额赔付</div>
                            <div>
                                <p>适用产品</p>
                                <p>一票一件、一票多件、海外仓暂存转运、海外仓一件代发</p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                        <div class="title-box">
                            <div class="title-num">04</div>
                            <div class="title-num-text">定制高标</div>
                        </div>
                    </el-col>
                </el-row>

            </div>
        </div>
        <div class="contact-container">
            <div class="left-info">
                <div class="left-text-box">
                    <p>增值服务</p>
                    <p class="p2">优化配套服务，提升用户体验</p>
                    <p class="p3">在提供跨境物流服务时，不局限于报关、订舱等传统服务，而是能够从不同类型客户的实际需求出发，在方案优化设计、综合物流服务等方面完善服务品类，提升服务品质。</p>
                </div>
            </div>
        </div>
        <!-- 合作伙伴 -->
        <div class="white-container">
            <div class="w-title">合作伙伴</div>
            <div class="center w-center">
                <i :class="[
                    isMoveLeftBtn
                        ? 'iconfont icon-jiantou1 disable-btn'
                        : 'iconfont icon-jiantou1',
                ]" @click="leftMove"></i>
                <div class="wrapper" id="scroll-wrapper">
                    <div class="cooperation-content">
                        <div class="c-list" v-for="(item, index) in cooperationList" :key="index">
                            <img :src="item.img" alt="" />
                        </div>
                    </div>
                </div>
                <i :class="[
                    isMoveRightBtn
                        ? 'iconfont icon-jiantou disable-btn'
                        : 'iconfont icon-jiantou',
                ]" @click="rightMove"></i>
            </div>
        </div>
        <div class="bottom-link">
            <el-row>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        主营业务
                    </div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                </el-col>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        关于我们
                    </div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                </el-col>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        主营业务
                    </div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                    <div class="sub-title">电商及FBA海派</div>
                </el-col>
            </el-row>
        </div>
        <!-- 底部 -->
        <div class="bottom-container">
            <div class="wrapper">
                Copyright @ 2008-至今 上海文舟供应链管理有限公司 All rights reserved
                沪ICP备20000382号-3
            </div>
        </div>
    </div>
</template>

<script>
import banner1 from '@/assets/banner1.jpg';
import banner2 from '@/assets/banner2.jpg';
import banner3 from '@/assets/banner3.jpg';


import c1 from '@/assets/c1.png';
import c2 from '@/assets/c2.png';
import c3 from '@/assets/c3.png';
import c4 from '@/assets/c4.png';
import c5 from '@/assets/c5.png';
import c6 from '@/assets/c6.png';
import c7 from '@/assets/c7.png';
import c8 from '@/assets/c8.png';
import c9 from '@/assets/c9.png';
import c10 from '@/assets/c10.png';
import c11 from '@/assets/c11.png';
import c12 from '@/assets/c12.png';
import c13 from '@/assets/c13.png';
import c14 from '@/assets/c14.png';
import c15 from '@/assets/c15.png';

export default {
    name: 'FbaSeaView',
    data() {
        return {
            bannerList: [
                {
                    img: banner1,
                    title: '冷链 ',
                    text: '为您提供专业的海运整柜/拼箱、关务、国际多式联运等门到门物流服务',
                }
            ],
            fbaList: [
                {
                    img: banner1,
                    title: '专注北美 链通全球',
                    text: '为您提供专业的海运整柜/拼箱、关务、国际多式联运等门到门物流服务',
                },
                {
                    img: banner2,
                    title: '一体化 高稳定 快时效',
                    text: '国内拖车+订舱/报关+清关/海运+空运+中欧班列/自营海外仓+自营车队',
                },
                {
                    img: banner3,
                    title: '非洲整柜海运',
                    text: '丰富的航线资源，稳定的非洲内陆连接网络，资深的专家团队，确保货物按时送达',
                },
            ],
            cooperationList: [
                { img: c1 },
                { img: c2 },
                { img: c3 },
                { img: c4 },
                { img: c5 },
                { img: c6 },
                { img: c7 },
                { img: c8 },
                { img: c9 },
                { img: c10 },
                { img: c11 },
                { img: c12 },
                { img: c13 },
                { img: c14 },
                { img: c15 },
            ],
            isMoveLeftBtn: true,
            isMoveRightBtn: false,
        };
    },
    methods: {
        leftMove() {
            let container = document.getElementById('scroll-wrapper');
            if (!this.scrollLeft) {
                container.scrollLeft -= 254;
            }
            if (container.scrollLeft <= 254) {
                this.isMoveLeftBtn = true;
                this.isMoveRightBtn = false;
            } else {
                this.isMoveRightBtn = false;
            }
        },
        rightMove() {
            let container = document.getElementById('scroll-wrapper');
            let value = Math.round(container.scrollLeft / 254);
            let num = this.cooperationList.length - 6;
            if (!this.isMoveRightBtn) {
                container.scrollLeft += 254;
            }
            if (value < num) {
                this.isMoveRightBtn = false;
                this.isMoveLeftBtn = false;
            } else {
                this.isMoveRightBtn = true;
                this.isMoveLeftBtn = false;
            }
        },
    }
};
</script>
<style>
.body {
    color: #15213B;
    font-size: 14px;
    line-height: 28px ;
    background-color: #eef1fa !important;
}

::v-deep .el-carousel__arrow i {
  font-size: 30px;
}
::v-deep .el-carousel__arrow {
  background-color: transparent;
}
::v-deep .el-carousel__arrow:hover {
  background-color: transparent;
}
.header-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  .el-menu .el-menu--horizontal {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: none;
  }
  .el-menu--horizontal {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: none;
  }

}

  ::v-deep .el-submenu__title:focus, ::v-deep .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  ::v-deep .el-menu-item:focus, ::v-deep .el-menu-item:hover{
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    outline: 0;
    color: #909399 !important;
}
.el-menu--horizontal>.el-submenu:hover .el-submenu__title {
    color: #909399 !important;
}
.el-menu-item:focus, .el-menu-item:hover {
    outline: 0;
    background-color: rgba(0, 0, 0, 0) !important;
}
.el-menu-item, .el-submenu__title {
    list-style: none;
}
.el-menu-item a {
    text-decoration:none;
}


  ::v-deep .el-menu--horizontal > ::v-deep .el-submenu ::v-deep .el-submenu__title{
    color: #909399 !important;
  }
  ::v-deep .el-menu--horizontal ::v-deep .el-menu-item:not(.is-disabled):hover {
    color: #909399 !important;
  }
  ::v-deep .el-menu--horizontal > ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399;
  }
  ::v-deep .el-menu--horizontal  > ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399 !important;
  }

.header-box {
  max-width: 1300px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 70px;
  margin: 0 auto;
  font-size: 14px;
  img {
    height: 70px;
    cursor: pointer;
  }
  span{
    display: inline-block;
    height: 70px;
    line-height: 70px;
    vertical-align: middle;
  }
}
.right-nav {
  width: 50%;
  display: flex;
  border-bottom: none !important;
  .text {
    margin: 0 20px;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      color: #82feff;
    }
  }
}
.banner-box {
    width: 100%;
    height: 334px;
    position: relative;

    .banner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        background-position: center;
        background-size: cover;
    }

    .title {
        padding-bottom: 40px;
        font-size: 46px;
        color: #ffffff;
        font-weight: bolder;
        letter-spacing: 2px;
    }

    .text {
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 2px;
    }
}

.fba-content {
    width: 100%;
    line-height: 200px;
    font-size: 40px;
    text-align: center;
    background-color: #eef1fa;
}

.second-box {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
    background-color: #eef1fa;

    .second-box-carousel {
        width: 70%;
        height: 334px;
        position: relative;

        /* background-color: antiquewhite; */
        .second {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            background-position: center;
            background-size: cover;
        }

        .title {
            padding-bottom: 40px;
            font-size: 46px;
            color: #ffffff;
            font-weight: bolder;
            letter-spacing: 2px;
        }

        .text {
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 2px;
        }
    }
}

.three-box {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #eef1fa;

    .el-row {
        width: 100%;
        display: flex;
        justify-content: center;

        .el-col {
            padding: 20px 16px;
            margin-bottom: -10px;

            .el-card {
                padding: 40px;
            }
        }

        .el-col-offset-2 {
            margin-left: 0%;
        }
    }

    .title {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        line-height: 40px;
        margin-top: 20px;
    }

    .title-desc {
        font-size: 16px;
        color: #999999;
        line-height: 30px;
        min-height: 90px;
    }

    .image {
        height: 40px !important;
    }

}

.three-last-box {
    padding-bottom: 20px;
}

.contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    background-color: #1d9f96;
}

.left-info {
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 50px 0;

    p {
        text-align: center;
        color: #15213B;
        line-height: 35px;
    }

    .p2 {
        font-size: 40px;
        font-weight: bold;
        margin: 25px auto;
    }

    .p3 {
        font-size: 21px;
        font-weight: bold;
        width: 60%;
        margin: 15px auto 0;
        line-height: 40px;
    }

    .concat-title {
        line-height: normal;
        padding-bottom: 16px;
        font-size: 30px;
        line-height: 65px;
        text-align: center;
        font-weight: bold;
        padding-bottom: 60px;
        color: #2a2929;
    }

    .words {
        text-align: center;
        line-height: 26px;
        padding-bottom: 30px;
    }


}

.cold-centent-box {

    background-color: #eef1fa;
    width: 100%;
    height: 1230px;
    padding-top: 50px;

    .title-box {
        height: 254px;
        text-align: center;
        background: #1f9e93;
        padding: 10px 48px;


        .title-num {
            font-size: 100px;
            color: #fff;
            display: block;
            padding-bottom: 10px;
            line-height: 100px;
        }

        .title-num-text {
            border-top: 4px solid #ffff;
            padding-top: 20px;
            font-size: 24px;
            color: #fff;
            font-weight: bold;
            line-height: 30px;
        }
    }


    .centent-img-box {
        display: flex;
        justify-content: center;
        width: 65%;
        height: 260px;
        margin: auto;
        padding-bottom: 40px;

        .el-row {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;

            .el-col {
                margin-bottom: 30px;

                .left {
                    padding: 20px 20px;
                    text-align: left;

                    .title {
                        font-size: 23px;
                        font-weight: bolder;
                    }
                }

                img {
                    padding: 0px 10px 20px 90px;
                    width: 80%;
                    height: 260px;
                }
            }
        }



    }

    .centent-img-box-v2 {
        display: flex;
        justify-content: center;
        width: 65%;
        height: 260px;
        margin: auto;
        padding-bottom: 40px;

        .el-row {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;

            .el-col {
                margin-bottom: 30px;

                .left {
                    padding: 20px 80px;
                    text-align: left;

                    .title {
                        font-size: 23px;
                        font-weight: bolder;
                    }
                }

                img {
                    padding: 0px 10px 20px 30px;
                    width: 80%;
                    height: 260px;
                }
            }
        }



    }

    .title {
        padding-bottom: 10px;
        font-size: 20px;
        color: #4b4747;
        font-weight: bolder;
    }

    .title-desc {
        letter-spacing: 2px;
        line-height: 28px !important;
    }
}


.white-container {
    padding: 60px 0 90px;
    background-color: #ffffff;
}

.w-title {
    font-size: 45px;
    line-height: 75px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 60px;
}

.center {
    display: flex;
    align-items: center;
    max-width: 1340px;
    margin: 0 auto;

    .iconfont {
        font-size: 40px;
        color: #ffffff;
        cursor: pointer;
    }

    .disable-btn {
        color: #eeeeee;
        cursor: not-allowed;
    }
}

.wrapper {
    overflow-x: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }
}

.cooperation-content {
    display: flex;

    .c-list {
        width: 254px;
        height: 120px;
        flex-shrink: 0;
        text-align: center;
    }

    img {
        /* // width: 100%; */
        height: 100%;
    }

    .c-wrapper {
        overflow-x: scroll;
        scroll-behavior: smooth;


    }
}

.w-center {
    .iconfont {
        color: #ffa12c;
    }

    .disable-btn {
        color: #f7dd91;
    }
}

.bottom-link {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 50px 0 30px;
    color: #FFF;
    background-color: #15213B;

    .el-row {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;

        .el-col {
            text-align: center;

            /* margin-right: 100px; */
            .title {
                font-size: 16px;
                font-weight: bolder;
                text-align: left;
                margin-bottom: 10px;
            }

            .sub-title {
                color: #95A5C9;
                border-bottom: 1px solid #1C2B4E;
                line-height: 35px;
                text-align: left;
                font-size: 14px;
            }
        }

    }
}

.bottom-container {
    padding: 60px 0;
    color: #ffffff;
    background-color: #1e1e1e;

    .wrapper {
        width: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .link {
        padding-right: 20px;
        cursor: pointer;
    }
}
</style>