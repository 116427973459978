<template>
    <div style="background-color: #eef1fa;">
        <div class="banner">
            <el-carousel class="ware-banner-box" height="100%" arrow="never" indicator-position="none" loop="false">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <div class="banner" :style="{ backgroundImage: `url(${item.img})` }">
                        <div class="sub-title">{{ item.subTitle }}</div>
                        <div class="title">{{ item.title }}</div>
                        <div class="text">{{ item.text }}</div>
                        <div class="link-btn"><router-link :to="{ name: 'concat' }">获取报价</router-link></div>
                    </div>
                </el-carousel-item>
                <div class="header-container">
                    <div class="header-box">
                        <div><span><img :src="logo" alt="" @click="goPage('/')" /></span>
                            <!-- <span>文舟供应链管理有限公司</span> -->
                        </div>
                        <el-menu class=" right-nav" mode="horizontal" @select="handleSelect">
                            <el-submenu index="1">
              <template slot="title">主营业务</template>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasea' }"><img
                        src="../assets/icon_menu1.png" />
                      电商及FBA海派</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasky' }"><img
                        src="../assets/icon_menu7.png" />
                      电商及FBA空派</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'interexp' }"><img
                        src="../assets/icon_menu6.png" />
                      国际快递</router-link></el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'importlogis' }"><img
                        src="../assets/icon_menu4.png" />
                      进口物流</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item style="widows: 50px;"><router-link :to="{ name: 'portsea' }">
                      <img src="../assets/icon_menu3.png" /> 港到港海运服务</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'door' }"><img
                        src="../assets/icon_menu2.png" />
                      门到门整柜运输</router-link>
                  </el-menu-item></el-col>

              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'seawarehouse' }"><img
                        src="../assets/icon_menu4.png" />
                      美国自营海外仓</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'delivery' }"><img
                        src="../assets/icon_menu8.png" />
                      美国清关+派送</router-link></el-menu-item></el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'intertrain' }"><img
                        src="../assets/icon_menu6.png" />
                      国际班列</router-link>
                  </el-menu-item></el-col>
                <el-col :span="10"><el-menu-item><router-link :to="{ name: 'collectlogis' }"><img
                        src="../assets/icon_menu8.png" />
                      集运物流</router-link></el-menu-item></el-col>
              </el-row>
            </el-submenu>
                            <el-menu-item index="2"><router-link :to="{ name: 'about' }">关于文舟</router-link></el-menu-item>
                            <el-menu-item index="3"><router-link :to="{ name: 'case' }">合作案例</router-link></el-menu-item>
                            <el-menu-item index="4"><router-link
                                    :to="{ name: 'concat' }">联系我们</router-link></el-menu-item>
                        </el-menu>

                    </div>
                </div>
            </el-carousel>
        </div>
        <div class="fba-content"><img src="../assets/fba1.png" />高标准履约是责任</div>

        <div class="ware-three-box">

            <div class="box">
                <div class="left-img-box">
                    <h4>高标准履约是责任</h4>
                    <p>High standard performance is a responsibility</p>
                </div>
                <div class="content-box">
                    <el-row :gutter="0">
                        <el-col :span="12" v-for="(o, index) in wareList1" :key="o" :offset="index > 0 ? 2 : 0">
                            <el-card :body-style="{ padding: '0px' }">
                                <img :src="o.img" class="image">
                                <div>
                                    <span class="title">{{ o.title }}</span>
                                    <div class="title-desc">
                                        {{ o.text }}
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row :gutter="0">
                        <el-col :span="12" v-for="(o, index) in wareList2" :key="o" :offset="index > 0 ? 2 : 0">
                            <el-card :body-style="{ padding: '0px' }">
                                <img :src="o.img" class="image">
                                <div>
                                    <span class="title">{{ o.title }}</span>
                                    <div class="title-desc">
                                        {{ o.text }}
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </div>

        </div>

        <div class="fba-content"><img src="../assets/fba1.png" />灵活配合是优势</div>
        <div class="ware-three-box">

            <div class="box">
                <div class="left-img-box">
                    <h4>灵活配合是优势</h4>
                    <p>FLEXIBLE COOPERATION I S AN ADVANTAGE</p>
                </div>
                <div class="content-box">
                    <el-row :gutter="0">
                        <el-col :span="12" v-for="(o, index) in wareList3" :key="o" :offset="index > 0 ? 2 : 0">
                            <el-card :body-style="{ padding: '0px' }">
                                <img :src="o.img" class="image">
                                <div>
                                    <span class="title">{{ o.title }}</span>
                                    <div class="title-desc">
                                        {{ o.text }}
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row :gutter="0">
                        <el-col :span="12" v-for="(o, index) in wareList4" :key="o" :offset="index > 0 ? 2 : 0">
                            <el-card :body-style="{ padding: '0px' }">
                                <img :src="o.img" class="image">
                                <div>
                                    <span class="title">{{ o.title }}</span>
                                    <div class="title-desc">
                                        {{ o.text }}
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </div>

        </div>

        <div class="map-box"><img src="../assets/map1.jpg">
            <div class="map-text">
                <p>文舟海外仓</p>
                <h4>自营车队大件自配送区域</h4>
            </div>
        </div>

        <div class="data-box">
            <div class="data-w">
                <div class="data-tt">
                    <p>洛杉矶仓库</p>
                    <p>纽约仓库</p>
                    <p>萨凡纳仓库</p>
                </div>
                <div class="data-list-box">
                    <div class="data-list">
                        <div class="data-item">
                            <div class="data-text">
                                <p>距离码头</p>
                                <h4><span>60</span>miles</h4>
                            </div>
                            <div class="data-text">
                                <p>面积</p>
                                <h4><span>71850</span>ft</h4>
                            </div>
                            <div class="data-text">
                                <p>仓高</p>
                                <h4><span>32</span></h4>
                            </div>
                        </div>
                        <div class="data-item">
                            <div class="data-text">
                                <p>距离码头</p>
                                <h4><span>19</span>miles</h4>
                            </div>
                            <div class="data-text">
                                <p>面积</p>
                                <h4><span>56911</span>ft</h4>
                            </div>
                            <div class="data-text">
                                <p>仓高</p>
                                <h4><span>28</span></h4>
                            </div>
                        </div>
                        <div class="data-item">
                            <div class="data-text">
                                <p>距离码头</p>
                                <h4><span>9</span>miles</h4>
                            </div>
                            <div class="data-text">
                                <p>面积</p>
                                <h4><span>14000</span>ft</h4>
                            </div>
                            <div class="data-text">
                                <p>仓高</p>
                                <h4><span>28</span></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>


        <div class="ware-contact-container">
            <div class="left-info">
                <div class="left-text-box">
                    <!-- <p>增值服务</p> -->
                    <p class="p2">持续投入建设物流基础设施</p>
                    <p class="p3">
                        文舟物流专注于美国本土化的运营和发展，继洛杉矶仓、纽约仓后，2022年8月萨凡纳海外仓也已正式投入运营，以美国尾段功能的协同布局，实现多仓联动、仓配一体的高效物流网络，为卖家降本、增效、提速。
                    </p>
                </div>
            </div>
        </div>
        <div class="fba-content">用心保您 方便无忧</div>
        <div class="warehouse-index">
            <el-row>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <el-card :body-style="{ padding: '0px' }">
                        <img src="../assets/w10.jpg">
                        <div class="index-desc">
                            <span>上架及时率</span>
                            <div class="bottom clearfix">
                                <p>入库后48小时内上架</p>
                                <p>操作要求: <br />仓库及时更新实际到达日期、上架日期，上架时效=上架日期-实际到达日期</p>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <el-card :body-style="{ padding: '0px' }">
                        <div class="index-desc">
                            <span>订单签出及时率</span>
                            <div class="bottom clearfix">
                                <p>一件代发&FBA转运</p>
                                <p>套户下单后24小时内签出旦交付快递快递派送:客户创建订单+48小时订单签出卡车派送:客户创建订单+48小时装箱完成</p>
                            </div>
                        </div>
                        <img src="../assets/w11.jpg" class="image">
                    </el-card>
                </el-col>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <el-card :body-style="{ padding: '0px' }">
                        <img src="../assets/w12.jpg" class="image">
                        <div class="index-desc">
                            <span>库存准确率</span>
                            <div class="bottom clearfix">
                                <p>库存抽盘，并提交盘点报告</p>
                                <p>每月完成TOP5客户至少10%SKU的库存抽盘，并提交盘点报告，作为履约的一部分，<br />标准为: 99.95%(PCS)</p>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <el-card :body-style="{ padding: '0px' }">
                        <div class="index-desc">
                            <span>拆柜及时率</span>
                            <div class="bottom clearfix">
                                <p>拆柜时效:到仓后24小时内拆完</p>
                                <p>请每月提供拆柜统计表，至少须包含入仓时间、拆柜完成时间</p>
                            </div>
                        </div>
                        <img src="../assets/w15.jpg" class="image">
                    </el-card>
                </el-col>
            </el-row>
        </div>
        <div class="bottom-link">
            <el-row>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        主营业务
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'fbasea' }">电商及FBA海派</router-link></div>
                    <div class="sub-title"><router-link
                      :to="{ name: 'fbasky' }">电商及FBA空派</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'interexp' }">国际快递</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'importlogis' }">进口物流</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'portsea' }">
                      港到港海运服务</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'door' }"> 门到门整柜运输</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'seawarehouse' }"> 美国自营海外仓</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'delivery' }">美国清关+派送</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'intertrain' }"> 国际班列</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'collectlogis' }">集运物流</router-link></div>
                </el-col>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        关于文舟
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'about' }">关于文舟</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'case' }">合作案例</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'concat' }">联系我们</router-link></div>
                </el-col>
            </el-row>
        </div>
        <!-- 底部 -->
        <div class="bottom-container">
            <div class="wrapper">
                Copyright @ 2008-至今 上海文舟供应链管理有限公司 All rights reserved
                沪ICP备20000382号-3
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/assets/w14.jpg';
import banner1 from '@/assets/banner1.jpg';
import banner2 from '@/assets/banner2.jpg';
import banner3 from '@/assets/banner3.jpg';

import w0 from '@/assets/w0.png';
import w1 from '@/assets/w1.png';
import w2 from '@/assets/w2.png';
import w3 from '@/assets/w3.png';
import w4 from '@/assets/w4.png';
import w5 from '@/assets/w5.png';
import w6 from '@/assets/w6.png';
import w7 from '@/assets/w7.png';

export default {
    name: 'FbaSeaView',
    data() {
        return {
            bannerList: [
                {
                    img: banner,
                    subTitle: '美国海外仓',
                    title: '文舟海外仓就是你自己的美国仓',
                    text: '为跨境电商客户提供优质的尾程落地配套服务，打造高效流转的中转型海外仓',
                }
            ],
            fbaList: [
                {
                    img: banner1,
                    title: '专注北美 链通全球',
                    text: '为您提供专业的海运整柜/拼箱、关务、国际多式联运等门到门物流服务',
                },
                {
                    img: banner2,
                    title: '一体化 高稳定 快时效',
                    text: '国内拖车+订舱/报关+清关/海运+空运+中欧班列/自营海外仓+自营车队',
                },
                {
                    img: banner3,
                    title: '非洲整柜海运',
                    text: '丰富的航线资源，稳定的非洲内陆连接网络，资深的专家团队，确保货物按时送达',
                },
            ]
            ,
            wareList1: [
                {
                    img: w0,
                    title: '全资全自营',
                    text: '非合作仓，非合股仓，全资全自营',
                },
                {
                    img: w1,
                    title: '亚马逊服务商',
                    text: '亚马逊SPN服务商',
                }
            ]
            ,
            wareList2: [
                {
                    img: w2,
                    title: '团队熟练',
                    text: '内作业全职员工平均司龄超过3年，团队稳定，作业熟练',
                },
                {
                    img: w3,
                    title: '履约能力',
                    text: '服务多家AMAZON TOP 50 SELLER，锤炼高标准履约能力',
                }
            ]
            ,
            wareList3: [
                {
                    img: w4,
                    title: '经验丰富',
                    text: '兼容TO B配送，TO C配送业务模式，VMI项目经验丰富',
                },
                {
                    img: w5,
                    title: '全流程解决方案',
                    text: '独立站退件入库集合退运返回再出口全流程解决方案',
                }
            ],
            wareList4: [
                {
                    img: w6,
                    title: '库位保障',
                    text: '旺季库位保障方案旺季快递提取保障方案',
                },
                {
                    img: w7,
                    title: '全额赔付',
                    text: '高货值丢件全额赔付保障方案',
                }
            ]
            ,
        };
    },
};
</script>
<style>
.body {
    color: #15213B;
    font-size: 14px;
    line-height: 28px;
    background-color: #eef1fa !important;
}

::v-deep .el-carousel__arrow i {
  font-size: 30px;
}
::v-deep .el-carousel__arrow {
  background-color: transparent;
}
::v-deep .el-carousel__arrow:hover {
  background-color: transparent;
}
.header-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  .el-menu .el-menu--horizontal {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: none;
  }
  .el-menu--horizontal {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: none;
  }

}

  ::v-deep .el-submenu__title:focus, ::v-deep .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  ::v-deep .el-menu-item:focus, ::v-deep .el-menu-item:hover{
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    outline: 0;
    color: #909399 !important;
}
.el-menu--horizontal>.el-submenu:hover .el-submenu__title {
    color: #909399 !important;
}
.el-menu-item:focus, .el-menu-item:hover {
    outline: 0;
    background-color: rgba(0, 0, 0, 0) !important;
}
.el-menu-item, .el-submenu__title {
    list-style: none;
}
.el-menu-item a {
    text-decoration:none;
}


  ::v-deep .el-menu--horizontal > ::v-deep .el-submenu ::v-deep .el-submenu__title{
    color: #909399 !important;
  }
  ::v-deep .el-menu--horizontal ::v-deep .el-menu-item:not(.is-disabled):hover {
    color: #909399 !important;
  }
  ::v-deep .el-menu--horizontal > ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399;
  }
  ::v-deep .el-menu--horizontal  > ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399 !important;
  }

.header-box {
  max-width: 1300px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 70px;
  margin: 0 auto;
  font-size: 14px;
  img {
    height: 70px;
    cursor: pointer;
  }
  span{
    display: inline-block;
    height: 70px;
    line-height: 70px;
    vertical-align: middle;
  }
}
.right-nav {
  width: 50%;
  display: flex;
  border-bottom: none !important;
  .text {
    margin: 0 20px;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      color: #82feff;
    }
  }
}
.ware-banner-box {
    width: 100%;
    height: 456px;
    position: relative;

    .banner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        background-position: center;
        background-size: cover;
    }

    .sub-title {
        padding-bottom: 40px;
        color: #ffffff;
        font-weight: bolder;
        letter-spacing: 2px;
        padding-left: 25%;
    }

    .title {
        padding-bottom: 40px;
        font-size: 46px;
        color: #ffffff;
        font-weight: bolder;
        letter-spacing: 2px;
        padding-left: 25%;
    }

    .text {
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 2px;
        padding-left: 25%;
    }
    .link-btn {
        padding: 10px 35px;
        transition: all 1s;
        border-radius: 35px;
        background-color: #F5C12D;
        font-size: 16px;
        width: 4%;
        min-width: 65px;
        margin-left: 25%;
        margin-top: 20px;

    }

    .link-btn:hover {
        cursor: pointer;
        background-color: #1D9F95;
        transition: all 1s;
    }

    a {
        text-decoration: none;
        font-weight: 400;
        color: #15213B;

    }
}

.fba-content {
    width: 100%;
    line-height: 200px;
    font-size: 40px;
    text-align: center;
    background-color: #eef1fa;
}

.second-box {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
    background-color: #eef1fa;

    .second-box-carousel {
        width: 70%;
        height: 334px;
        position: relative;

        /* background-color: antiquewhite; */
        .second {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            background-position: center;
            background-size: cover;
        }

        .title {
            padding-bottom: 40px;
            font-size: 46px;
            color: #ffffff;
            font-weight: bolder;
            letter-spacing: 2px;
        }

        .text {
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 2px;
        }
    }
}

.ware-three-box {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #eef1fa;

    .box {
        width: 80%;
        display: flex;
        justify-content: center;

        .left-img-box {
            width: 25%;
            height: 98%;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url(../assets/w8.jpg);

            h4 {
                font-size: 32px;
                line-height: 36px;
                color: #fff;
                font-weight: bold;
                padding-left: 30px;
                padding-top: 50px;
            }

            P {
                font-size: 16px;
                color: #fff;
                padding-top: 20px;
                padding-left: 30px;
                text-transform: uppercase;
                letter-spacing: 1px;
                opacity: .8;
            }
        }

        .content-box {
            width: 70%;
            height: 98%;

            .el-row {
                display: flex;
                justify-content: flex-start;

                .el-col {
                    padding: 0px 10px 10px 0px;

                    .el-card {
                        padding: 40px;
                    }
                }

                .el-col-offset-2 {
                    margin-left: 0%;
                }
            }
        }

    }

    .img-box {
        width: 33.33%;
        height: 100%;
        background-image: url(../assets/w8.jpg);
    }



    .title {
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        line-height: 40px;
        margin-top: 20px;
    }

    .title-desc {
        font-size: 16px;
        color: #999999;
        line-height: 30px;
        min-height: 90px;
    }

    .image {
        height: 40px !important;
    }

}

.three-last-box {
    padding-bottom: 20px;
}

.map-box {
    position: relative;
    text-align: center;

    .map-box img {
        width: 100%;
        height: auto;
    }


    .map-text {
        position: absolute;
        top: 8%;
        width: 100%;
        text-align: center;

        p {
            font-size: 24px;
            color: #fff;
            padding-bottom: 25px;
        }

        h4 {
            font-size: 32px;
            color: #ffffff;
        }
    }
}

.data-box {
    background: #eef1f9;
}

.data-w {
    position: relative;
    top: -100px;
    max-width: 1240px;
    margin: 0 auto;
}

.data-tt {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    background: rgb(255, 255, 255, .5);
    display: flex;
    justify-content: flex-start;
    padding-left: 96px;
    padding-right: 30px;
}

.data-tt p {
    width: 33.33%;
    padding: 30px 0px;
    font-size: 36px;
    color: #fff;
    line-height: 26px;
    margin-bottom: 11px;
}

.data-list-box {
    padding-left: 96px;
    padding-right: 30px;
    padding-bottom: 40px;
    padding-top: 20px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background: #ffffff;
    box-shadow: 0px 10px 15px 10px #e6e8f0;
}

.data-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.data-item {
    width: 33.33%;
    padding-top: 20px;
}

.data-text {
    padding-bottom: 20px;
}

.data-text p {
    font-size: 24px;
    color: #666666;
    margin-bottom: 10px;
}

.data-text h4 {
    color: #666666;
    font-size: 24px;
    display: flex;
    align-items: end;
}

.data-text h4 span {
    font-size: 58px;
    color: #1d9f96;
}


.ware-contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    background-color: #1d9f96;
}

.left-info {
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 50px 0;

    p {
        text-align: center;
        color: #15213B;
        line-height: 35px;
    }

    .p2 {
        font-size: 40px;
        font-weight: bold;
        margin: 25px auto;
    }

    .p3 {
        font-size: 21px;
        font-weight: bold;
        width: 60%;
        margin: 15px auto 0;
        line-height: 40px;
        padding-bottom: 55px;
    }

    .concat-title {
        line-height: normal;
        padding-bottom: 16px;
        font-size: 30px;
        line-height: 65px;
        text-align: center;
        font-weight: bold;
        padding-bottom: 60px;
        color: #2a2929;
    }

    .words {
        text-align: center;
        line-height: 26px;
        padding-bottom: 30px;
    }


}

.centent-box {

    background-color: #eef1fa;
    width: 100%;
    height: 850px;
    padding-top: 50px;


    .centent-img-box {
        display: flex;
        justify-content: center;
        width: 65%;
        height: 400px;
        margin: auto;
        padding-bottom: 40px;

        .el-row {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;

            .el-col {
                margin-bottom: 30px;

                .left {
                    padding: 20px 80px;
                    text-align: left;

                    .title {
                        font-size: 23px;
                        font-weight: bolder;
                    }
                }

                img {
                    padding: 0px 10px 20px 90px;
                    width: 80%;
                    height: 360px;
                }
            }
        }



    }

    .centent-img-box-v2 {
        display: flex;
        justify-content: center;
        width: 65%;
        height: 400px;
        margin: auto;

        .el-row {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;

            .el-col {
                margin-bottom: 30px;

                .left {
                    padding: 20px 80px;
                    text-align: left;

                    .title {
                        font-size: 23px;
                        font-weight: bolder;
                    }
                }

                img {
                    padding: 0px 10px 20px 30px;
                    width: 80%;
                    height: 360px;
                }
            }
        }



    }

    .title {
        padding-bottom: 10px;
        font-size: 20px;
        color: #4b4747;
        font-weight: bolder;
    }

    .title-desc {
        letter-spacing: 2px;
        line-height: 28px !important;
    }
}

.warehouse-index {
    display: flex;
    justify-content: center;
    background-color: #eef1fa !important;
    margin-bottom: 50px;

    .el-row {
        width: 70%;
        display: flex;
        justify-content: center;

        .el-col:nth-child(1) {
            margin-right: 50px;

        }

        .el-col:nth-child(2) {
            margin-right: 50px;

        }

        .el-col:nth-child(3) {
            margin-right: 50px;

        }

        .el-col:nth-child(4) {
            margin-right: -50px;

        }

        .el-col {
            /* margin-right: 50px; */
            width: 50%;
            background-size: 100% 231px;
            background-color: #ffffff;

            .el-card {
                .index-desc {
                    width: 80%;
                    height: 240px;
                    padding: 35px 20px;

                    span {
                        font-size: 24px;
                        color: #333;
                        font-weight: bold;
                        line-height: 30px;
                    }

                    .bottom{
                        font-size: 16px;
    color: #666666;
    line-height: 24px;
    margin-bottom: 25px;
                    }
                }

                img {
                    width: 100%;
                    height: 240px;
                }
            }
        }
    }

    .el-row:nth-last-child() {
        margin-right: -50px;
    }
}

.bottom-link {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 50px 0 30px;
    color: #FFF;
    background-color: #15213B;

    .el-row {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;

        .el-col {
            text-align: center;

            /* margin-right: 100px; */
            .title {
                font-size: 16px;
                font-weight: bolder;
                text-align: left;
                margin-bottom: 10px;
            }

            .sub-title {
                color: #95A5C9;
                border-bottom: 1px solid #1C2B4E;
                line-height: 35px;
                text-align: left;
                font-size: 14px;
            }
        }

    }
}

.bottom-container {
    padding: 30px 0;
    color: #ffffff;
    background-color: #15213B;
    border-top: 1px solid #41537A;

    .wrapper {
        width: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .link {
        padding-right: 20px;
        cursor: pointer;
    }
}
</style>