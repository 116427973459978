import axios from 'axios';
import {Message} from 'element-ui';

const service = axios.create ({
  baseURL: 'https://www.wenzhoulogistics.com/api',
  // baseURL: '/api',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  timeout: 30000,
});

// 请求拦截器
service.interceptors.request.use (config => {
  return config;
});

// 响应拦截器
service.interceptors.response.use (
  res => {
    if (res.status == 200) {
      console.log ('请求成功');
      return res.data;
    } else {
      return Message.error (res.message);
      // return res.data;
    }
  },
  err => {
    // console.log(err)
    return Promise.reject (err);
  }
);

export default service;
