<template>
    <div style="background-color: #eef1fa;">
        <div class="banner">
            <el-carousel class="port-banner-box" height="100%" arrow="never" indicator-position="none" loop="false">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <div class="banner" :style="{ backgroundImage: `url(${item.img})` }">
                        <div class="sub-title">{{ item.subTitle }}</div>
                        <div class="title">{{ item.title }}</div>
                        <div class="text">{{ item.text }}</div>
                        <div class="link-btn"><router-link :to="{ name: 'concat' }">获取报价</router-link></div>
                    </div>
                </el-carousel-item>
                <div class="header-container">
                    <div class="header-box">
                        <div><span><img :src="logo" alt="" @click="goPage('/')" /></span>
                            <!-- <span>文舟供应链管理有限公司</span> -->
                        </div>
                        <el-menu class=" right-nav" mode="horizontal" @select="handleSelect">
                            <el-submenu index="1">
                                <template slot="title">主营业务</template>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasea' }"><img
                                                    src="../assets/icon_menu1.png" />
                                                电商及FBA海派</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'fbasky' }"><img
                                                    src="../assets/icon_menu7.png" />
                                                电商及FBA空派</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'interexp' }"><img
                                                    src="../assets/icon_menu6.png" />
                                                国际快递</router-link></el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'importlogis' }"><img
                                                    src="../assets/icon_menu4.png" />
                                                进口物流</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item style="widows: 50px;"><router-link
                                                :to="{ name: 'portsea' }">
                                                <img src="../assets/icon_menu3.png" /> 港到港海运服务</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'door' }"><img
                                                    src="../assets/icon_menu2.png" />
                                                门到门整柜运输</router-link>
                                        </el-menu-item></el-col>

                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'seawarehouse' }"><img
                                                    src="../assets/icon_menu4.png" />
                                                美国自营海外仓</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'delivery' }"><img
                                                    src="../assets/icon_menu8.png" />
                                                美国清关+派送</router-link></el-menu-item></el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'intertrain' }"><img
                                                    src="../assets/icon_menu6.png" />
                                                国际班列</router-link>
                                        </el-menu-item></el-col>
                                    <el-col :span="10"><el-menu-item><router-link :to="{ name: 'collectlogis' }"><img
                                                    src="../assets/icon_menu8.png" />
                                                集运物流</router-link></el-menu-item></el-col>
                                </el-row>
                            </el-submenu>
                            <el-menu-item index="2"><router-link
                                    :to="{ name: 'about' }">关于文舟</router-link></el-menu-item>
                            <el-menu-item index="3"><router-link
                                    :to="{ name: 'case' }">合作案例</router-link></el-menu-item>
                            <el-menu-item index="4"><router-link
                                    :to="{ name: 'concat' }">联系我们</router-link></el-menu-item>
                        </el-menu>

                    </div>
                </div>
            </el-carousel>
        </div>

        <div>
            <div class="subWrap clearfix">

                <div class="clTit">
                    <h2>进口流程</h2>
                </div>
                <div class="clstep clearfix">
                    <b>售前</b>
                    <ul>
                        <li>了解需求</li>
                        <li>签订合同</li>
                    </ul>
                    <b>文舟供应链实施系统</b>
                    <ul>
                        <li>采购</li>
                        <li>金融</li>
                        <li>物流</li>
                        <li>通关</li>
                        <li>仓储</li>
                        <li>配送</li>
                    </ul>
                    <b>售后</b>
                    <ul>
                        <li>订单完成</li>
                        <li>售后服务</li>
                    </ul>
                </div>
                <div class="ysTit">
                    <h3>我们的优势</h3>
                    <p>为各类客户提供企业与产品备案，标签审核制作，通关、配送全程供应链服务。</p>
                    <ul class="clearfix">
                        <li>
                            <div class="photo"><img src="../assets/jkwl1.png"></div>
                            <div class="desc"><span>始于2005年，超过12年进口行业代理经验</span></div>
                        </li>
                        <li>
                            <div class="photo"><img src="../assets/jkwl2.png"></div>
                            <div class="desc"><span>“互联网+物流+金融”的全新进口代理模式</span></div>
                        </li>
                        <li>
                            <div class="photo"><img src="../assets/jkwl3.png"></div>
                            <div class="desc"><span>一站式进口物流，进口关务，进口金融解决方案</span></div>
                        </li>
                        <li>
                            <div class="photo"><img src="../assets/jkwl4.png"></div>
                            <div class="desc"><span>专业顾问团队运营管理，行业标杆</span></div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="hotOuter">
                <div class="hotInner">
                    <div class="tit">
                        <h4>热门进口产品</h4>
                    </div>
                    <ul class="clearfix">
                        <li><a href="javascript:;" class="button clockwise both">
                                <div><b class="txtEll">设备进口流程</b>
                                    <p>Equipment import</p>
                                </div><u
                                    :style="{ backgroundImage: 'url(' + require('../assets/jkwl9.png') + ')' }"></u>
                                <div class="inner"></div>
                            </a></li>
                        <li><a href="javascript:;" class="button clockwise both">
                                <div><b class="txtEll">食品进口流程</b>
                                    <p>Equipment import</p>
                                </div><u
                                    :style="{ backgroundImage: 'url(' + require('../assets/jkwl10.png') + ')' }"></u>
                                <div class="inner"></div>
                            </a></li>
                        <li><a href="javascript:;" class="button clockwise both">
                                <div><b class="txtEll">化工品进口流程</b>
                                    <p>Equipment import</p>
                                </div><u
                                    :style="{ backgroundImage: 'url(' + require('../assets/jkwl11.png') + ')' }"></u>
                                <div class="inner"></div>
                            </a></li>
                        <li><a href="javascript:;" class="button clockwise both">
                                <div><b class="txtEll">3C产品进口流程</b>
                                    <p>Equipment import</p>
                                </div><u
                                    :style="{ backgroundImage: 'url(' + require('../assets/jkwl12.png') + ')' }"></u>
                                <div class="inner"></div>
                            </a></li>
                        <li><a href="javascript:;" class="button clockwise both">
                                <div><b class="txtEll">日用品进口流程</b>
                                    <p>Equipment import</p>
                                </div><u
                                    :style="{ backgroundImage: 'url(' + require('../assets/jkwl13.png') + ')' }"></u>
                                <div class="inner"></div>
                            </a></li>
                        <li><a href="javascript:;" class="button clockwise both">
                                <div><b class="txtEll">木材进口流程</b>
                                    <p>Equipment import</p>
                                </div><u
                                    :style="{ backgroundImage: 'url(' + require('../assets/jkwl14.png') + ')' }"></u>
                                <div class="inner"></div>
                            </a></li>
                        <li><a href="javascript:;" class="button clockwise both">
                                <div><b class="txtEll">石材进口流程</b>
                                    <p>Equipment import</p>
                                </div><u
                                    :style="{ backgroundImage: 'url(' + require('../assets/jkwl15.png') + ')' }"></u>
                                <div class="inner"></div>
                            </a></li>
                        <li><a href="javascript:;" class="button clockwise both">
                                <div><b class="txtEll">家具进口流程</b>
                                    <p>Equipment import</p>
                                </div><u
                                    :style="{ backgroundImage: 'url(' + require('../assets/jkwl16.png') + ')' }"></u>
                                <div class="inner"></div>
                            </a></li>
                        <li><a href="javascript:;" class="button clockwise both">
                                <div><b class="txtEll">纸制品进口流程</b>
                                    <p>Equipment import</p>
                                </div><u></u>
                                <div class="inner"></div>
                            </a></li>
                    </ul><i>* 列表为热门的清关服务产品，我们还有其他清关服务</i><em><router-link
                            :to="{ name: 'concat' }">点击获得免费进口方案</router-link></em>
                </div>
            </div>
            <div class="jklc mb55">
                <h3>根据不同需求，定制进口方案</h3>
                <ul class="clearfix">
                    <li><a href="javascript:;">设备进口流程</a></li>
                    <li><a href="javascript:;">食品进口流程</a></li>
                    <li><a href="javascript:;">化工品进口流程</a></li>
                    <li><a href="javascript:;">3C产品进口流程</a></li>
                    <li><a href="javascript:;">日用品进口流程</a></li>
                    <li><a href="javascript:;">木材进口流程</a></li>
                    <li><a href="javascript:;">石材进口流程</a></li>
                    <li><a href="javascript:;">家具进口流程</a></li>
                    <li><a href="javascript:;">纸制品进口流程</a></li>
                </ul>
            </div>
        </div>

        <div class="bottom-link">
            <el-row>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        主营业务
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'fbasea' }">电商及FBA海派</router-link></div>
                    <div class="sub-title"><router-link
                      :to="{ name: 'fbasky' }">电商及FBA空派</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'interexp' }">国际快递</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'importlogis' }">进口物流</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'portsea' }">
                      港到港海运服务</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'door' }"> 门到门整柜运输</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'seawarehouse' }"> 美国自营海外仓</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'delivery' }">美国清关+派送</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'intertrain' }"> 国际班列</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'collectlogis' }">集运物流</router-link></div>
                </el-col>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        关于文舟
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'about' }">关于文舟</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'case' }">合作案例</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'concat' }">联系我们</router-link></div>
                </el-col>
            </el-row>
        </div>
        <!-- 底部 -->
        <div class="bottom-container">
            <div class="wrapper">
                Copyright @ 2008-至今 上海文舟供应链管理有限公司 All rights reserved
                沪ICP备20000382号-3
            </div>
        </div>
    </div>
</template>

<script>
import banner1 from '@/assets/banner1.jpg';
import banner2 from '@/assets/banner2.jpg';
import banner3 from '@/assets/banner3.jpg';
import banner from '@/assets/zyyw-banner5.jpg';
export default {
    name: 'FbaSeaView',
    data() {
        return {
            bannerList: [
                {
                    img: banner,
                    subTitle: '',
                    title: '进口物流',
                    text: '',
                }
            ],
            fbaList: [
                {
                    img: banner1,
                    title: '专注北美 链通全球',
                    text: '为您提供专业的海运整柜/拼箱、关务、国际多式联运等门到门物流服务',
                },
                {
                    img: banner2,
                    title: '一体化 高稳定 快时效',
                    text: '国内拖车+订舱/报关+清关/海运+空运+中欧班列/自营海外仓+自营车队',
                },
                {
                    img: banner3,
                    title: '非洲整柜海运',
                    text: '丰富的航线资源，稳定的非洲内陆连接网络，资深的专家团队，确保货物按时送达',
                },
            ]
        };
    },
};
</script>
<style>
.body {
    color: #15213B;
    font-size: 14px;
    line-height: 28px;
    background-color: #eef1fa !important;
}

::v-deep .el-carousel__arrow i {
    font-size: 30px;
}

::v-deep .el-carousel__arrow {
    background-color: transparent;
}

::v-deep .el-carousel__arrow:hover {
    background-color: transparent;
}

.header-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;

    .el-menu .el-menu--horizontal {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: none;
    }

    .el-menu--horizontal {
        background-color: rgba(0, 0, 0, 0);
        border-bottom: none;
    }

}

::v-deep .el-submenu__title:focus,
::v-deep .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

::v-deep .el-menu-item:focus,
::v-deep .el-menu-item:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    outline: 0;
    color: #909399 !important;
}

.el-menu--horizontal>.el-submenu:hover .el-submenu__title {
    color: #909399 !important;
}

.el-menu-item:focus,
.el-menu-item:hover {
    outline: 0;
    background-color: rgba(0, 0, 0, 0) !important;
}

.el-menu-item,
.el-submenu__title {
    list-style: none;
}

.el-menu-item a {
    text-decoration: none;
}


::v-deep .el-menu--horizontal> ::v-deep .el-submenu ::v-deep .el-submenu__title {
    color: #909399 !important;
}

::v-deep .el-menu--horizontal ::v-deep .el-menu-item:not(.is-disabled):hover {
    color: #909399 !important;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399;
}

::v-deep .el-menu--horizontal> ::v-deep .el-submenu:hover ::v-deep .el-submenu__title {
    color: #909399 !important;
}

.header-box {
    max-width: 1300px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 70px;
    margin: 0 auto;
    font-size: 14px;

    img {
        height: 70px;
        cursor: pointer;
    }

    span {
        display: inline-block;
        height: 70px;
        line-height: 70px;
        vertical-align: middle;
    }
}

a {
    text-decoration: none;
    font-weight: 400;
    color: #15213B;

}



.el-menu--horizontal {
    border-right: none;
    width: 30% !important;

    .el-row {
        margin: 0 !important;

        .el-col {
            width: 50%;
            padding: 10px;
            padding-right: 92px !important;

        }

        .el-col:hover {
            color: #000;
            background-color: #EEEEEE;
            border-radius: 5px;

            .el-menu-item {
                color: #000;
                background-color: #EEEEEE;
                border-radius: 5px;

            }
        }
    }
}

.right-nav {
    width: 50%;
    display: flex;
    border-bottom: none !important;

    .text {
        margin: 0 20px;
        color: #ffffff;
        cursor: pointer;

        &:hover {
            color: #82feff;
        }
    }
}


.subWrap {
    width: 1200px;
    margin: 0 auto;
    padding: 28px 0;
}

.subLeft {
    float: left;
    width: 910px;
}

.nrWrap {}

.subRight {
    float: right;
    width: 260px;
}

.site {
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #bfbfbf;
    margin-bottom: 23px;
}

.site .left {
    font-size: 20px;
    color: #283f8b;
}

.site .left:before {
    margin-left: 0;
}

.site .left h1 {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    display: inline;
}

.site .right {
    font-size: 14px;
    color: #999;
}

.site .right a {
    color: #999;
}

.sidebar .tit {
    height: 60px;
    line-height: 60px;
    margin-bottom: 1px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    background-color: #283f8b;
}

.sidebar .tit h1,
.sidebar .tit h2,
.sidebar .tit h3,
.sidebar .tit h4,
.sidebar .tit h5,
.sidebar .tit h6,
.sidebar .tit a {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
}


.sidebar ul {
    padding: 15px 0;
    border: 1px solid #283f8b;
}

.sidebar ul li a {
    display: block;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    color: #666;
}

.sidebar ul li a:hover {
    color: #283f8b;
}

.sidebar ul li a span {
    display: inline-block;
    position: relative;
}

.sidebar ul li.on a {
    color: #283f8b;
}

.sidebar ul li.on span:before {
    position: absolute;
    left: -15px;
    top: 0;
    height: 100%;
    line-height: inherit;
    font-family: 'fontello';
    content: '\e873';
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    color: #283f8b;
}

.sidebar .cont {
    padding: 35px 15px 35px;
    border: 1px solid #283f8b;
    font-size: 14px;
    color: #666;
}

.sidebar .cont p {
    position: relative;
    margin-bottom: 20px;
    padding-left: 2em;
}

.sidebar .cont p:before {
    margin-left: 0;
    position: absolute;
    left: 0;
    font-size: 20px;
    color: #283f8b;
}

.sidebar .bjb {
    display: block;
    width: 180px;
    height: 36px;
    line-height: 36px;
    border: 1px solid #283f8b;
    border-radius: 19px;
    margin: 0 auto;
}

.sidebar .bjb span {
    display: inline-block;
    padding-left: 33px;
    font-size: 16px;
    color: #283f8b;
    background: url(../assets/jkwl1.png) no-repeat left center;
}

.sidebar ol {
    padding: 25px 15px;
    border: 1px solid #283f8b;
}

.sidebar ol.tuwen {
    padding: 5px 10px;
}

.tuwen li {
    padding: 15px 0;
    border-bottom: 1px dashed #bfbfbf;
}

.tuwen li:last-child {
    border-bottom: none;
}

.tuwen .photo {
    position: relative;
    float: left;
    display: block;
    width: 115px;
    height: 65px;
    overflow: hidden;
}

.tuwen .photo span {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 115px;
    height: 65px;
    overflow: hidden;
}

.tuwen .photo span img {
    vertical-align: bottom;
    max-width: 115px;
    max-height: 65px;
    transition: transform 0.3s ease 0s;
}

.tuwen .photo u {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 115px;
    height: 65px;
    overflow: hidden;
    text-decoration: none;
    font-size: 16px;
    background-color: #f6f6f6;
    color: #999;
}

.tuwen .desc {
    float: right;
    width: 113px;
}

.tuwen .desc h3 {
    font-size: 14px;
    font-weight: bold;
}

.tuwen .desc p {
    height: 32px;
    overflow: hidden;
    color: #666;
}

.tuwen .desc p:last-child {
    height: auto;
    overflow: auto;
    font-size: 11px;
    color: #999;
}

.tuwen a:hover img {
    transform: scale(1.2, 1.2);
}

.clTit {
    height: 130px;
    overflow: hidden;
    text-indent: -9999em;
    background: url(../assets/jkwl6.png) no-repeat center center;
}

/* .fwTit h2 {height:100px;overflow:hidden;text-indent:-9999em;background:url(../images/titfw.png) no-repeat bottom center;} */
.fwTit p {
    text-align: center;
    padding: 15px 0 25px;
    font-size: 20px;
    color: #666;
}

/* .czTit {height:169px;overflow:hidden;text-indent:-9999em;background:url(../images/titcz.png) no-repeat center 49px;} */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    outline: none;
    list-style: none;
    font-style: normal;
}

.ysTit {
    padding-bottom: 32px;
}

.ysTit h3 {
    height: 140px;
    overflow: hidden;
    text-indent: -9999em;
    background: url(../assets/jkwl7.png) no-repeat bottom center;
}

.ysTit p {
    padding: 10px 0 35px;
    text-align: center;
    font-size: 20px;
    color: #666;
}

.ysTit li {
    float: left;
    display: block;
    width: 253px;
    margin: 0 20px 0 0;
    padding: 15px;
    border: 1px solid #dcdcdc;
}

.ysTit li:last-child {
    margin-right: 0;
}

.ysTit li:hover {
    border-color: #283f8b;
}

.ysTit .photo {
    float: left;
    width: 90px;
    height: 90px;
    line-height: 90px;
    text-align: center;
    border-radius: 45px;
    background-color: #283f8b;
}

.ysTit .desc {
    float: right;
    width: 148px;
}

.ysTit span {
    display: table-cell;
    vertical-align: middle;
    width: 148px;
    height: 90px;
    font-size: 14px;
    color: #666;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}


.clstep {
    position: relative;
}

.clstep b {
    position: absolute;
    top: 0;
    display: block;
    height: 62px;
    font-weight: normal;
    text-align: center;
    line-height: 62px;
    border: 7px solid #fff;
    background-color: #e1e1e1;
    border-radius: 38px;
    font-size: 26px;
}

.clstep b:nth-of-type(1) {
    left: 0;
    z-index: 2;
    width: 271px;
}

.clstep b:nth-of-type(2) {
    left: 234px;
    z-index: 1;
    width: 732px;
    background-color: #415cb5;
    color: #fff;
}

.clstep b:nth-of-type(3) {
    right: 0;
    z-index: 0;
    width: 236px;
    padding-left: 106px;
}

.clstep ul {
    float: left;
    padding-top: 100px;
    padding-left: 0px;
}

.clstep li {
    float: left;
    position: relative;
    width: 120px;
    padding-top: 116px;
    text-align: center;
    font-size: 16px;
}

.clstep li:before {
    content: '';
    width: 0px;
    border-left: 1px dotted #bfbfbf;
    height: 96px;
    font-size: 0;
    line-height: 0;
    position: absolute;
    left: 50%;
    top: 0;
}

.clstep li:after {
    content: '';
    width: 10px;
    height: 10px;
    font-size: 0;
    line-height: 0;
    position: absolute;
    left: 50%;
    top: 96px;
    margin-left: -5px;
    background-color: #bfbfbf;
    border-radius: 50%;
}

.clstep ul:nth-of-type(2) li {
    color: #415cb5;
}

.clstep ul:nth-of-type(2) li:before {
    border-color: #415cb5;
}

.clstep ul:nth-of-type(2) li:after {
    background-color: #415cb5;
}

.clearfix:after {
    content: "\20";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden
}

.jklist li {
    float: left;
    margin: 0 20px 20px 0;
}

.jklist li:nth-child(3n) {
    margin-right: 0;
}

.jklist a {
    float: left;
    display: block;
    width: 286px;
    overflow: hidden;
    border: 2px solid #d2d2d2;
}

.jklist a:hover {
    border-color: #283f8b;
}

.jklist a:hover img {
    transform: scale(1.2, 1.2);
}

.jklist .photo {
    position: relative;
    display: block;
    width: 278px;
    height: 180px;
    padding: 4px;
    overflow: hidden;
}

.jklist .photo span {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 278px;
    height: 180px;
    overflow: hidden;
}

.jklist .photo span img {
    vertical-align: bottom;
    max-width: 278px;
    max-height: 180px;
    transition: transform 0.3s ease 0s;
}

.jklist b {
    display: block;
    text-align: center;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 15px;
}


.hotOuter {
    height: 610px;
    background: url(../assets/jkwl8.jpg) no-repeat center center;
}

.hotInner {
    width: 1200px;
    height: 610px;
    margin: 0 auto;
    position: relative;
}

.hotInner .tit {
    height: 195px;
    overflow: hidden;
    text-indent: -9999em;
}

.hotInner ul {
    height: 262px;
    overflow: hidden;
}

.hotInner li {
    float: left;
    width: 285px;
    margin: 0 20px 20px 0;
}

.hotInner li:nth-child(4n) {
    margin-right: 0;
}

.hotInner li a {
    position: relative;
    display: block;
    width: 249px;
    height: 115px;
    padding: 0 15px;
    border: 3px solid #fff;
    background-color: #fff;
}

.hotInner li u {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    font-size: 0;
    line-height: 0;
    width: 115px;
    height: 115px;
    background-repeat: no-repeat;
    background-position: center center;
}

.hotInner li div {
    display: table-cell;
    vertical-align: middle;
    width: 249px;
    height: 115px;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

.hotInner li b {
    display: block;
    font-weight: bold;
    font-size: 20px;
    color: inherit;
}

.hotInner li p {
    font-size: 15px;
    color: inherit;
}

.hotInner li a:hover {
    color: #283f8b;
}

.hotInner i {
    position: absolute;
    left: 0;
    top: 537px;
    font-style: normal;
    font-size: 16px;
    color: #fff;
}

.hotInner em {
    position: absolute;
    left: 429px;
    top: 500px;
    width: 338px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}

.hotInner em a {
    display: block;
    font-size: 20px;
    font-weight: bold;
    border-radius: 30px;
    color: #283f8b;
    background-color: #fff;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

.hotInner em a:hover {
    background-color: #283f8b;
    color: #fff;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}


.jklc {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 60px;
}

.jklc h1,
.jklc h2,
.jklc h3,
.jklc h4,
.jklc h5,
.jklc h6 {
    height: 190px;
    background: url(../assets/jkwl17.png) no-repeat center center;
    overflow: hidden;
    text-indent: -9999em;
}

.jklc li {
    float: left;
    margin-right: 1px;
}

.jklc li:last-child {
    margin-right: 0;
}

.jklc li:last-child a {
    width: 134px;
}

.jklc li a {
    float: left;
    display: block;
    width: 130px;
    line-height: 53px;
    text-align: center;
    border: 1px solid #283f8b;
    color: #283f8b;
    font-size: 16px;
}

.jklc li a:hover {
    background-color: #283f8b;
    color: #fff;
}


.bottom-link {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 50px 0 30px;
    color: #FFF;
    background-color: #15213B;

    .el-row {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;

        .el-col {
            text-align: center;

            /* margin-right: 100px; */
            .title {
                font-size: 16px;
                font-weight: bolder;
                text-align: left;
                margin-bottom: 10px;
            }

            .sub-title {
                color: #95A5C9;
                border-bottom: 1px solid #1C2B4E;
                line-height: 35px;
                text-align: left;
                font-size: 14px;
            }
        }

    }
}


.bottom-container {
    padding: 30px 0;
    color: #ffffff;
    background-color: #15213B;
    border-top: 1px solid #41537A;

    .wrapper {
        width: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .link {
        padding-right: 20px;
        cursor: pointer;
    }
}
</style>