<template>
    <div style="background-color: #fff;">
        <div class="us-info-box">
            <div class="contact-box">
                <div class="select-box">
                    <div>
                        <el-select v-model="value" placeholder="请选择" @change="selectOffice">
                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="addr-box">
                        <p class="p1">{{ officeInfos[value].city }}</p>
                        <p class="p2">地址：<br />{{ officeInfos[value].addr }}</p>
                        <p>联系电话：<br />{{ officeInfos[value].phone }}</p>
                    </div>
                </div>
                <div>
                    <p>填写下方表单，我们将第一时间与您联系，并提供文舟物流专业解决方案、典型客户案例及相关服务。</p>
                    <div class="right-img">
                        <div class="right-form">
                            <el-input class="i-list" v-model="messageInfo.name" placeholder="姓名"></el-input>
                            <el-input class="i-list" v-model="messageInfo.phone" placeholder="手机号"></el-input>
                            <el-input class="i-list" v-model="messageInfo.mail" placeholder="邮箱"></el-input>
                            <el-button @click="submitInfo" class="submit-btn" :loading="submitLoading"
                                type="warning">提交</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="map" id="map-container">
                <baidu-map class="bm-view" ak="7SzlQnH2EwaiVhmNRJRduMxguvPyjQJK" :scroll-wheel-zoom="true"
                    :center="center" :zoom="zoom" @ready="handler">
                    <bm-marker :position="center"
                        animation="BMAP_ANIMATION_BOUNCE">
                        <bm-label content="3123123132" :labelStyle="{ color: 'red', fontSize: '24px' }"
                            :offset="{ width: -35, height: 30 }" />
                    </bm-marker>
                </baidu-map>
            </div>
        </div>

        <div class="bottom-link">
            <el-row>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        主营业务
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'fbasea' }">电商及FBA海派</router-link></div>
                    <div class="sub-title"><router-link
                      :to="{ name: 'fbasky' }">电商及FBA空派</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'interexp' }">国际快递</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'importlogis' }">进口物流</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'portsea' }">
                      港到港海运服务</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'door' }"> 门到门整柜运输</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'seawarehouse' }"> 美国自营海外仓</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'delivery' }">美国清关+派送</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'intertrain' }"> 国际班列</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'collectlogis' }">集运物流</router-link></div>
                </el-col>
                <el-col :span="3" v-for="(o, index) in 1" :key="o" :offset="index > 0 ? 2 : 0">
                    <div class="title">
                        关于文舟
                    </div>
                    <div class="sub-title"><router-link :to="{ name: 'about' }">关于文舟</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'case' }">合作案例</router-link></div>
                    <div class="sub-title"><router-link :to="{ name: 'concat' }">联系我们</router-link></div>
                </el-col>
            </el-row>
        </div>
        <!-- 底部 -->
        <div class="bottom-container">
            <div class="wrapper">
                Copyright @ 2008-至今 上海文舟供应链管理有限公司 All rights reserved
                沪ICP备20000382号-3
            </div>
        </div>
    </div>
</template>

<script>
import banner from '@/assets/about-banner1.jpg';
import banner1 from '@/assets/banner1.jpg';
import banner2 from '@/assets/banner2.jpg';
import banner3 from '@/assets/banner3.jpg';

import Swiper from 'swiper';

import 'swiper/css/swiper.min.css'

import { submitMessage } from '@/request/api';

// import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
// import BmMarker from 'vue-baidu-map/components/map/Map.vue'

import {
  BaiduMap,
  BmControl,
  BmView,
  BmAutoComplete,
  BmLocalSearch,
  BmMarker,
  BmGeolocation,
} from "vue-baidu-map";

// Import Swiper styles
// import 'swiper/css';
export default {
    components: {
        BaiduMap,
        BmMarker,
    },
    mounted() {
        var swiperInstance = new Swiper('.swiper-container', {
            slidesPerView: 3,
            spaceBetween: 30,
            zoom: 30,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        });

    },



    name: 'FbaSeaView',
    data() {

        return {
            
            messageInfo: {},
            submitLoading: false,
            options: [
                {
                    value: 0,
                    label: '上海'
                },
                // {
                //     value: 1,
                //     label: '广州'
                // },
            ],
            value: 0,
            valuetype: '上海',
            officeInfos: [
                {
                    city: '上海',
                    addr: '上海市宝山区 沪太路5018号梓坤科技园 1005室',
                    phone: '17398020158',
                    lng: 121.387325,
                    lat: 31.356121,

                },
                {
                    city: '广州',
                    addr: '广州',
                    phone: '广州电话',
                    lng: 113.618247,
                    lat: 22.776136,
                },
            ],
            swiperInstance: null,
            bannerList: [
                {
                    img: banner,
                    title: '关于文舟',
                    text: 'ABOUT US',
                }
            ],
            fbaList: [
                {
                    img: banner1,
                    title: '发件美国，求【稳】，选文舟',
                    text: '我们致力于推动跨境电商品牌出海，利用先进的物流数字化系统，充分整合资源，为客户提供全方位物流及增值服务。',
                },
                {
                    img: banner2,
                    title: '一体化 高稳定 快时效',
                    text: '国内拖车+订舱/报关+清关/海运+空运+中欧班列/自营海外仓+自营车队',
                },
                {
                    img: banner3,
                    title: '非洲整柜海运',
                    text: '丰富的航线资源，稳定的非洲内陆连接网络，资深的专家团队，确保货物按时送达',
                },
            ],
            center: { lng: 0, lat: 0 },
            zoom: 1,
        };
    },
    methods: {
        onSwiper() {

        },
        onSlideChange() {

        },
        selectOffice(value) {
            this.center.lng = this.officeInfos[this.value].lng;
            this.center.lat = this.officeInfos[this.value].lat;
        },
        handler({ BMap, map }) {
            this.center.lng = this.officeInfos[this.value].lng;
            this.center.lat = this.officeInfos[this.value].lat;
            this.zoom = 15
        },
        // 提交留言
        submitInfo() {
            if (!this.messageInfo.name) {
                this.$message.warning('请输入姓名！');
            } else if (!this.messageInfo.phone) {
                this.$message.warning('请输入手机号！');
            } else if (!/^1[3-9]\d{9}$/.test(this.messageInfo.phone)) {
                this.$message.warning('请输入有效的手机号！');
            } else if (!this.messageInfo.mail) {
                this.$message.warning('请输入邮箱！');
            } else if (
                !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                    this.messageInfo.mail
                )
            ) {
                this.$message.warning('请输入有效的邮箱！');
            } else {
                submitMessage(this.messageInfo).then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        this.$message.success('留言成功，客户经理会尽快与你联系！');
                        this.messageInfo = {};
                    }
                });
            }
        },
        // 页面跳转
        goPage(url) {
            this.$router.push({ path: url });
        },
    }

};
</script>
<style>
.body {
    color: #15213B;
    font-size: 14px;
    line-height: 28px;
    background-color: #eef1fa !important;
}

.us-info-box {
    display: flex;
    justify-content: center;

    .contact-box {
        width: 25%;
        padding: 20px;

        .select-box {
            padding: 20px;
            background-color: #F0F1F5;

            .el-select {
                width: 100%;
            }
        }

        .addr-box {
            background-color: #ffffff;
            line-height: 28px;
            padding: 20px;
            margin-top: 20px;

            .p1 {
                font-size: 18px;
                border-bottom: 1px solid #F1F2F6;
                padding-bottom: 10px;
                margin-bottom: 10px;
                font-weight: bold;
            }

            .p2 {
                border-bottom: 1px solid #F1F2F6;
                padding-bottom: 10px;
                margin-bottom: 10px;
            }
        }

        .right-form {

            .el-input {
                margin-bottom: 16px;
            }

            .el-button {
                width: 100%;
            }
        }

    }

    .map {
        width: 70%;
        padding: 20px;

        .bm-view {
            height: 100%;
            width: 100%;
        }
    }
}


.bottom-link {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 50px 0 30px;
    color: #FFF;
    background-color: #15213B;

    .el-row {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;

        .el-col {
            text-align: center;

            /* margin-right: 100px; */
            .title {
                font-size: 16px;
                font-weight: bolder;
                text-align: left;
                margin-bottom: 10px;
            }

            .sub-title {
                color: #95A5C9;
                border-bottom: 1px solid #1C2B4E;
                line-height: 35px;
                text-align: left;
                font-size: 14px;

                a{
                    color: #95A5C9 !important;
                }
            }
        }

    }
}
::v-deep .sub-title ::v-deep a{
    color: #95A5C9 !important;
}

.bottom-container {
    padding: 30px 0;
    color: #ffffff;
    background-color: #15213B;
    border-top: 1px solid #41537A;

    .wrapper {
        width: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }

    .link {
        padding-right: 20px;
        cursor: pointer;
    }
}
</style>